import React from "react";
import { View, StyleSheet, Text, Linking, Pressable } from "react-native";

const FooterLinks = () => {
  const openURL = (url: string) => {
    Linking.openURL(url).catch((err) =>
      console.error("Failed to open URL:", err),
    );
  };

  return (
    <View style={footerStyles.footerLinksContainer}>
      <Pressable
        onPress={() =>
          openURL(
            "https://calendly.com/supercharge-your-clinic/15-min-convo?month=2024-05",
          )
        }
      >
        <Text style={footerStyles.text} numberOfLines={1}>
          Contact Us
        </Text>
      </Pressable>
      <Pressable onPress={() => openURL("https://www.clinicos.ai/tos")}>
        <Text style={footerStyles.text} numberOfLines={1}>
          Terms of Service
        </Text>
      </Pressable>
      <Pressable
        onPress={() => openURL("https://www.clinicos.ai/privacy-policy")}
      >
        <Text style={footerStyles.text} numberOfLines={1}>
          Privacy Policy
        </Text>
      </Pressable>
    </View>
  );
};

const footerStyles = StyleSheet.create({
  footerLinksContainer: {
    flexDirection: "row",
    gap: 24,
  },
  text: {
    fontFamily: "Urbanist500",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    fontStyle: "normal",
    color: "#282B33",
  },
});

export default FooterLinks;
