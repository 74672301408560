import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import global from "../../styles/globalStyles";
import { supabase } from "../../utils/supabase";
import { useNavigate } from "react-router-dom";
import { clientDomain } from "../../config";

import {
  Input,
  Header,
  FooterLinks,
  PrimaryButton,
  SecondaryButton,
} from "../common/native/index";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleOnPressResetLink = async () => {
    setLoading(true);
    try {
      const options = {
        redirectTo: `${clientDomain}reset-password`,
      };
      const { data, error } = await supabase.auth.resetPasswordForEmail(
        email,
        options,
      );
      if (error) setErrorMessage(error.message);
      if (!error) setStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Header />
      <View
        style={{
          width: "100%",
          maxWidth: 434,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {step === 1 && (
          <>
            <Text style={global.title}>Forgot Password?</Text>
            <View style={{ marginBottom: 24 }} />
            <Text style={global.text}>
              Enter your email and we will send a link to get your password
              back. We suggest to{" "}
              <Text style={global.fontWeight600}>
                try the email address you use at work.
              </Text>
            </Text>
            <Input
              type="email-address"
              name="Email"
              placeholder="name@work-email.com"
              value={email}
              onChangeText={setEmail}
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessage !== "" && (
              <Text style={global.errorText}>{errorMessage}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <PrimaryButton
              text="Request a reset link"
              fullWidth
              onPress={handleOnPressResetLink}
              disabled={email ? false : true}
              loading={loading}
            />
            <View style={{ marginBottom: 12 }} />
            <SecondaryButton
              text="Back to Login"
              fullWidth
              onPress={() => navigate("/signin")}
            />
          </>
        )}
        {step === 2 && (
          <>
            <Text style={global.title}>Email Sent</Text>
            <View style={{ marginBottom: 24 }} />
            <Text style={global.text}>
              We sent an email to{" "}
              <Text style={global.fontWeight600}>{email}</Text> with a link to
              reset your password Please make sure to check your spam folder.
            </Text>
          </>
        )}
      </View>
      <FooterLinks />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    minHeight: "100%",
    width: "100%",
    gap: 32,
    padding: 16,
  },
});

export default ForgotPassword;
