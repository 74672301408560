import React from "react";
import { StyleSheet, Platform, Text, Pressable } from "react-native";
import { useNavigate } from "react-router-dom";

interface TextLinkProps {
  to: string;
  text: string;
  style?: object;
}

const TextLink: React.FC<TextLinkProps> = ({ to, text, style }) => {
  const navigate = useNavigate();

  return (
    <Pressable onPress={() => navigate(to)}>
      <Text style={[styles.text, style]} numberOfLines={1}>
        {text}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: "Urbanist500",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    fontStyle: "normal",
    color: "#282B33",
  },
});

export default TextLink;
