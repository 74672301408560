import React from "react";
import { Text, StyleSheet, Image, Pressable } from "react-native";
import { TProcedure } from "../../../utils/types";
import { capitalizeString } from "../../../utils/functions";
import { procedureIconMap } from "../../../utils/constant";

type ProcedureButtonProps = {
  procedure: TProcedure;
  selected: boolean;
  onPress: (name: TProcedure) => void;
};

const ProcedureButton: React.FC<ProcedureButtonProps> = ({
  procedure,
  selected,
  onPress,
}) => {
  return (
    <Pressable
      onPress={() => onPress(procedure)}
      style={[
        styles.container,
        {
          backgroundColor: selected ? "rgba(0, 183, 244, 0.1)" : "#fff",
        },
      ]}
    >
      <Image
        style={styles.icon}
        source={{ uri: procedureIconMap[procedure] }}
      />
      <Text style={[styles.text]}>{capitalizeString(procedure)}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    width: "100%",
    height: 67,
    maxWidth: 240,
    alignItems: "center",
    borderColor: "#C4C6CD",
    borderWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 20,
    gap: 16,
    borderRadius: 20,
    userSelect: "none",
  },
  icon: {
    width: 25,
    height: 25,
    aspectRatio: 1,
  },
  text: {
    color: "#212121",
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 14 * 1.4,
    letterSpacing: 0.2,
  },
});

export default ProcedureButton;
