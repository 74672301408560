import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import global from "../../styles/globalStyles";
import { supabase } from "../../utils/supabase";
import { mixpanel } from "../../utils/mixpanel";
import {
  addUserToClinic,
  createAClinic,
  getAdminToken,
} from "../../services/api";
import { titles, states } from "../../utils/constant";

import {
  Header,
  Input,
  TextLink,
  OrSeperator,
  FooterLinks,
  PrimaryButton,
  SecondaryButton,
  InputDropdown,
  GoogleLoginBtn,
} from "../common/native/index";

const Signup = () => {
  const [step, setStep] = useState<number>(1);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [errorMessageStep1, setErrorMessageStep1] = useState<string>("");
  const [errorMessageStep2, setErrorMessageStep2] = useState<string>("");
  const [errorMessageStep3, setErrorMessageStep3] = useState<string>("");
  const [errorMessageStep4, setErrorMessageStep4] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const handleOnPressLogInWithEmail = async () => {
    const trimedEmail = email.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(trimedEmail)) {
      setErrorMessageStep1("Please enter a valid email address.");
      return;
    }

    setErrorMessageStep1("");
    setStep((prevStep) => prevStep + 1);
  };

  const handleOnPressNext2 = () => {
    if (password !== confirmPassword) {
      setErrorMessageStep2("Please make sure your passwords match.");
      return;
    }
    if (password.length < 8) {
      setErrorMessageStep2("Invalid password");
      return;
    }
    setErrorMessageStep2("");
    setStep((prevStep) => prevStep + 1);
  };

  const handleOnPressNextStep3 = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const register = async (email: string, password: string) => {
    const options = {
      data: {
        title: title,
        fullName: fullName,
        businessName: businessName,
        jobTitle: jobTitle,
        phoneNumber: phoneNumber,
        state: state,
      },
    };
    return supabase.auth.signUp({
      email,
      password,
      options,
    });
  };

  const handleOnPressNextStep4 = async () => {
    const phoneRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

    if (phoneNumber && !phoneRegex.test(phoneNumber)) {
      setErrorMessageStep4("Invalid phone number format");
      return;
    }

    setLoading(true);
    try {
      const { token } = await getAdminToken();
      const { id } = await createAClinic(token, businessName);
      const { data, error } = await register(email, password);
      if (error) {
        setErrorMessageStep4(error.message);
        return;
      }
      if (!data || !data.user || !data.user.id) {
        throw error;
      }
      await addUserToClinic(token, id, data.user.id, "CLINIC_ADMIN");

      mixpanel.track("Signup Started", {
        "User Email": data.user.email,
        "Clinic Name": businessName,
      });
      mixpanel.track("Signup Complete", {
        "User Email": data.user.email,
        "Clinic Name": businessName,
      });

      if (!error) setStep((prevStep) => prevStep + 1);
    } catch (error) {
      setErrorMessageStep4("Error in Creating Account");
    }
    setLoading(false);
  };

  const handleOnPressBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <View style={styles.container}>
      <Header />
      <View
        style={{
          width: "100%",
          maxWidth: 434,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {step === 1 && (
          <>
            <Text style={[global.title, { marginBottom: 12 }]}>
              Welcome to ClinicOS
            </Text>
            <Text
              style={[
                global.text,
                global.fontWeight600,
                global.fontSize18,
                { marginBottom: 24 },
              ]}
            >
              Already have an account?{" "}
              <TextLink
                to={"/signin"}
                text={"Sign In"}
                style={[global.blue, global.fontSize18]}
              />
            </Text>
            <GoogleLoginBtn name={"Signup with Google"} />
            <OrSeperator />
            <Text
              style={[global.text, global.alignSelfStart, { marginBottom: 12 }]}
            >
              We suggest using{" "}
              <Text style={global.fontWeight600}>
                the email address you use at work.
              </Text>
            </Text>
            <Input
              type="email-address"
              name="Email"
              placeholder="name@work-email.com"
              value={email}
              onChangeText={setEmail}
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep1 !== "" && (
              <Text style={global.errorText}>{errorMessageStep1}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <PrimaryButton
              text="Signup with Email"
              fullWidth
              onPress={handleOnPressLogInWithEmail}
              disabled={email ? false : true}
              loading={loading}
            />
          </>
        )}

        {step === 2 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 1 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 24 },
              ]}
            >
              Let's get you started
            </Text>
            <Input
              name="Password"
              placeholder="Enter your password"
              value={password}
              onChangeText={setPassword}
              secureTextEntry
              required
            />
            <Input
              name="Confirm Password"
              placeholder="Enter your confirm password"
              value={confirmPassword}
              onChangeText={setConfirmPassword}
              secureTextEntry
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep2 !== "" && (
              <Text style={global.errorText}>{errorMessageStep2}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={styles.backNextBtnsContainer}>
              <SecondaryButton
                text="Back"
                width={140}
                onPress={handleOnPressBack}
              />
              <PrimaryButton
                text="Next"
                width={140}
                onPress={handleOnPressNext2}
                disabled={password && confirmPassword ? false : true}
              />
            </View>
          </>
        )}

        {step === 3 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 2 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 24 },
              ]}
            >
              Tell us about yourself
            </Text>
            <View style={{ zIndex: 1, width: "100%" }}>
              <InputDropdown
                onSelect={setTitle}
                required
                name={"Title"}
                selections={titles}
                value={title}
              />
            </View>
            <Input
              name="Full name"
              placeholder="Enter your full name"
              value={fullName}
              onChangeText={setFullName}
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep4 !== "" && (
              <Text style={global.errorText}>{errorMessageStep3}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={styles.backNextBtnsContainer}>
              <SecondaryButton
                text="Back"
                width={140}
                onPress={handleOnPressBack}
              />
              <PrimaryButton
                text="Next"
                width={140}
                onPress={handleOnPressNextStep3}
                disabled={title && fullName ? false : true}
              />
            </View>
          </>
        )}

        {step === 4 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 3 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 24 },
              ]}
            >
              Tell us about your business
            </Text>
            <Input
              name="Business Name"
              placeholder="Enter your business name"
              value={businessName}
              onChangeText={setBusinessName}
              required
            />
            <View style={{ zIndex: 1, width: "100%" }}>
              <InputDropdown
                onSelect={setState}
                allowSearch
                required
                name={"State"}
                selections={states}
                value={state}
              />
            </View>
            <Input
              name="Job Title"
              placeholder="Enter your job title"
              value={jobTitle}
              onChangeText={setJobTitle}
            />
            <Input
              name="Phone number"
              placeholder="Enter your phone number"
              type="numeric"
              value={phoneNumber}
              onChangeText={setPhoneNumber}
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep4 !== "" && (
              <Text style={global.errorText}>{errorMessageStep4}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={styles.backNextBtnsContainer}>
              <SecondaryButton
                text="Back"
                width={140}
                onPress={handleOnPressBack}
              />
              <PrimaryButton
                text="Next"
                width={140}
                onPress={handleOnPressNextStep4}
                disabled={businessName && state ? false : true}
                loading={loading}
              />
            </View>
          </>
        )}
        {step === 5 && (
          <>
            <Text style={[global.title, { marginBottom: 24 }]}>Registered</Text>
            <Text style={global.text}>Check your email to confirm: </Text>
            <Text style={[global.text, global.fontWeight600]}>{email}</Text>
          </>
        )}
      </View>
      <FooterLinks />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    minHeight: "100%",
    width: "100%",
    gap: 32,
    padding: 16,
  },
  backNextBtnsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default Signup;
