import React, { useContext } from "react";
import { View, StyleSheet, Text, useWindowDimensions } from "react-native";

import { TGetFormUsageMetricsV2Response } from "../../../../services/api-analytics";
import { SessionContext } from "../../../../contexts";
import { NormalText } from "../../../common/native";

type VisitorSimulationSubmissionProps = {
  formUsageMetrics: TGetFormUsageMetricsV2Response;
};

const VisitorSimulationSubmission: React.FC<
  VisitorSimulationSubmissionProps
> = ({ formUsageMetrics }) => {
  const session = useContext(SessionContext);
  if (!session) return null;

  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  return (
    <View style={[styles.cardsContainer, { gap: isMobile ? 10 : 20 }]}>
      <View style={styles.cardContainer}>
        <Text style={[styles.cardTextTitle, { fontSize: isMobile ? 16 : 20 }]}>
          Site Visits
        </Text>
        <NormalText
          text={
            formUsageMetrics.form_usage_analytics.site_visits.toString() || "0"
          }
        />
      </View>
      <View style={styles.cardContainer}>
        <Text style={[styles.cardTextTitle, { fontSize: isMobile ? 16 : 20 }]}>
          Form Visits
        </Text>
        <NormalText
          text={
            formUsageMetrics.form_usage_analytics.form_visits.toString() || "0"
          }
        />
      </View>
      <View style={styles.cardContainer}>
        <Text style={[styles.cardTextTitle, { fontSize: isMobile ? 16 : 20 }]}>
          New Leads
        </Text>
        <NormalText
          text={
            formUsageMetrics.form_usage_analytics.form_submissions.toString() ||
            "0"
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardsContainer: {
    justifyContent: "center",
    flexDirection: "row",
  },
  cardContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    height: 100,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#DDDEE0",
  },
  cardTextTitle: {
    fontFamily: "Urbanist600",
    fontWeight: "600",
    fontSize: 20,
    color: "#000",
  },
});

export default VisitorSimulationSubmission;
