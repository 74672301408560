import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  ScrollView,
  FlatList,
  useWindowDimensions,
} from "react-native";
import { Tooltip } from "@rneui/themed";

import global from "../../../../styles/globalStyles";
import {
  TModal,
  TSimulationLinkAndPin,
  TSimulationRow,
} from "../../../../utils/types";
import {
  TReturnGetImagesBatchBatchId,
  getImagesBatch,
  getImagesBatchBatchId,
  postTransformBatchShare,
} from "../../../../services/api";
import { SessionContext } from "../../../../contexts";
import { capitalizeString } from "../../../../utils/functions";
import { clientDomain } from "../../../../config";
import { sexes } from "../../../../utils/constant";

import {
  InputPatientDetailPhone,
  InputPatientDetail,
  InputPatientDetailDropdown,
  Tab,
  PrimaryButton,
} from "../../../common/native/index";
import { PatientDetailSimulation } from "../../../uncommon/native/index";
import { ModalOpener } from "../../../uncommon/native/modals/index";
import { Plus, Upload } from "../../../../assets";
import { TPatientInfo } from "../../../../services/api-clinic";

interface ControlledTooltipProps {
  children: React.ReactNode;
  popover: React.ReactElement;
  withPointer: boolean;
}

const ControlledTooltip: React.FC<ControlledTooltipProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Tooltip
      visible={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...props}
      containerStyle={{ height: "auto", padding: 0, margin: 0 }}
      backgroundColor={"transparent"}
    />
  );
};

type PatientDetailProps = {
  currentPatientDetail: TPatientInfo;
  setPatientDetailToNull: () => void;
};

const PatientDetail: React.FC<PatientDetailProps> = ({
  currentPatientDetail,
  setPatientDetailToNull,
}) => {
  const session = useContext(SessionContext)?.session;
  const { width, height } = useWindowDimensions();
  const isMobile = width < 644;

  const [visible, setVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<TModal>("");

  const [simulationRows, setSimulationRows] = useState<TSimulationRow[]>([]);

  const [isShowSimulationImages, setIsShowSimulationImages] =
    useState<boolean>(false);

  const [simulationLinkAndPin, setSimulationLinkAndPin] =
    useState<TSimulationLinkAndPin>({ simulationLink: "", pin: "" });

  const [currentPatientImageData, setCurrentPatientImageData] = useState<
    TReturnGetImagesBatchBatchId[]
  >([]);

  const handleSelectedRow = (batchId: string) => {
    const fetchBatchImages = async () => {
      if (session) {
        try {
          const patientImageDataRes: TReturnGetImagesBatchBatchId[] =
            await getImagesBatchBatchId(
              session,
              currentPatientDetail.uuid,
              batchId,
            );

          patientImageDataRes.sort((a, b) => {
            const viewTypeOrder = {
              TORSO_FRONT: 1,
              TORSO_LEFT_45: 2,
              TORSO_LEFT: 3,
              TORSO_RIGHT_45: 4,
              TORSO_RIGHT: 5,
              BODY_FRONT: 7,
              BODY_BACK: 8,
              BODY_LEFT: 9,
              BODY_RIGHT: 10,
              HEAD_FRONT: 11,
              HEAD_LEFT_45: 12,
              HEAD_LEFT: 13,
              HEAD_RIGHT_45: 14,
              HEAD_RIGHT: 15,
            };

            // @ts-ignore
            const viewTypeA = viewTypeOrder[a.viewType] || Infinity;
            // @ts-ignore
            const viewTypeB = viewTypeOrder[b.viewType] || Infinity;

            return viewTypeA - viewTypeB;
          });
          setCurrentPatientImageData(patientImageDataRes);
        } catch (error) {
          console.error("Error fetching clinic info:", error);
        }
      }
    };

    const fetchSimulationLink = async () => {
      if (session) {
        try {
          const postTransformBatchShareRes = await postTransformBatchShare(
            session,
            currentPatientDetail.uuid,
            batchId,
          );
          const simulataionLink = `${clientDomain}simulation/${postTransformBatchShareRes.shareId}`;
          const pin = postTransformBatchShareRes.pin;
          setSimulationLinkAndPin({
            simulationLink: simulataionLink,
            pin: pin,
          });
        } catch (error) {
          console.error("Error fetching clinic info:", error);
        }
      }
    };
    fetchBatchImages();
    fetchSimulationLink();
    setIsShowSimulationImages(true);
  };

  const fetchSimulationData = async () => {
    if (session) {
      try {
        const simulationRowsRes = await getImagesBatch(
          session,
          currentPatientDetail.uuid,
        );
        const sortingFunction = (a: TSimulationRow, b: TSimulationRow) => {
          const valueA = a["updated_at" as keyof TSimulationRow];
          const valueB = b["updated_at" as keyof TSimulationRow];
          // @ts-ignore
          const dateA = valueA ? new Date(valueA) : null;
          // @ts-ignore
          const dateB = valueB ? new Date(valueB) : null;

          if (dateA && dateB) return dateB.getTime() - dateA.getTime();
          else if (dateA) return 1;
          else if (dateB) return -1;
          return 0;
        };
        const sorted = [...simulationRowsRes].sort(sortingFunction);
        setSimulationRows(sorted);
      } catch (error) {
        console.error("Error fetching clinic info:", error);
      }
    }
  };

  useEffect(() => {
    fetchSimulationData();
  }, []);

  return (
    <View style={styles.container}>
      <ModalOpener
        visible={visible}
        modalType={modalType}
        onClose={() => {
          fetchSimulationData();
          setVisible(false);
        }}
        currentPatientUuid={currentPatientDetail.uuid}
        simulationLinkAndPin={simulationLinkAndPin}
      />
      <View style={styles.headerContainer}>
        <View style={{ flexDirection: "row", marginTop: 3 }}>
          <Pressable onPress={setPatientDetailToNull}>
            <Text
              style={[global.text, global.fontSize24, global.fontWeight500]}
            >
              Patient /{" "}
            </Text>
          </Pressable>
          {isShowSimulationImages && (
            <>
              <Pressable onPress={() => setIsShowSimulationImages(false)}>
                <Text
                  style={[global.text, global.fontSize24, global.fontWeight500]}
                >
                  {currentPatientDetail.firstName || "-"} /{" "}
                </Text>
              </Pressable>
              <Text
                style={[global.text, global.fontSize24, global.fontWeight700]}
              >
                {" "}
                Images
              </Text>
            </>
          )}
          {!isShowSimulationImages && (
            <Text
              style={[global.text, global.fontSize24, global.fontWeight700]}
            >
              {currentPatientDetail.firstName || "-"}
            </Text>
          )}
        </View>
        {isShowSimulationImages ? (
          <PrimaryButton
            text={isMobile ? "" : "Share the Simulation"}
            roundBorderRadius
            icon={<Upload />}
            onPress={() => {
              setVisible(true);
              setModalType("ShareReportPdf");
            }}
          />
        ) : null}
      </View>

      {isShowSimulationImages && (
        <PatientDetailSimulation patientImageData={currentPatientImageData} />
      )}

      {!isShowSimulationImages && (
        <Tab
          tabNames={["Simulations", "Profile"]}
          initialTab="Simulations"
          tabContent={[
            <View key={0} style={{ gap: 8 }}>
              <ScrollView horizontal showsHorizontalScrollIndicator={true}>
                <View style={{ flexDirection: "column" }}>
                  <View style={styles.patientListHeader}>
                    <Text style={[global.text, global.fontWeight600]}>
                      Date Added
                    </Text>
                    <Text style={[global.text, global.fontWeight600]}>
                      Procedures
                    </Text>
                    <Text style={[global.text, global.fontWeight600]}>
                      Image
                    </Text>
                  </View>
                  <FlatList
                    style={{ maxHeight: height - 300 }}
                    showsVerticalScrollIndicator={false}
                    data={simulationRows}
                    keyExtractor={(item, index) => `${index}`}
                    renderItem={({ item }) => (
                      <View style={styles.patientListRow}>
                        <Text style={global.text}>
                          {item.created_at
                            ? new Date(item.created_at).toLocaleDateString()
                            : "-"}
                        </Text>
                        <ControlledTooltip
                          popover={
                            <View
                              style={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                elevation: 4,
                                shadowColor: "#000",
                                shadowOffset: { width: 0, height: 2 },
                                shadowOpacity: 0.25,
                                shadowRadius: 3.84,
                                borderRadius: 10,
                                backgroundColor: "white",
                                zIndex: 1,
                              }}
                            >
                              {item.procedure_types &&
                                item.procedure_types.map((item, index) => (
                                  <Text
                                    key={index}
                                    style={[global.text, { padding: 12 }]}
                                  >
                                    {capitalizeString(item)}
                                  </Text>
                                ))}
                            </View>
                          }
                          withPointer={false}
                        >
                          <Text
                            style={[global.text, { marginRight: 10 }]}
                            numberOfLines={1}
                            ellipsizeMode="tail"
                          >
                            {item.procedure_types
                              ? item.procedure_types
                                  .map((item2) => capitalizeString(item2))
                                  .join(", ")
                              : "-"}
                          </Text>
                        </ControlledTooltip>
                        <Pressable onPress={() => handleSelectedRow(item.id)}>
                          <Text style={[global.text, global.colorBlue]}>
                            {item.upload_type
                              ? item.upload_type.includes("User")
                                ? "Photos Uploaded"
                                : "Scanning"
                              : "-"}
                          </Text>
                        </Pressable>
                      </View>
                    )}
                  />
                </View>
              </ScrollView>
              <PrimaryButton
                text={"Simulate a new procedure"}
                roundBorderRadius
                icon={<Plus />}
                width={275}
                onPress={() => {
                  setModalType("AddANewProcedure");
                  setVisible(true);
                }}
              />
            </View>,
            <View key={1}>
              <View style={{ gap: 10, maxWidth: 422 }}>
                <InputPatientDetail
                  name={"First name"}
                  value={currentPatientDetail.firstName || "-"}
                />
                <InputPatientDetail
                  name={"Last name"}
                  value={currentPatientDetail.lastName || "-"}
                />
                <InputPatientDetail
                  name={"Email"}
                  value={currentPatientDetail.email || "-"}
                />
                <InputPatientDetailPhone
                  value={currentPatientDetail.phone || "+1"}
                />
                <View style={{ zIndex: 1 }}>
                  <InputPatientDetailDropdown
                    name="Sex"
                    value={[currentPatientDetail.sex || "-"]}
                    selections={sexes}
                  />
                </View>
              </View>
            </View>,
          ]}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  patientListHeader: {
    // @ts-ignore comment
    display: "grid",
    gridTemplateColumns: "100px 220px 130px",
    borderBottomColor: "#DDDEE0",
    borderBottomWidth: 1,
    paddingVertical: 20,
  },
  patientListRow: {
    // @ts-ignore comment
    display: "grid",
    gridTemplateColumns: "100px 220px 130px",
    paddingVertical: 6,
    marginVertical: 10,
  },
});

export default PatientDetail;
