import React from "react";

interface StarsProps {
  color?: string;
}

const Stars: React.FC<StarsProps> = ({ color = "#000" }) => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.68182 0L8.10909 1.14583L6.86364 1.66667L8.10909 2.19167L8.68182 3.33333L9.25 2.19167L10.5 1.66667L9.25 1.14583M4.13636 1.25L3 3.54167L0.5 4.58333L3 5.625L4.13636 7.91667L5.27273 5.625L7.77273 4.58333L5.27273 3.54167M8.68182 5.83333L8.10909 6.975L6.86364 7.5L8.10909 8.02083L8.68182 9.16667L9.25 8.02083L10.5 7.5L9.25 6.975"
        fill={color}
      />
    </svg>
  );
};

export default Stars;
