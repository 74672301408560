import React, { useContext } from "react";
import { StyleSheet, View, Text } from "react-native";

import { patchUpdatePlan } from "../../../../services/api";
import { SessionContext } from "../../../../contexts";
import { TPlan } from "../../../../utils/types";

import { PrimaryButton, SecondaryButton } from "../../../common/native";

interface ConfirmUpdatePlanModalProps {
  onClose: () => void;
  planToUpgrade: TPlan;
}

const ConfirmUpdatePlanModal: React.FC<ConfirmUpdatePlanModalProps> = ({
  onClose,
  planToUpgrade,
}) => {
  const session = useContext(SessionContext)?.session;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [isPlanUpdated, setIsPlanUpdated] = React.useState<boolean>(false);

  if (!session) return null;

  const handlePressConfirm = async () => {
    setLoading(true);
    try {
      await patchUpdatePlan(session, planToUpgrade);
      setIsPlanUpdated(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  if (isPlanUpdated) {
    return (
      <View style={styles.modalView}>
        <Text style={styles.text}>Plan updated! Refresh in 3 second!</Text>
      </View>
    );
  }

  if (isError) {
    return (
      <View style={styles.modalView}>
        <Text style={styles.text}>
          Something went wrong, please try again later
        </Text>
        <SecondaryButton
          text={"Close"}
          roundBorderRadius
          onPress={() => {
            onClose();
          }}
        />
      </View>
    );
  }

  return (
    <View style={styles.modalView}>
      <Text style={styles.text}>Are you sure you want to downgrade?</Text>
      <View style={styles.buttonsContainer}>
        <SecondaryButton
          text={"Yes"}
          width={100}
          loading={loading}
          roundBorderRadius
          onPress={() => handlePressConfirm()}
        />
        <PrimaryButton
          text={"Cancel"}
          width={100}
          roundBorderRadius
          onPress={() => {
            onClose();
          }}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxWidth: 465,
    height: 170,
    padding: 24,
    gap: 24,
    borderRadius: 10,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#000",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    fontStyle: "normal",
    letterSpacing: 0.2,
    marginRight: 10,
    textAlign: "center",
  },
  buttonsContainer: {
    flexDirection: "row",
    gap: 16,
  },
});

export default ConfirmUpdatePlanModal;
