import React from "react";
import { StyleSheet, View, Image } from "react-native";

type DashboardHeaderNonLoginProps = {};

const DashboardHeaderNonLogin: React.FC<
  DashboardHeaderNonLoginProps
> = ({}) => {
  return (
    <View style={styles.headerContainer}>
      <View style={{ marginRight: "auto" }}>
        <Image
          source={{
            uri: "https://clinic-os.com/user-app-assets/header-clinicos.png",
          }}
          style={styles.image}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#DDDEE0",
  },
  image: {
    width: 90,
    height: 30,
  },
});

export default DashboardHeaderNonLogin;
