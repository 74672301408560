import React from "react";

type ClockProps = { color?: string };

const Clock: React.FC<ClockProps> = ({ color = "#000" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375ZM6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375Z"
        fill={color}
      />
      <path
        d="M7.85501 7.96586C7.79001 7.96586 7.72501 7.95086 7.66501 7.91086L6.11501 6.98586C5.73001 6.75586 5.44501 6.25086 5.44501 5.80586V3.75586C5.44501 3.55086 5.61501 3.38086 5.82001 3.38086C6.02501 3.38086 6.19501 3.55086 6.19501 3.75586V5.80586C6.19501 5.98586 6.34501 6.25086 6.50001 6.34086L8.05001 7.26586C8.23001 7.37086 8.28501 7.60086 8.18001 7.78086C8.10501 7.90086 7.98001 7.96586 7.85501 7.96586Z"
        fill={color}
      />
    </svg>
  );
};

export default Clock;
