import React, { useRef, useState, TouchEvent, useEffect } from "react";
import { Download, LeftRightHandle } from "../../../../assets";

interface SliderProps {
  afterImageUrls: string[];
  value: number;
  handleTurnOnOffScrollingStep3?: (bool: boolean) => void;
}

let mousewheelListener: EventListener,
  domMouseScrollListener: EventListener,
  touchmoveListener: EventListener;

const disableScrolling = () => {
  mousewheelListener = (e: Event) => {
    e.preventDefault();
  };
  domMouseScrollListener = (e: Event) => {
    e.preventDefault();
  };
  touchmoveListener = (e: Event) => {
    e.preventDefault();
  };

  const options: AddEventListenerOptions = { passive: false };
  document.addEventListener("mousewheel", mousewheelListener, options);
  document.addEventListener("DOMMouseScroll", domMouseScrollListener, options);
  document.addEventListener("touchmove", touchmoveListener, options);
};

const enableScrolling = () => {
  const options: AddEventListenerOptions = { passive: true };
  document.removeEventListener("mousewheel", mousewheelListener, options);
  document.removeEventListener(
    "DOMMouseScroll",
    domMouseScrollListener,
    options,
  );
  document.removeEventListener("touchmove", touchmoveListener, options);
};

const ImageSlider: React.FC<SliderProps> = ({
  afterImageUrls,
  value,
  handleTurnOnOffScrollingStep3 = () => {},
}: SliderProps): JSX.Element => {
  const ppContainerRef = useRef<HTMLDivElement>(null);
  const [sliderPosition, setSliderPosition] = useState<number>(0);
  const [transitionStyles, setTransitionStyles] = useState({
    sliderLine: "",
    afterImage: "",
  });
  const isSlidingRef = useRef<boolean>(false);

  useEffect(() => {
    if (isSlidingRef.current) {
      disableScrolling();
      return;
    }
    enableScrolling();
  }, []);

  const slide = (xPosition: number): void => {
    handleTurnOnOffScrollingStep3(false);
    isSlidingRef.current = true;
    if (ppContainerRef.current) {
      const containerBoundingRect =
        ppContainerRef.current.getBoundingClientRect();
      setSliderPosition(() => {
        if (xPosition < containerBoundingRect.left) {
          return 0;
        }
        if (xPosition > containerBoundingRect.right) {
          return 1;
        }
        return (
          (xPosition - containerBoundingRect.left) / containerBoundingRect.width
        );
      });
    }
  };

  const handleMouseDown = (): void => {
    window.onmousemove = handleMouseMove;
    window.onmouseup = handleMouseUp;
  };

  const handleMouseMove = (e: MouseEvent): void => {
    setTransitionStyles({
      sliderLine: "",
      afterImage: "",
    });
    slide(e.clientX);
  };

  const handleMouseUp = (): void => {
    window.onmousemove = null;
    window.onmouseup = null;
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>): void => {
    setTransitionStyles({
      sliderLine: "",
      afterImage: "",
    });
    slide(e.touches.item(0).clientX);
  };

  const handleRelease = () => {
    setTransitionStyles({
      sliderLine: "left 1s ease",
      afterImage: "clipPath 1s ease",
    });
    setSliderPosition(sliderPosition > 0.5 ? 1 : 0);
    handleTurnOnOffScrollingStep3(true);
    isSlidingRef.current = false;
  };

  return (
    <div
      ref={ppContainerRef}
      style={{
        display: "flex",
        position: "relative",
        backgroundColor: "transparent",
        borderRadius: "8px",
        overflow: "hidden",
        width: "100%",
        height: "auto",
      }}
    >
      <p
        style={{
          fontFamily: "Urbanist500",
          fontSize: "18px",
          top: "6px",
          position: "absolute",
          color: "#fff",
          zIndex: 3,
          left: "50%",
          transform: "translate(-50%, -50%)",
          transition: "opacity 1s ease",
          opacity: sliderPosition <= 0.5 ? 0 : 1,
        }}
      >
        Before
      </p>
      <p
        style={{
          fontFamily: "Urbanist500",
          fontSize: "18px",
          top: "6px",
          position: "absolute",
          color: "#fff",
          zIndex: 3,
          left: "50%",
          transform: "translate(-50%, -50%)",
          transition: "opacity 1s ease",
          opacity: sliderPosition > 0.5 ? 0 : 1,
        }}
      >
        After
      </p>
      <div
        style={{
          position: "absolute",
          right: "6px",
          bottom: "3px",
          zIndex: 3,
        }}
        onClick={async () => {
          const link = document.createElement("a");
          link.href = afterImageUrls[value];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
      >
        <Download />
      </div>
      <img
        style={{
          width: "100%",
          height: "auto",
          objectFit: "contain",
          position: "relative",
        }}
        draggable={false}
        src={afterImageUrls[0]}
        alt=""
      />
      {afterImageUrls.map((afterImageUrl, index) => (
        <img
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            zIndex: 1,
            position: "absolute",
            inset: 0,
            opacity: value === index ? 1 : 0,
            clipPath: `polygon(100% 100%, ${sliderPosition * 100}% 100%, ${
              sliderPosition * 100
            }% 0%, 100% 0%)`,
            transition: transitionStyles.afterImage,
          }}
          draggable={false}
          key={index}
          src={afterImageUrl}
          alt=""
        />
      ))}
      <div
        style={{
          position: "absolute",
          top: 0,
          height: "100%",
          paddingLeft: "2px",
          paddingRight: "2px",
          translate: "-50%",
          background: "#fff",
          cursor: "ew-resize",
          touchAction: "none",
          zIndex: 2,
          left: `${sliderPosition * 100}%`,
          transition: transitionStyles.sliderLine,
          boxShadow: "0px 0px 10px rgba(128, 128, 128, 0.5)",
        }}
        onMouseDown={handleMouseDown}
        onTouchMove={handleTouchMove}
        onMouseUp={handleRelease}
        onTouchEnd={handleRelease}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LeftRightHandle />
        </div>
      </div>
      <input
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          inset: 0,
          zIndex: 1,
          opacity: 0,
        }}
        type="range"
        min={0}
        max={1}
        onMouseDown={handleMouseDown}
        onTouchMove={handleTouchMove}
        onMouseUp={handleRelease}
        onTouchEnd={handleRelease}
      />
    </div>
  );
};

export default ImageSlider;
