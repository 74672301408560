import React, { useState } from "react";
import { StyleSheet, Pressable, Text, View } from "react-native";
import { TTabName } from "../../../utils/types";
import { Star } from "../../../assets";

type TabProps = {
  tabNames: TTabName[];
  tabContent: React.ReactNode[];
  tabRight?: React.ReactNode;
  currentTab?: TTabName;
  onTabPress?: (tabKey: TTabName) => void;
  initialTab?: string;
  disabledTabIndexes?: number[];
};

const Tab: React.FC<TabProps> = ({
  tabNames,
  tabRight = null,
  tabContent,
  onTabPress = null,
  initialTab = tabNames[0],
  disabledTabIndexes = [],
  currentTab = "",
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  React.useEffect(() => {
    if (currentTab) setSelectedTab(currentTab);
  }, [currentTab]);

  return (
    <>
      <View style={[styles.tabContainer]}>
        {tabNames.map((tabName, index) =>
          disabledTabIndexes.includes(index) ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                key={index}
                style={[
                  styles.disabledText,
                  { color: tabName === selectedTab ? "#00B7F4" : "#868B97" },
                ]}
              >
                {tabName}
              </Text>
              {tabName === "Deploy" && (
                <Star
                  color={selectedTab === tabName ? " #00B7F4" : "#868B97"}
                />
              )}
            </View>
          ) : (
            <Pressable
              key={index}
              style={[
                tabName === selectedTab
                  ? styles.selectedTab
                  : styles.unselectedTab,
              ]}
              onPress={() => {
                setSelectedTab(tabName);
                if (onTabPress) onTabPress(tabName);
              }}
            >
              <Text
                style={[
                  tabName === selectedTab
                    ? styles.selectedText
                    : styles.unselectedText,
                ]}
              >
                {tabName}
              </Text>
              {tabName === "Deploy" && (
                <Star color={selectedTab == tabName ? " #00B7F4" : "#868B97"} />
              )}
            </Pressable>
          ),
        )}
        <View style={{ marginLeft: "auto" }}>{tabRight}</View>
        <View style={styles.grayLine} />
      </View>
      {tabContent.map((content, index) => (
        <View
          key={index}
          style={{
            flex: 1,
            display: tabNames[index] === selectedTab ? "flex" : "none",
          }}
        >
          {content}
        </View>
      ))}
    </>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: "row",
    width: "100%",
    position: "relative",
    gap: 24,
    marginBottom: 12,
  },
  grayLine: {
    bottom: 0,
    left: 0,
    width: "100%",
    borderBottomWidth: 1,
    flexDirection: "row",
    borderBottomColor: "#DDDEE0",
    position: "absolute",
  },
  unselectedText: {
    fontSize: 20,
    fontFamily: "Urbanist600",
  },
  selectedText: {
    fontSize: 20,
    fontFamily: "Urbanist600",
    color: "#00B7F4",
  },
  unselectedTab: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomColor: "transparent",
  },
  selectedTab: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 12,
    borderBottomColor: "#00B7F4",
    borderBottomWidth: 1,
    zIndex: 2,
  },
  disabledText: {
    fontSize: 20,
    fontFamily: "Urbanist600",
    paddingVertical: 12,
    borderBottomColor: "transparent",
    color: "#868B97",
    // @ts-ignore
    cursor: "not-allowed",
  },
});

export default Tab;
