import React, { useContext, useEffect, useState } from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native";

import global from "../../../../styles/globalStyles";
import { mixpanel } from "../../../../utils/mixpanel";
import { supabase } from "../../../../utils/supabase";
import { SessionContext } from "../../../../contexts";
import { addUserToClinic, createAClinic } from "../../../../services/api";

import {
  Input,
  InputDropdown,
  PrimaryButton,
  SecondaryButton,
} from "../../../common/native/index";
import { Close } from "../../../../assets/index";
import { states, titles } from "../../../../utils/constant";

type FillOutClinicInfoModalProps = {
  onClose: () => void;
};

const FillOutClinicInfoModal: React.FC<FillOutClinicInfoModalProps> = ({
  onClose,
}) => {
  const session = useContext(SessionContext)?.session;
  const { width } = useWindowDimensions();
  const isMobile = width < 644;

  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const refreshToken = async () => {
    const { data, error } = await supabase.auth.refreshSession();
    if (error) throw new Error("Error refresing session");
    return data;
  };

  const updateUser = async () => {
    const { data, error } = await supabase.auth.updateUser({
      data: {
        title: title,
        fullName: fullName,
        businessName: businessName,
        jobTitle: jobTitle,
        phoneNumber: phoneNumber,
        state: state,
      },
    });
    if (error) throw new Error("Error updating user");
    return data;
  };

  useEffect(() => {
    mixpanel.track("Signup Started", {
      "User Email": session?.userEmail,
      "Clinic Name": businessName,
    });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    if (!session || !session.adminToken) return;
    try {
      await updateUser();
      const { id } = await createAClinic(session.adminToken, businessName);
      await addUserToClinic(
        session.adminToken,
        id,
        session.userId,
        "CLINIC_ADMIN",
      );
      await refreshToken();

      mixpanel.track("Signup Complete", {
        "User Email": session.userEmail,
        "Clinic Name": businessName,
      });

      setLoading(false);
      window.location.reload();
      onClose();
    } catch (error) {
      setErrorMessage("Error adding business info");
    }
  };

  const renderStepContent = () => {
    if (currentStep === 1)
      return (
        <>
          <View style={{ width: "100%", zIndex: 9999 }}>
            <Input
              name="Full name"
              placeholder=""
              value={fullName}
              onChangeText={setFullName}
              required
            />
            <InputDropdown
              onSelect={setTitle}
              required
              name={"Title"}
              selections={titles}
              value={title}
            />
          </View>
          <View style={{ marginTop: "auto", marginLeft: "auto" }}>
            <PrimaryButton
              text="Next"
              width={140}
              onPress={() => setCurrentStep(2)}
              disabled={title && fullName ? false : true}
            />
          </View>
        </>
      );

    if (currentStep === 2)
      return (
        <>
          <View style={{ width: "100%", zIndex: 9999 }}>
            <Input
              name="Business Name"
              value={businessName}
              onChangeText={setBusinessName}
              required
            />
            <View style={{ zIndex: 1 }}>
              <InputDropdown
                onSelect={setState}
                required
                name={"State"}
                selections={states}
                value={state}
              />
            </View>
            <Input
              name="Job Title"
              value={jobTitle}
              onChangeText={setJobTitle}
            />
            <Input
              name="Phone number"
              type="numeric"
              value={phoneNumber}
              onChangeText={setPhoneNumber}
            />
            {errorMessage !== "" && (
              <Text style={global.errorText}>{errorMessage}</Text>
            )}
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <SecondaryButton
              text="Back"
              width={140}
              onPress={() => setCurrentStep(1)}
            />
            <PrimaryButton
              text="Submit"
              width={140}
              onPress={() => handleSubmit()}
              loading={loading}
              disabled={businessName && state ? false : true}
            />
          </View>
        </>
      );
  };

  return (
    <View style={[styles.modalView, { maxHeight: isMobile ? 2000 : 600 }]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Business Details</Text>
      </View>
      {renderStepContent()}
    </View>
  );
};
const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    height: "100%",
    maxWidth: 465,
    padding: 24,
    gap: 24,
    borderRadius: 10,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Urbanist600",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    letterSpacing: 0.2,
    fontStyle: "normal",
  },
});

export default FillOutClinicInfoModal;
