import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { TProcedure } from "../../../../utils/types";
import { adjustTextMap } from "../../../../utils/constant";
import {
  simulateTabBblImages,
  simulateTabBreastAugImages,
  simulateTabBreastLiftImages,
  simulateTabBreastLiftPlusAugImages,
  simulateTabBreastReduxImages,
  simulateTabFaceLiftImages,
  simulateTabTummyTuckImages,
} from "./simulateTabConstants";
import ImageComparisonSlider from "../../../uncommon/web/image-comparison-slider/ImageComparisonSlider";
import UploadImage from "../../../uncommon/web/upload-image/UploadImage";
import ImageCarousel from "../../../uncommon/web/image-carousel/ImageCarousel";

interface TryAIComponentProps {
  isShowSlider: boolean;
  selectedProcedure: TProcedure;
  afterImageUrls: string[] | null;
  afterImageMetaData: any;
  saveCurrentImage: (image: Blob | string) => void;
  isMobile: boolean;
  handleSetIsAllowScrolling?: (isAllowScrolling: boolean) => void;
}

const TryAIComponent: React.FC<TryAIComponentProps> = ({
  isShowSlider,
  selectedProcedure,
  afterImageUrls,
  afterImageMetaData,
  saveCurrentImage,
  isMobile,
  handleSetIsAllowScrolling = () => {},
}) => {
  return (
    <View
      style={[styles.tryAiInnerContainer, { width: isMobile ? "100%" : 316 }]}
    >
      {isShowSlider ? (
        <ImageComparisonSlider
          adjustText={
            selectedProcedure
              ? adjustTextMap[selectedProcedure]
              : "Adjust the output"
          }
          imageUrls={afterImageUrls ?? []}
          metaData={afterImageMetaData}
          handleTurnOnOffScrollingStep3={handleSetIsAllowScrolling}
        />
      ) : (
        <UploadImage saveCurrentImage={saveCurrentImage} />
      )}
      <Text style={styles.modelText}>Use a model</Text>
      <ImageCarousel
        imageUrls={
          selectedProcedure === "BREAST_AUGMENTATION"
            ? simulateTabBreastAugImages
            : selectedProcedure === "BREAST_LIFT"
              ? simulateTabBreastLiftImages
              : selectedProcedure === "BREAST_REDUCTION"
                ? simulateTabBreastReduxImages
                : selectedProcedure === "BREAST_LIFT_AND_AUGMENTATION"
                  ? simulateTabBreastLiftPlusAugImages
                  : selectedProcedure === "BBL"
                    ? simulateTabBblImages
                    : selectedProcedure === "TUMMY_TUCK"
                      ? simulateTabTummyTuckImages
                      : simulateTabFaceLiftImages
        }
        saveCurrentImage={saveCurrentImage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  tryAiInnerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: "auto",
  },
  modelText: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#8F9195",
    marginVertical: 12,
  },
});

export default TryAIComponent;
