import React, { useContext } from "react";
import { StyleSheet, ScrollView, View } from "react-native";
import { SessionContext } from "../../../../contexts";
import { Tab, TitleText } from "../../../common/native";
import { TTabName } from "../../../../utils/types";
import ProfileTab from "./ProfileTab";
import AppearanceTab, { TAppearance } from "./AppearanceTab";
import DeployTab from "./DeployTab";
import { TProfile } from "./ProfileTab";
import { ProgressBar } from "../../../uncommon/native";

type GetStartedProps = {};

const GetStarted: React.FC<GetStartedProps> = ({}) => {
  const session = useContext(SessionContext)?.session;

  const [selectedTab, setSelectedTab] = React.useState<TTabName>("Profile");
  const [progress, setProgress] = React.useState<number>(0);
  const [profile, setProfile] = React.useState<TProfile>(() => {
    const hasSurgeons = session && session.surgeons.length > 0;
    return {
      email: "",
      leadSurgeonName: hasSurgeons ? session.surgeons[0].name || "" : "",
      clinicAddress: hasSurgeons ? session.surgeons[0].address || "" : "",
      leadSurgeonPhoto: hasSurgeons ? session.surgeons[0].photoUrl || "" : "",
    };
  });
  const [appearance, setAppearance] = React.useState<TAppearance>({
    xOffset: "10",
    yOffset: "10",
    themeColor: "#00B7F4",
    textColor: "#ffffff",
    position: "Bottom Right",
    ctaStateMobile: "Display Call to Action",
    ctaStateDesktop: "Display Call to Action",
    isDesktopView: "Desktop",
  });
  const handleUpdateAppearance = (field: string, value: string) => {
    setAppearance((prev) => ({ ...prev, [field]: value }));
  };

  const [codeSnippet, setCodeSnippet] = React.useState<string>("");

  React.useEffect(() => {
    setCodeSnippet(`  <script
    src="https://www.clinic-os.com/clinicos-full-form-${process.env.EXPO_PUBLIC_ENV === "production" ? "prod" : "dev"}-v2.js"
    clinicSdkKey="${session?.clinicSdkKeys[0]}"
    formName="onboarding-tier1"
    themeColor="${appearance.themeColor}"
    textColor="${appearance.textColor}"
    isCtaOpenByDefaultDesktop="${appearance.ctaStateDesktop === "Display Call to Action" ? "true" : "false"}"
    isCtaOpenByDefaultMobile="${appearance.ctaStateMobile === "Display Call to Action" ? "true" : "false"}"
    position="${appearance.position === "Bottom Right" ? "right" : "left"}"
    horizontalOffset="${appearance.xOffset}px"
    verticalOffset="${appearance.yOffset}px"
  ></script>`);
  }, [appearance, session]);

  React.useEffect(() => {
    if (selectedTab === "Profile") {
      setProgress(0.4);
    } else if (selectedTab === "Appearance") {
      setProgress(0.7);
    } else if (selectedTab === "Deploy") {
      setProgress(0.92);
    }
  }, [selectedTab]);

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.scrollViewContent}
    >
      <View style={styles.titleContainer}>
        <TitleText text="Activate Lead Acquisition" style={{ marginTop: 5 }} />
        <ProgressBar
          progress={session?.deployStatus ? 1 : progress}
          title={
            session?.deployStatus
              ? "All Set!"
              : selectedTab === "Profile"
                ? "Set up your profile"
                : selectedTab === "Appearance"
                  ? "Customize appearance"
                  : "Adjust the appearance"
                    ? "Deploy the form"
                    : "Deploy to your site"
          }
          timeLeft={
            session?.deployStatus
              ? "0mins"
              : selectedTab === "Profile"
                ? "3mins"
                : selectedTab === "Appearance"
                  ? "2mins"
                  : "1min"
          }
        />
      </View>
      <Tab
        tabNames={["Profile", "Appearance", "Deploy"]}
        currentTab={selectedTab}
        onTabPress={(tabKey) => setSelectedTab(tabKey as TTabName)}
        tabContent={[
          <ProfileTab
            key="Profile"
            profile={profile}
            setProfile={setProfile}
            onContinue={() => setSelectedTab("Appearance")}
          />,
          <AppearanceTab
            key="Appearance"
            appearance={appearance}
            onContinue={() => setSelectedTab("Deploy")}
            onUpdateAppearance={handleUpdateAppearance}
          />,
          <DeployTab key="Deploy" codeSnippet={codeSnippet} />,
        ]}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flex: 1,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 12,
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default GetStarted;
