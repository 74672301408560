import React from "react";
import { View, Image, StyleSheet, Pressable, Linking } from "react-native";

const Header = () => {
  const openWebsite = async () => {
    const url = "https://clinicos.ai";
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`Don't know how to open this URL: ${url}`);
    }
  };

  return (
    <Pressable style={styles.container} onPress={openWebsite}>
      <Image
        source={{
          uri: "https://clinic-os.com/user-app-assets/header-clinicos.png",
        }}
        style={styles.image}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: "auto",
  },
  image: {
    width: 116,
    height: 52,
  },
});

export default Header;
