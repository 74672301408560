import React, { useContext, useState } from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";

import global from "../../../../styles/globalStyles";
import { TRole } from "../../../../utils/types";
import { clientDomain } from "../../../../config";
import { SessionContext } from "../../../../contexts";
import { supabaseAdmin } from "../../../../utils/supabase";
import { addUserToClinic } from "../../../../services/api";
import { mixpanel } from "../../../../utils/mixpanel";

import { Close } from "../../../../assets/index";
import {
  Input,
  PrimaryButton,
  SecondaryButton,
  InputDropdown,
} from "../../../common/native/index";
import { roles } from "../../../../utils/constant";

type InviteAMemberModalProps = {
  onClose: () => void;
};

const InviteAMemberModal: React.FC<InviteAMemberModalProps> = ({ onClose }) => {
  const session = useContext(SessionContext)?.session;

  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleModalClose = async () => {
    setLoading(true);
    try {
      const options = {
        redirectTo: `${clientDomain}setup-password`,
      };
      const { data, error } = await supabaseAdmin.auth.admin.inviteUserByEmail(
        email,
        options,
      );

      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
        return;
      }

      if (!session || !session.adminToken || !session.clinicId) {
        setErrorMessage("Missing session or session data");
        setLoading(false);
        return;
      }

      let roleParam: TRole;
      if (role === "Admin") {
        roleParam = "CLINIC_ADMIN";
      } else if (role === "Member") {
        roleParam = "CLINIC_STAFF";
      } else {
        const errorMessage = "Unknown role provided";
        setErrorMessage(errorMessage);
        setLoading(false);
        throw new Error(errorMessage);
      }

      await addUserToClinic(
        session.adminToken,
        session.clinicId,
        data.user.id,
        roleParam,
      );

      mixpanel.track("User Invited", {
        "User Email": session.userEmail,
        "Clinic Name": session.clinicName,
        "Invited User Email": email,
      });

      onClose();
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred while inviting member");
      }
      setLoading(false);
    }
  };

  return (
    <View style={styles.modalView}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Invite a Team Member</Text>
        <Pressable onPress={onClose}>
          <Close />
        </Pressable>
      </View>
      <Input
        type="email-address"
        name="Email"
        placeholder=""
        value={email}
        onChangeText={setEmail}
        required
      />
      <View style={{ zIndex: 9999, width: "100%" }}>
        <InputDropdown
          onSelect={setRole}
          required
          name={"Role"}
          selections={roles}
          value={role}
        />
      </View>
      {errorMessage !== "" && (
        <Text style={global.errorText}>{errorMessage}</Text>
      )}
      <View style={styles.buttonContainer}>
        <PrimaryButton
          text="Add"
          roundBorderRadius
          onPress={handleModalClose}
          width={100}
          loading={loading}
          disabled={email && role ? false : true}
        />
        <SecondaryButton text="Cancel" roundBorderRadius onPress={onClose} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxWidth: 465,
    height: 370,
    padding: 20,
    borderRadius: 10,
    backgroundColor: "#fff",
    alignItems: "center",
    position: "relative",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  buttonContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    marginTop: "auto",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Urbanist600",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    letterSpacing: 0.2,
    fontStyle: "normal",
  },
});

export default InviteAMemberModal;
