import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Pressable,
  Text,
  useWindowDimensions,
} from "react-native";
import { Dialog } from "@rneui/base";
import { useNavigate } from "react-router-dom";

import global from "../../../styles/globalStyles";
import SessionContext from "../../../contexts/session";
import { supabase } from "../../../utils/supabase";
import { mixpanel } from "../../../utils/mixpanel";

import { Close, Hamburger, Person, PersonAdd } from "../../../assets/index";
import { ModalOpener } from "./modals";

type DashboardHeaderProps = {
  handleToggleHamburger: () => void;
  isMobileMenuOpen: boolean;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  handleToggleHamburger,
  isMobileMenuOpen,
}) => {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const session = useContext(SessionContext)?.session;
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <View style={styles.headerContainer}>
      <View style={styles.leftContainer}>
        <Pressable
          onPress={() => {
            if (!session) return;
            mixpanel.track("Viewed Simulate Page", {
              "User Email": session.userEmail,
              "Clinic Name": session.clinicName,
            });
          }}
        >
          <Image
            source={{
              uri: "https://clinic-os.com/user-app-assets/header-clinicos.png",
            }}
            style={styles.image}
          />
        </Pressable>
      </View>
      <View style={styles.rightContainer}>
        <Pressable onPress={() => setIsModalOpen(true)}>
          <PersonAdd />
        </Pressable>
        <Pressable onPress={toggleDialog}>
          <Person />
        </Pressable>
        {isMobile ? (
          <>
            <Pressable onPress={handleToggleHamburger}>
              {isMobileMenuOpen ? <Close /> : <Hamburger />}
            </Pressable>
          </>
        ) : null}
      </View>
      <Dialog
        isVisible={visible}
        animationType="fade"
        onBackdropPress={toggleDialog}
        overlayStyle={{
          position: "absolute",
          top: 56,
          right: 0,
          backgroundColor: "#fff",
          width: 174,
          padding: 18,
        }}
        backdropStyle={{
          backgroundColor: "rgba(0, 0, 0, 0)",
          // @ts-ignore
          cursor: "default",
        }}
      >
        <Pressable
          onPress={async () => {
            try {
              const { error } = await supabase.auth.signOut();
              if (error) throw new Error("Error signing out");
              if (!error) navigate("/signin");
            } catch (error) {}
          }}
        >
          <Text style={[global.text, { fontFamily: "Urbanist500" }]}>
            Sign out
          </Text>
        </Pressable>
      </Dialog>
      <ModalOpener
        visible={isModalOpen}
        modalType={"InviteAMember"}
        onClose={() => setIsModalOpen(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#DDDEE0",
    backgroundColor: "#fff",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 24,
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 24,
  },
  image: {
    width: 90,
    height: 30,
  },
});

export default DashboardHeader;
