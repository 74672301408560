import React from "react";
import { Text, Pressable, StyleSheet } from "react-native";

import global from "../../../styles/globalStyles";
import { supabase } from "../../../utils/supabase";
import { clientDomain } from "../../../config";

import { Google } from "../../../assets/index";

type LoginWithGoogleButtonProps = {
  name: string;
};

const LoginWithGoogleButton: React.FC<LoginWithGoogleButtonProps> = ({
  name,
}) => {
  const handleLoginWithGoogle = async () => {
    const options = {
      redirectTo: clientDomain,
    };
    supabase.auth.signInWithOAuth({
      provider: "google",
      options,
    });
  };

  return (
    <Pressable style={styles.container} onPress={handleLoginWithGoogle}>
      <Google size={24} />
      <Text style={[global.text, global.fontSize16, global.fontWeight500]}>
        {name}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    gap: 12,
    backgroundColor: "#fff",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#282B33",
  },
  image: {
    width: 24,
    height: 24,
  },
});

export default LoginWithGoogleButton;
