import React from "react";
import { View, StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";

import { Header, NormalText, PrimaryButton, TitleText } from "../common/native";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.containerContent}>
        <TitleText text={"Page not found!"} />
        <NormalText text={"The page you are looking for does not exist."} />
        <PrimaryButton
          text={"Go back to sign in"}
          roundBorderRadius
          onPress={() => navigate("/signin")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerContent: {
    alignItems: "center",
    margin: "auto",
    gap: 12,
  },
});

export default NotFound;
