import React, { useContext } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import { InputDropdown, NormalText, TitleText } from "../../../common/native";

import {
  getFormUsageMetricsV2ByPeriod,
  TGetFormUsageMetricsV2Response,
} from "../../../../services/api-analytics";
import { SessionContext } from "../../../../contexts";
import FunnelChart from "./FunnelChart";
import PieChart from "./PieChart";
import AreaChart from "./AreaChart";
import VisitorSimulationSubmission from "./VisitorSimulationSubmission";

type HomeAnalyticsProps = {};

const HomeAnalytics: React.FC<HomeAnalyticsProps> = ({}) => {
  const session = useContext(SessionContext)?.session;
  if (!session) return null;

  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const [weekMonthOrYear, setWeekMonthOrYear] = React.useState<string>("Week");
  const [isLoadingWeek, setIsLoadingWeek] = React.useState(true);
  const [isLoadingMonth, setIsLoadingMonth] = React.useState(true);
  const [isLoadingYear, setIsLoadingYear] = React.useState(true);

  const [formUsageMetricsDataWeek, setFormUsageMetricsDataWeek] =
    React.useState<TGetFormUsageMetricsV2Response | null>(null);
  const [formUsageMetricsDataMonth, setFormUsageMetricsDataMonth] =
    React.useState<TGetFormUsageMetricsV2Response | null>(null);
  const [formUsageMetricsDataYear, setFormUsageMetricsDataYear] =
    React.useState<TGetFormUsageMetricsV2Response | null>(null);

  const [weekError, setWeekError] = React.useState<boolean>(false);
  const [monthError, setMonthError] = React.useState<boolean>(false);
  const [yearError, setYearError] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchWeekData = async () => {
      try {
        const endDate = new Date();
        const startDateWeek = new Date(endDate);
        startDateWeek.setDate(startDateWeek.getDate() - 7);
        const result: TGetFormUsageMetricsV2Response =
          await getFormUsageMetricsV2ByPeriod(
            session,
            "onboarding-tier1",
            startDateWeek,
            endDate,
          );
        setFormUsageMetricsDataWeek(result);
        console.log("Week data:", result);
      } catch (error: any) {
        if (error.message.includes("404")) {
          return;
        }
        setWeekError(true);
      } finally {
        setIsLoadingWeek(false);
      }
    };
    fetchWeekData();
  }, [session]);

  React.useEffect(() => {
    const fetchMonthData = async () => {
      try {
        const endDate = new Date();
        const startDateMonth = new Date(endDate);
        startDateMonth.setDate(startDateMonth.getDate() - 30);

        const result: TGetFormUsageMetricsV2Response =
          await getFormUsageMetricsV2ByPeriod(
            session,
            "onboarding-tier1",
            startDateMonth,
            endDate,
          );
        setFormUsageMetricsDataMonth(result);
      } catch (error) {
        console.error("Error fetching month data:", error);
        setMonthError(true);
      } finally {
        setIsLoadingMonth(false);
      }
    };
    fetchMonthData();
  }, [session]);

  React.useEffect(() => {
    const fetchYearData = async () => {
      try {
        const endDate = new Date();
        const startDateYear = new Date(endDate);
        startDateYear.setDate(startDateYear.getDate() - 365);

        const result: TGetFormUsageMetricsV2Response =
          await getFormUsageMetricsV2ByPeriod(
            session,
            "onboarding-tier1",
            startDateYear,
            endDate,
          );
        setFormUsageMetricsDataYear(result);
      } catch (error) {
        console.error("Error fetching year data:", error);
        setYearError(true);
      } finally {
        setIsLoadingYear(false);
      }
    };
    fetchYearData();
  }, [session]);

  const getSelectedData = () => {
    switch (weekMonthOrYear) {
      case "Week":
        return {
          formUsageMetrics: formUsageMetricsDataWeek,
          isLoading: isLoadingWeek,
          isError: weekError,
        };
      case "Month":
        return {
          formUsageMetrics: formUsageMetricsDataMonth,
          isLoading: isLoadingMonth,
          isError: monthError,
        };
      case "Year":
        return {
          formUsageMetrics: formUsageMetricsDataYear,
          isLoading: isLoadingYear,
          isError: yearError,
        };
      default:
        return {
          formUsageMetrics: formUsageMetricsDataWeek,
          isLoading: isLoadingWeek,
          isError: weekError,
        };
    }
  };

  const { formUsageMetrics, isLoading, isError } = getSelectedData();

  if (!formUsageMetrics) return null;

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ gap: isMobile ? 10 : 20 }}>
        <View style={styles.analyticsTitleContainer}>
          <TitleText text="Analytics" style={{ marginTop: 5 }} />
          <View style={{ width: 100 }}>
            <InputDropdown
              name={""}
              selections={["Week", "Month", "Year"]}
              value={weekMonthOrYear}
              onSelect={(value) => setWeekMonthOrYear(value)}
            />
          </View>
        </View>
        {isLoading ? (
          <ActivityIndicator
            style={{ marginTop: 50 }}
            size="large"
            color="#00B7E0"
          />
        ) : isError ? (
          <NormalText text={"Something went wrong. Please try again later."} />
        ) : (
          <>
            <VisitorSimulationSubmission formUsageMetrics={formUsageMetrics} />

            <AreaChart
              weekMonthOrYear={weekMonthOrYear}
              formUsageMetrics={formUsageMetrics}
            />

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                flexWrap: "wrap",
                gap: isMobile ? 10 : 20,
              }}
            >
              <PieChart formUsageMetrics={formUsageMetrics} />

              <FunnelChart formUsageMetrics={formUsageMetrics} />
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flex: 1,
  },
  analyticsTitleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1,
  },
});

// TODO: Use router loader to load data instead of useEffect

export default HomeAnalytics;
