import { FC } from "react";

import "./UploadImage.css";
import UploadImageButton from "./UploadImageButton";

interface UploadImageProps {
  saveCurrentImage: (image: Blob) => void;
}

const UploadImage: FC<UploadImageProps> = ({ saveCurrentImage }) => {
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      saveCurrentImage(selectedFile);
    } else {
      alert("Please select a valid image file.");
      event.target.value = "";
    }
  };

  return (
    <div id="upload-image__outer">
      <img
        id="upload-image__img"
        src="https://clinic-os.com/try-our-ai-assets%2Fleft-front-right.png"
        alt="left right front"
      />
      <UploadImageButton saveCurrentImage={saveCurrentImage} />
    </div>
  );
};

export default UploadImage;
