import React from "react";
import { Slider } from "@rneui/themed";
import { StyleSheet, View, Text, useWindowDimensions } from "react-native";

interface RangeSliderProps {
  implantSize: number | null;
  value: number;
  min: number;
  max: number;
  onChange: (newValue: number) => void;
  handleTurnOnOffScrollingStep3?: (bool: boolean) => void;
}

let mousewheelListener: EventListener,
  domMouseScrollListener: EventListener,
  touchmoveListener: EventListener;

const disableScrolling = () => {
  mousewheelListener = (e: Event) => {
    e.preventDefault();
  };
  domMouseScrollListener = (e: Event) => {
    e.preventDefault();
  };
  touchmoveListener = (e: Event) => {
    e.preventDefault();
  };

  const options: AddEventListenerOptions = { passive: false };
  document.addEventListener("mousewheel", mousewheelListener, options);
  document.addEventListener("DOMMouseScroll", domMouseScrollListener, options);
  document.addEventListener("touchmove", touchmoveListener, options);
};

const enableScrolling = () => {
  const options: AddEventListenerOptions = { passive: true };
  document.removeEventListener("mousewheel", mousewheelListener, options);
  document.removeEventListener(
    "DOMMouseScroll",
    domMouseScrollListener,
    options,
  );
  document.removeEventListener("touchmove", touchmoveListener, options);
};

const ReactRange: React.FC<RangeSliderProps> = ({
  implantSize,
  value,
  min,
  max,
  onChange,
  handleTurnOnOffScrollingStep3 = () => {},
}: RangeSliderProps) => {
  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Slider
        value={value}
        onSlidingComplete={() => {
          enableScrolling();
          handleTurnOnOffScrollingStep3(true);
        }}
        onSlidingStart={() => {
          disableScrolling();
          handleTurnOnOffScrollingStep3(false);
        }}
        onValueChange={(newValue) => onChange(newValue)}
        maximumValue={max}
        minimumValue={min}
        step={1}
        minimumTrackTintColor="#00B7F4"
        thumbStyle={styles.thumb}
        trackStyle={styles.track}
        thumbProps={
          implantSize && {
            children: (
              <View
                style={{
                  bottom: 20,
                  right: isMobile ? 13 : 50,
                  backgroundColor: "#000",
                  width: isMobile ? 50 : 125,
                  padding: 2,
                  borderRadius: 3,
                }}
              >
                {isMobile ? (
                  <Text
                    style={{
                      fontFamily: "Urbanist600",
                      fontSize: 12.6,
                      color: "#fff",
                      textAlign: "center",
                    }}
                    numberOfLines={1}
                  >
                    {Math.round((value * implantSize) / max / 25) * 25}
                    cc
                  </Text>
                ) : (
                  <Text
                    style={{
                      fontFamily: "Urbanist600",
                      fontSize: 12.6,
                      color: "#fff",
                      textAlign: "center",
                    }}
                    numberOfLines={1}
                  >
                    Implant Size:{" "}
                    {Math.round((value * implantSize) / max / 25) * 25}
                    cc
                  </Text>
                )}
              </View>
            ),
          }
        }
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#ffffff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  track: {
    height: 4,
  },
});

export default ReactRange;
