import React, { FC, useState, useCallback } from "react";
import {
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  Text,
  TouchableOpacity,
  Modal,
} from "react-native";
import ColorPicker, {
  Panel1,
  Preview,
  OpacitySlider,
  HueSlider,
} from "reanimated-color-picker";
import PrimaryButton from "./PrimaryButton";

interface InputProps extends Omit<TextInputProps, "onChange"> {
  type?: "default" | "email-address" | "numeric" | "phone-pad";
  name: string;
  placeholder?: string;
  required?: boolean;
  style?: any;
  inputHeight?: number;
  onChange?: (color: string) => void;
}

interface InputProps extends Omit<TextInputProps, "onChange"> {
  type?: "default" | "email-address" | "numeric" | "phone-pad";
  name: string;
  placeholder?: string;
  required?: boolean;
  style?: any;
  inputHeight?: number;
  onChange?: (color: string) => void;
  initialValue?: string; // New prop for initial color value
  textWidth?: number;
}

const InputPatientDetailColorPicker: FC<InputProps> = ({
  type = "default",
  name,
  placeholder = "",
  style,
  inputHeight = 30,
  onChange,
  initialValue = "#ff0000", // Default to red if no initial value is provided
  textWidth = 120,
  ...otherProps
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>(initialValue);

  // Effect to update the selected color if the initialValue prop changes
  React.useEffect(() => {
    setSelectedColor(initialValue);
  }, [initialValue]);

  const onSelectColor = useCallback(
    ({ hex }: { hex: string }) => {
      setSelectedColor(hex);
      onChange?.(hex);
    },
    [onChange],
  );

  const toggleModal = useCallback(() => setShowModal((prev) => !prev), []);

  return (
    <View style={styles.inputContainer}>
      <View style={{ width: textWidth }}>
        <Text style={styles.name}>{name}</Text>
      </View>
      <TextInput
        style={[styles.textInput, { height: inputHeight }]}
        value={selectedColor}
        keyboardType={type}
        placeholder={placeholder}
        editable={false}
        {...otherProps}
        multiline={inputHeight > 30}
        maxLength={500}
      />
      <TouchableOpacity
        style={[styles.colorButton, { backgroundColor: selectedColor }]}
        onPress={toggleModal}
      />
      <Modal visible={showModal} animationType="fade" transparent>
        <View style={styles.modalContainer}>
          <View style={styles.colorPickerContainer}>
            <ColorPicker value={selectedColor} onComplete={onSelectColor}>
              <View style={{ gap: 15 }}>
                <Preview />
                <Panel1 />
                <HueSlider />
                <OpacitySlider />
                <PrimaryButton
                  text={"OK"}
                  roundBorderRadius
                  onPress={toggleModal}
                />
              </View>
            </ColorPicker>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
    position: "relative",
    paddingRight: 40,
  },
  name: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    color: "#282B33",
  },
  textInput: {
    width: "100%",
    maxWidth: 220,
    fontFamily: "Urbanist500",
    fontWeight: "500",
    borderRadius: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 16,
    color: "#000000",
  },
  colorButton: {
    position: "absolute",
    right: 0,
    width: 30,
    height: 30,
    borderWidth: 1,
    borderColor: "#C4C6CD",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
  colorPickerContainer: {
    backgroundColor: "#fff",
    padding: 20,
    width: "100%",
    maxWidth: 400,
    borderRadius: 10,
  },
  okButton: {
    backgroundColor: "#007AFF",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
    marginTop: 10,
  },
  okButtonText: {
    color: "#fff",
    fontFamily: "Urbanist600",
    fontSize: 16,
  },
});

export default InputPatientDetailColorPicker;
