import React, { useEffect, useState } from "react";
import {
  Text,
  Pressable,
  StyleSheet,
  View,
  useWindowDimensions,
  ScrollView,
} from "react-native";

import global from "../../../styles/globalStyles";

import {
  InputDropdown,
  ProcedureButtonLightBlue,
  Tab,
} from "../../common/native/index";
import { TReturnGetImagesBatchBatchId } from "../../../services/api";
import { TAreaInterest, TProcedure } from "../../../utils/types";
import {
  isBodyProcedure,
  isFaceProcedure,
  isTorsoProcedure,
} from "../../../utils/functions";
import DisplayOneSquare from "./DisplayOneSquare";
import DisplayOneSquareCompare from "./DisplayOneSquareCompare";

type PatientDetailSimulationProps = {
  patientImageData: TReturnGetImagesBatchBatchId[];
};

const PatientDetailSimulation: React.FC<PatientDetailSimulationProps> = ({
  patientImageData,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 644;

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProcedure, setSelectedProcedure] = useState<TProcedure | "">(
    "",
  );
  const [allProcedures, setAllProcedures] = useState<TProcedure[]>([]);
  const [initialTab, setInitialTab] = useState<TAreaInterest | "">();
  const [selectedSimulationTab, setSelectedSimulationTab] = useState<
    "Pre-op" | "Simulated" | "Compare"
  >("Compare");

  useEffect(() => {
    const allSimulatedProceduresDuplicate = patientImageData.flatMap(
      (item) => item.procedureType,
    );
    const allSimulatedProceduresUnique = Array.from(
      new Set(allSimulatedProceduresDuplicate),
    );
    const sortedAllSimulatedProceduresUnique =
      allSimulatedProceduresUnique.sort();
    setAllProcedures(sortedAllSimulatedProceduresUnique);

    if (allSimulatedProceduresUnique.length === 0) return;
    const firstProcedure = allSimulatedProceduresUnique[0];
    setSelectedProcedure(firstProcedure);
    if (isBodyProcedure(firstProcedure)) setInitialTab("Body");
    if (isTorsoProcedure(firstProcedure)) setInitialTab("Breast");
    if (isFaceProcedure(firstProcedure)) setInitialTab("Face");
    setLoading(false);
  }, [patientImageData]);

  const renderProcedureButtons = (areaOfInterest: TAreaInterest) => {
    return allProcedures
      .filter((item) => {
        if (areaOfInterest === "Body") return isBodyProcedure(item);
        if (areaOfInterest === "Breast") return isTorsoProcedure(item);
        if (areaOfInterest === "Face") return isFaceProcedure(item);
        return false;
      })
      .map((item, index) => (
        <ProcedureButtonLightBlue
          key={index}
          procedure={item}
          selected={item === selectedProcedure}
          onPress={() => setSelectedProcedure(item)}
        />
      ));
  };

  const renderAllComparePreopAndSimulatedTabContent = () => {
    return allProcedures.map((item, index) => {
      const currentImageData = patientImageData.filter((itemInner) =>
        itemInner.procedureType.includes(item),
      );
      return (
        <View key={index}>
          <View
            style={{
              display:
                selectedSimulationTab === "Compare" &&
                selectedProcedure === item
                  ? "flex"
                  : "none",
            }}
          >
            <DisplayOneSquareCompare
              currentImageData={currentImageData}
              selectedProcedure={item}
            />
          </View>
          <View
            style={{
              display:
                selectedSimulationTab === "Pre-op" && selectedProcedure === item
                  ? "flex"
                  : "none",
            }}
          >
            <DisplayOneSquare
              currentImageData={currentImageData}
              isAIImages={false}
            />
          </View>
          <View
            style={{
              display:
                selectedSimulationTab === "Simulated" &&
                selectedProcedure === item
                  ? "flex"
                  : "none",
            }}
          >
            <DisplayOneSquare
              currentImageData={currentImageData}
              isAIImages={true}
            />
          </View>
        </View>
      );
    });
  };

  const renderSimulationTab = () => {
    return (
      <>
        <View
          style={{
            flexDirection: "row",
            backgroundColor: "#F8F8F8",
            borderRadius: 10,
            marginBottom: 24,
            paddingHorizontal: 5,
            marginHorizontal: "auto",
          }}
        >
          <Pressable
            style={styles.beforeAICompareButton}
            onPress={() => setSelectedSimulationTab("Compare")}
          >
            <Text
              style={[
                global.text,
                global.fontWeight600,
                selectedSimulationTab === "Compare" &&
                  styles.beforeAICompareButtonSelected,
              ]}
            >
              Compare
            </Text>
          </Pressable>
          <Pressable
            style={styles.beforeAICompareButton}
            onPress={() => setSelectedSimulationTab("Pre-op")}
          >
            <Text
              style={[
                global.text,
                global.fontWeight600,
                selectedSimulationTab === "Pre-op" &&
                  styles.beforeAICompareButtonSelected,
              ]}
            >
              Pre-op
            </Text>
          </Pressable>
          <Pressable
            style={styles.beforeAICompareButton}
            onPress={() => setSelectedSimulationTab("Simulated")}
          >
            <Text
              style={[
                global.text,
                global.fontWeight600,
                selectedSimulationTab === "Simulated" &&
                  styles.beforeAICompareButtonSelected,
              ]}
            >
              Simulated
            </Text>
          </Pressable>
        </View>
        <View
          style={{
            width: isMobile ? "100%" : 387,
            minWidth: isMobile ? width - 88 : "auto",
            paddingHorizontal: 24,
            marginBottom: 20,
          }}
        >
          {renderAllComparePreopAndSimulatedTabContent()}
        </View>
      </>
    );
  };

  if (loading) return;

  if (isMobile)
    return (
      <>
        <View
          style={{
            zIndex: 1,
            marginVertical: 24,
            minWidth: isMobile ? width - 40 : "auto",
          }}
        >
          <InputDropdown
            name={""}
            selections={allProcedures}
            value={selectedProcedure}
            // @ts-ignore
            onSelect={setSelectedProcedure}
          />
        </View>
        {renderSimulationTab()}
      </>
    );

  return (
    <Tab
      tabNames={["Breast", "Body", "Face"]}
      initialTab={initialTab}
      tabContent={[
        <View key={0} style={{ flexDirection: "row", marginTop: 14 }}>
          <View
            style={{ gap: 12, width: "100%", maxWidth: 240, marginTop: 32 }}
          >
            {renderProcedureButtons("Breast")}
          </View>
          <View style={{ flexGrow: 1, alignItems: "center" }}>
            {renderSimulationTab()}
          </View>
        </View>,
        <View key={1} style={{ flexDirection: "row", marginTop: 14 }}>
          <View
            style={{ gap: 12, width: "100%", maxWidth: 240, marginTop: 32 }}
          >
            {renderProcedureButtons("Body")}
          </View>
          <View style={{ flexGrow: 1, alignItems: "center" }}>
            {renderSimulationTab()}
          </View>
        </View>,
        <View key={2} style={{ flexDirection: "row", marginTop: 14 }}>
          <View
            style={{ gap: 12, width: "100%", maxWidth: 240, marginTop: 32 }}
          >
            {renderProcedureButtons("Face")}
          </View>
          <View style={{ flexGrow: 1, alignItems: "center" }}>
            {renderSimulationTab()}
          </View>
        </View>,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  beforeAICompareButton: {
    width: 110,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  beforeAICompareButtonSelected: {
    backgroundColor: "#00B7F41A",
    textAlign: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    width: 120,
    borderRadius: 10,
  },
});

export default PatientDetailSimulation;
