import { config } from "../config";
import { TFormName, TSession } from "../utils/types";

const protocol = config.coreApi.protocol;
const host = config.coreApi.host;
const urlPrefix = `${protocol}://${host}/clinics/v1/analytics`;

export type TGetFormUsageMetricsResponse = {
  start_date: string;
  end_date: string;
  total_users: number;
  completion_rate: number;
  funnel_analytics: {
    percentage_entered_contact_info: number;
    percentage_selected_area_of_interest: number;
  };
  average_time_per_screen: Record<string, number>;
  daily_visits: Record<string, number>;
  daily_simulations: Record<string, number>;
  referrers: Record<string, string>;
  average_total_completion_time: number;
};

export const getFormUsageMetrics = async (
  session: TSession,
  start_date: Date,
  end_date: Date,
): Promise<TGetFormUsageMetricsResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  const formattedStartDate = start_date.toISOString().split("T")[0];
  const formattedEndDate = end_date.toISOString().split("T")[0];

  try {
    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/form-usage-metrics?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting form usage metrics", error);
    throw error;
  }
};

export type TGetFirstLogVisitedScreenResponse = {
  id: number;
  clinicUuid: string;
  visitorUuid: string;
  screenVisit: string;
  location: string;
  timeSpent: number;
  referrer: string;
};
export const getFirstLogVisitedScreen = async (
  accessToken: string,
  clinicId: string,
): Promise<TGetFirstLogVisitedScreenResponse> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(
    `${urlPrefix}/${clinicId}/first-log-visited-screen`,
    {
      method: "GET",
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  const result: TGetFirstLogVisitedScreenResponse = {
    id: data.id,
    clinicUuid: data.clinic_uuid ?? "",
    visitorUuid: data.visitor_uuid ?? "",
    screenVisit: data.screen_visit ?? "",
    location: data.location ?? "",
    timeSpent: data.time_spent ?? -1,
    referrer: data.referrer ?? "",
  };
  return result;
};

export type TGetFirstDailyAnalyticsResponse = {
  id: number;
  clinic_uuid: string;
  form_name: string;
};
export const getFirstDailyAnalytics = async (
  accessToken: string,
  clinicId: string,
  formName: TFormName,
): Promise<TGetFirstDailyAnalyticsResponse> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  const response = await fetch(
    `${urlPrefix}/${clinicId}/first-daily-analytics?form_name=${formName}`,
    {
      method: "GET",
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export type TGetFormUsageMetricsV2Response = {
  form_usage_analytics: {
    site_visits: number;
    form_visits: number;
    form_submissions: number;
    form_simulations: number;
    daily_site_visits: Record<string, number>;
    daily_form_visits: Record<string, number>;
    daily_form_submissions: Record<string, number>;
    daily_form_simulations: Record<string, number>;
  };
  referrer_count_analytics: {
    referrers_counts: Record<string, number>;
    total_referrers: number;
  };
};
export const getFormUsageMetricsV2ByPeriod = async (
  session: TSession,
  formName: TFormName,
  startDate: Date,
  endDate: Date,
): Promise<TGetFormUsageMetricsV2Response> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  const formattedStartDate = startDate.toISOString().split("T")[0];
  const formattedEndDate = endDate.toISOString().split("T")[0];

  const response = await fetch(
    `${urlPrefix}/${session.clinicId}/form-usage-metrics-v2?start_date=${formattedStartDate}&end_date=${formattedEndDate}&form_name=${formName}`,
    {
      method: "GET",
      headers,
    },
  );

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};
