import React from "react";
import { View } from "react-native";

interface CheckProps {
  size?: number;
}

const Check: React.FC<CheckProps> = ({ size = 25 }) => {
  return (
    <View style={{ width: size, height: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0302 7.96983C18.1706 8.11045 18.2495 8.30108 18.2495 8.49983C18.2495 8.69858 18.1706 8.8892 18.0302 9.02983L11.0302 16.0298C10.8895 16.1703 10.6989 16.2492 10.5002 16.2492C10.3014 16.2492 10.1108 16.1703 9.97015 16.0298L5.97015 12.0298C5.89647 11.9612 5.83736 11.8784 5.79637 11.7864C5.75538 11.6944 5.73334 11.5951 5.73156 11.4944C5.72979 11.3936 5.74831 11.2936 5.78603 11.2002C5.82375 11.1068 5.8799 11.022 5.95112 10.9508C6.02233 10.8796 6.10717 10.8234 6.20056 10.7857C6.29394 10.748 6.39397 10.7295 6.49468 10.7312C6.59538 10.733 6.69469 10.7551 6.78669 10.796C6.87869 10.837 6.96149 10.8961 7.03015 10.9698L10.5002 14.4398L16.9702 7.96983C17.1108 7.82938 17.3014 7.75049 17.5002 7.75049C17.6989 7.75049 17.8895 7.82938 18.0302 7.96983Z"
          fill="black"
        />
      </svg>
    </View>
  );
};

export default Check;
