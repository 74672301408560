import React, { useContext, useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  Pressable,
  View,
  Image,
  ScrollView,
  Modal,
  useWindowDimensions,
} from "react-native";

import global from "../../../../styles/globalStyles";
import { mixpanel } from "../../../../utils/mixpanel";
import { SessionContext } from "../../../../contexts";
import {
  adjustTextMap,
  areaInterests,
  availableBodyProcedure,
  availableBreastProcedure,
  availableFaceProcedure,
  iframeSrcBody,
  iframeSrcFace,
  iframeSrcTorso,
  initialUploadBodyImageData,
  initialUploadFaceImageData,
  initialUploadTorsoImageData,
  sexes,
} from "../../../../utils/constant";
import {
  checkUrlAvailability,
  pollForData,
  postImagesBatch,
  getSupportedProceduresForView,
  postTransformsBatch,
  getSupportedViewTypeAndProcedureType,
} from "../../../../services/api";
import {
  TAreaInterest,
  TProcedure,
  TUploadImage,
  TView,
  TTransformedItem,
  TTabName,
} from "../../../../utils/types";
import {
  capitalizeString,
  isBodyProcedure,
  isFaceProcedure,
  isTorsoProcedure,
} from "../../../../utils/functions";
import { supportedProcedures } from "../../../../utils/constant";

import {
  InputPatientDetail,
  PrimaryButton,
  InputPatientDetailDropdown,
  Tab,
  InputPatientDetailPhone,
  ProcedureButtonLightBlue,
  SecondaryButton,
  InputDropdown,
  ProcedureButton,
} from "../../../common/native/index";
import { UploadImage } from "../../../common/web/index";
import { Camera, Close } from "../../../../assets/index";
import TextLabel from "../../../common/native/TextLabel";
import ImageComparisonSlider from "../../web/image-comparison-slider/ImageComparisonSlider";
import { useNavigate } from "react-router-dom";
import {
  postPatients,
  TPatientInfo,
  TPostPatientsResponse,
} from "../../../../services/api-clinic";

type PatientData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  sex: string;
  areaInterest: string;
  procedures: string[];
};

type AddPatientModalProps = {
  onClose: () => void;
  addNewPatient: ((PatientListElement: TPatientInfo) => void) | null;
  currentPatientUuid: string | null;
  addPatientOrProcedure: "patient" | "procedure";
};

// TODO: Refactor this component to make it smaller
const AddPatientModal: React.FC<AddPatientModalProps> = ({
  onClose,
  addNewPatient,
  currentPatientUuid,
  addPatientOrProcedure,
}) => {
  const session = useContext(SessionContext)?.session;
  if (!session) return <p>Must have a session</p>;
  if (currentPatientUuid === null && addPatientOrProcedure === "procedure")
    return <Text>Wrong props</Text>;
  if (addNewPatient === null && addPatientOrProcedure === "patient")
    return <Text>Wrong props</Text>;
  if (addNewPatient === null && currentPatientUuid === null)
    return <Text>Wrong props</Text>;

  const { width } = useWindowDimensions();
  const isMobile = width < 644;
  const navigate = useNavigate();

  // For AddProcedure and AddPatient
  const [isAtLeastOneImageUpload, setIsAtLeastOneImageUpload] =
    useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [tabSimulate, setTabSimulate] = useState<TTabName | "">("");
  const [selectedImage, setSelectedImage] = useState<TView | "">("");
  const [selectedProcedure, setSelectedProcedure] = useState<TProcedure | "">(
    "",
  );
  const [uploadImageData, setUploadImageData] = useState<TUploadImage[]>([]);
  const [transformedData, setTransformedData] = useState<TTransformedItem[]>(
    [],
  );

  const [disableScrollingStepThree, setDisableScrollingStepThree] =
    useState<boolean>(true);
  const handleTurnOnOffScrollingStep3 = (bool: boolean) => {
    setDisableScrollingStepThree(bool);
  };

  const [isOutOfFreeSimulation, setIsOutOfFreeSimulation] =
    useState<boolean>(false);

  // AddPatient States
  const [patientData, setPatientData] = useState<PatientData>({
    firstName: process.env.EXPO_PUBLIC_ENV === "production" ? "" : "first",
    lastName: process.env.EXPO_PUBLIC_ENV === "production" ? "" : "last",
    email:
      process.env.EXPO_PUBLIC_ENV === "production" ? "" : "example@example.com",
    phone: process.env.EXPO_PUBLIC_ENV === "production" ? "+1" : "+1",
    sex: process.env.EXPO_PUBLIC_ENV === "production" ? "" : "Female",
    areaInterest: process.env.EXPO_PUBLIC_ENV === "production" ? "" : "",
    procedures: process.env.EXPO_PUBLIC_ENV === "production" ? [] : [],
  });
  const [procedureSelections, setProcedureSelections] = useState<TProcedure[]>(
    [],
  );

  // AddProcedure States
  const [selectedProceduresAddProcedure, setSelectedProceduresAddedProcedure] =
    useState<TProcedure[]>([]);
  const [tabProcedureStep23, setTabProcedureStep23] =
    useState<TTabName>("Breast");
  const [disabledTabIndexesAddProcedure, setDisabledTabIndexesAddProcedure] =
    useState<number[]>([]);

  useEffect(() => {
    const saveScannedImages = async () => {
      for (let i = 0; i < transformedData.length; i++) {
        try {
          const downloadedMetaData = await pollForData(
            transformedData[i].meta_data,
          );
          if (!downloadedMetaData)
            throw new Error("Failed to download metadata");
          if (downloadedMetaData.success === false) {
            setTransformedData((prevTransformedData) => {
              const updatedData = [...prevTransformedData];
              updatedData[i] = {
                ...updatedData[i],
                downloaded_after_urls: [],
                downloaded_meta_data: {
                  success: downloadedMetaData.success,
                  reason: downloadedMetaData.reason,
                  implantSize: downloadedMetaData.implantSize,
                },
              };
              return updatedData;
            });
          } else {
            const downloadedAfterUrls = await Promise.all(
              transformedData[i].presigned_after_urls.map(async (item) =>
                checkUrlAvailability(item),
              ),
            );

            setTransformedData((prevTransformedData) => {
              const updatedData = [...prevTransformedData];
              updatedData[i] = {
                ...updatedData[i],
                downloaded_meta_data: downloadedMetaData,
                downloaded_after_urls: downloadedAfterUrls,
              };
              return updatedData;
            });
          }
        } catch (error) {
          setTransformedData((prevTransformedData) => {
            const updatedData = [...prevTransformedData];
            updatedData[i] = {
              ...updatedData[i],
              downloaded_after_urls: [],
              downloaded_meta_data: {
                success: false,
                reason: "Failed to download metadata: Unknown error",
                implantSize: null,
              },
            };
            return updatedData;
          });
        }
      }
    };
    saveScannedImages();
  }, [currentStep]);

  useEffect(() => {
    const handleMessage = (ev: MessageEvent) => {
      const data = [...ev.data];

      const updatedImageData = [...uploadImageData];

      for (const ele of data) {
        if (ele.viewType === "TORSO_LEFT") {
          updatedImageData[4] = {
            ...uploadImageData[4],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "TORSO_LEFT_45") {
          updatedImageData[3] = {
            ...uploadImageData[3],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "TORSO_FRONT") {
          updatedImageData[2] = {
            ...uploadImageData[2],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "TORSO_RIGHT_45") {
          updatedImageData[1] = {
            ...uploadImageData[1],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "TORSO_RIGHT") {
          updatedImageData[0] = {
            ...uploadImageData[0],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }

        if (ele.viewType === "HEAD_LEFT") {
          updatedImageData[4] = {
            ...uploadImageData[4],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "HEAD_LEFT_45") {
          updatedImageData[3] = {
            ...uploadImageData[3],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "HEAD_FRONT") {
          updatedImageData[2] = {
            ...uploadImageData[2],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "HEAD_RIGHT_45") {
          updatedImageData[1] = {
            ...uploadImageData[1],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "HEAD_RIGHT") {
          updatedImageData[0] = {
            ...uploadImageData[0],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }

        if (ele.viewType === "BODY_FRONT") {
          updatedImageData[0] = {
            ...uploadImageData[0],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "BODY_BACK") {
          updatedImageData[1] = {
            ...uploadImageData[1],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "BODY_RIGHT") {
          updatedImageData[2] = {
            ...uploadImageData[2],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
        if (ele.viewType === "BODY_LEFT") {
          updatedImageData[3] = {
            ...uploadImageData[3],
            file: ele.blob,
            fileUrl: URL.createObjectURL(ele.blob),
          };
        }
      }

      setUploadImageData(updatedImageData);
      setModalVisible(false);
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [uploadImageData]);

  // AddProcedure Functions
  const handlePressProcedureButton = (procedure: TProcedure) => {
    if (selectedProceduresAddProcedure.includes(procedure)) {
      if (selectedProceduresAddProcedure.length === 1)
        setDisabledTabIndexesAddProcedure([]);
      setSelectedProceduresAddedProcedure(
        selectedProceduresAddProcedure.filter((item) => item !== procedure),
      );
    } else {
      if (isBodyProcedure(procedure)) {
        setDisabledTabIndexesAddProcedure([0, 2]);
      }
      if (isTorsoProcedure(procedure)) {
        setDisabledTabIndexesAddProcedure([1, 2]);
      }
      if (isFaceProcedure(procedure)) {
        setDisabledTabIndexesAddProcedure([0, 1]);
      }
      setSelectedProceduresAddedProcedure([
        ...selectedProceduresAddProcedure,
        procedure,
      ]);
    }
  };

  const handlePressNextStep1Procedure = async () => {
    setCurrentStep(2);
    setIsSubmitting(true);
    try {
      let initialUploadData;
      let viewTypes: TView[] = [];
      const selectedProcedure = selectedProceduresAddProcedure[0];
      if (isFaceProcedure(selectedProcedure)) {
        initialUploadData = initialUploadFaceImageData;
        viewTypes = [
          "HEAD_FRONT",
          "HEAD_LEFT_45",
          "HEAD_LEFT",
          "HEAD_RIGHT_45",
          "HEAD_RIGHT",
        ];
        setTabProcedureStep23("Face");
      } else if (isTorsoProcedure(selectedProcedure)) {
        initialUploadData = initialUploadTorsoImageData;
        viewTypes = [
          "TORSO_FRONT",
          "TORSO_LEFT_45",
          "TORSO_LEFT",
          "TORSO_RIGHT_45",
          "TORSO_RIGHT",
        ];
        setTabProcedureStep23("Breast");
      } else if (isBodyProcedure(selectedProcedure)) {
        initialUploadData = initialUploadBodyImageData;
        viewTypes = ["BODY_FRONT", "BODY_BACK", "BODY_LEFT", "BODY_RIGHT"];
        setTabProcedureStep23("Body");
      } else {
        setIsSubmitting(false);
        return;
      }

      const viewTypeAndProcedureTypes =
        await getSupportedViewTypeAndProcedureType(session, viewTypes);

      const viewTypesWithAISupport = viewTypeAndProcedureTypes
        .filter((item) =>
          selectedProceduresAddProcedure.includes(item.procedureType),
        )
        .map((item) => item.viewType);

      initialUploadData = initialUploadData.map((item) => ({
        ...item,
        isAISupported: viewTypesWithAISupport.includes(item.viewType),
      }));

      setUploadImageData(initialUploadData);
    } catch (error) {
      console.error("Error in handlePressNextStep1Procedure:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitProcedure = async () => {
    setIsSubmitting(true);
    try {
      if (!currentPatientUuid) {
        throw new Error("No patient uuid");
      }

      const viewTypeFile = uploadImageData
        .filter((item) => item.file)
        .map((item) => ({
          file: item.file,
          viewType: item.viewType,
          procedureTypes: selectedProceduresAddProcedure,
        }));

      const batchIdAndViewTypePresignedBeforeUrls = await postImagesBatch(
        session,
        currentPatientUuid,
        viewTypeFile,
      );

      const batchId = batchIdAndViewTypePresignedBeforeUrls.batchId;
      const viewTypePresignedBeforeUrls =
        batchIdAndViewTypePresignedBeforeUrls.viewTypeAndPresignedBeforeUrls;

      const handleUpdatePresignedBeforeUrl = async () => {
        const updatedImageData = uploadImageData.map((item) => {
          const newItem = viewTypePresignedBeforeUrls.find(
            (newItem) => newItem.viewType === item.viewType,
          );
          if (newItem) {
            return {
              ...item,
              presignedBeforeUrl: newItem.presignedBeforeUrl,
            };
          }
          return item;
        });

        setUploadImageData(updatedImageData);

        const viewTypesAndPresignedBeforeUrlsData = updatedImageData
          .filter((item) => item.presignedBeforeUrl)
          .map((item) => ({
            presignedBeforeUrl: item.presignedBeforeUrl,
            viewType: item.viewType,
          }));

        let image_url_procedure_type_procedure_view =
          await getSupportedProceduresForView(
            session,
            viewTypesAndPresignedBeforeUrlsData,
          );

        image_url_procedure_type_procedure_view =
          image_url_procedure_type_procedure_view.filter((item) =>
            selectedProceduresAddProcedure.includes(item.procedure_type),
          );

        const transformedDataRes = await postTransformsBatch(
          session,
          currentPatientUuid,
          batchId,
          image_url_procedure_type_procedure_view,
        );

        if (!transformedDataRes) return;
        setTransformedData(transformedDataRes);
        let filteredData = transformedDataRes?.filter(
          (item) => item.procedure_type === selectedProceduresAddProcedure[0],
        );

        if (filteredData && filteredData.length > 0) {
          setSelectedImage(filteredData[0].procedure_view);
          setSelectedProcedure(filteredData[0].procedure_type);
          if (isBodyProcedure(filteredData[0].procedure_type))
            setTabSimulate("Body");
          if (isTorsoProcedure(filteredData[0].procedure_type))
            setTabSimulate("Breast");
          if (isFaceProcedure(filteredData[0].procedure_type))
            setTabSimulate("Face");
        } else {
          if (transformedDataRes && transformedDataRes.length > 0) {
            setSelectedImage(transformedDataRes[0].procedure_view);
            setSelectedProcedure(transformedDataRes[0].procedure_type);
            if (isBodyProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Body");
            if (isTorsoProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Breast");
            if (isFaceProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Face");
          }
        }
      };
      await handleUpdatePresignedBeforeUrl();
    } catch (error: any) {
      if (error.message.includes("402")) setIsOutOfFreeSimulation(true);

      setIsSubmitting(false);
      setCurrentStep(3);
    }
    setIsSubmitting(false);
    setCurrentStep(3);
  };

  // AddPatient Functions
  const handlePressNextStep1Patient = async () => {
    setIsSubmitting(true);
    try {
      const viewTypes = uploadImageData.map((item) => item.viewType);
      const viewTypeAndProcedureTypes =
        await getSupportedViewTypeAndProcedureType(session, viewTypes);
      const viewTypesWithAISupport = viewTypeAndProcedureTypes
        .filter((item) => patientData.procedures.includes(item.procedureType))
        .map((item) => item.viewType);

      setUploadImageData((prevUploadImageData) => {
        return prevUploadImageData.map((item) => {
          if (viewTypesWithAISupport.includes(item.viewType)) {
            return {
              ...item,
              isAISupported: true,
            };
          }
          return item;
        });
      });
      setCurrentStep(2);
    } catch (error) {}
    setIsSubmitting(false);
  };

  const handleSubmitPatient = async () => {
    setIsSubmitting(true);
    const newPatient = {
      first_name: patientData.firstName,
      last_name: patientData.lastName,
      email: patientData.email,
      phone: patientData.phone,
      sex: patientData.sex,
      areas_of_interest: [patientData.areaInterest],
      procedures_of_interest: patientData.procedures,
    };
    try {
      const addedPatient: TPostPatientsResponse = await postPatients(
        session,
        newPatient,
      );
      if (addedPatient) {
        mixpanel.track("PatientAdded", {
          "User Email": session.userEmail,
          "Clinic Name": session.clinicName,
          Procedures: patientData.procedures,
        });
      }

      if (addNewPatient && addedPatient) {
        addNewPatient(addedPatient);
      }

      // call format data and call postImagesBatch
      const viewTypeFile = uploadImageData
        .filter((item) => item.file)
        .map((item) => ({
          file: item.file,
          viewType: item.viewType,
          procedureTypes: patientData.procedures,
        }));

      const batchIdAndViewTypePresignedBeforeUrls = await postImagesBatch(
        session,
        addedPatient.uuid,
        viewTypeFile,
      );

      const batchId = batchIdAndViewTypePresignedBeforeUrls.batchId;
      const viewTypePresignedBeforeUrls =
        batchIdAndViewTypePresignedBeforeUrls.viewTypeAndPresignedBeforeUrls;

      const handleUpdatePresignedBeforeUrl = async () => {
        const updatedImageData = uploadImageData.map((item) => {
          const newItem = viewTypePresignedBeforeUrls.find(
            (newItem) => newItem.viewType === item.viewType,
          );
          if (newItem) {
            return {
              ...item,
              presignedBeforeUrl: newItem.presignedBeforeUrl,
            };
          }
          return item;
        });

        const viewTypesAndPresignedUrlsData = updatedImageData
          .filter((item) => item.presignedBeforeUrl)
          .map((item) => ({
            presignedBeforeUrl: item.presignedBeforeUrl,
            viewType: item.viewType,
          }));

        let image_url_procedure_type_procedure_view =
          await getSupportedProceduresForView(
            session,
            viewTypesAndPresignedUrlsData,
          );

        image_url_procedure_type_procedure_view =
          image_url_procedure_type_procedure_view.filter((item) =>
            patientData.procedures.includes(item.procedure_type),
          );

        const transformedDataRes = await postTransformsBatch(
          session,
          addedPatient.uuid,
          batchId,
          image_url_procedure_type_procedure_view,
        );

        if (transformedDataRes) setTransformedData(transformedDataRes);

        let filteredData = transformedDataRes?.filter(
          (item) => item.procedure_type === patientData.procedures[0],
        );

        if (filteredData && filteredData.length > 0) {
          setSelectedImage(filteredData[0].procedure_view);
          setSelectedProcedure(filteredData[0].procedure_type);
          if (isBodyProcedure(filteredData[0].procedure_type))
            setTabSimulate("Body");
          if (isTorsoProcedure(filteredData[0].procedure_type))
            setTabSimulate("Breast");
          if (isFaceProcedure(filteredData[0].procedure_type))
            setTabSimulate("Face");
        } else {
          if (transformedDataRes && transformedDataRes.length > 0) {
            setSelectedImage(transformedDataRes[0].procedure_view);
            setSelectedProcedure(transformedDataRes[0].procedure_type);
            if (isBodyProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Body");
            if (isTorsoProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Breast");
            if (isFaceProcedure(transformedDataRes[0].procedure_type))
              setTabSimulate("Face");
          }
        }
      };
      await handleUpdatePresignedBeforeUrl();
    } catch (error: any) {
      if (error.message.includes("402")) setIsOutOfFreeSimulation(true);
      setIsSubmitting(false);
      setCurrentStep(3);
    }
    setIsSubmitting(false);
    setCurrentStep(3);
  };

  // AddPatient and AddProcedure Functions
  const handleFileUpload = (
    name: string,
    file: Blob | null,
    fileUrl: string | null,
  ) => {
    setIsAtLeastOneImageUpload(true);
    setUploadImageData(
      uploadImageData.map(
        (image): TUploadImage =>
          image.name === name
            ? {
                ...image,
                file,
                fileUrl: fileUrl ? fileUrl : "",
              }
            : image,
      ),
    );
  };

  // Render Functions
  const renderPatientImages = () => {
    const filteredData = transformedData.filter(
      (item) => item.procedure_type === selectedProcedure,
    );

    return filteredData.map((item, index) => (
      <Pressable
        key={index}
        onPress={() => setSelectedImage(item.procedure_view)}
        style={{
          maxWidth: 78,
          maxHeight: 78,
          ...(isMobile ? { flex: 1 } : { width: 78, height: 78 }),
          aspectRatio: 1,
        }}
      >
        <Image
          style={[
            styles.image,
            item.procedure_view === selectedImage && styles.selectedImageStyle,
          ]}
          source={{
            uri: item.image_url,
          }}
        />
      </Pressable>
    ));
  };

  const renderProceduresMobile = () => {
    transformedData.sort((a, b) =>
      a.procedure_type.localeCompare(b.procedure_type),
    );
    const duplicatedProcedure = transformedData.map(
      (item) => item.procedure_type,
    );
    const uniqueProcedure = [...new Set(duplicatedProcedure)];
    return (
      <View style={{ zIndex: 1 }}>
        <InputDropdown
          name={""}
          selections={uniqueProcedure}
          value={selectedProcedure}
          // @ts-ignore
          onSelect={setSelectedProcedure}
        />
      </View>
    );
  };

  const renderProcedures = (tab: TAreaInterest) => {
    const filteredData = transformedData.filter((item, index, self) => {
      const procedureType: TProcedure = item.procedure_type;
      switch (tab) {
        case "Face":
          return (
            isFaceProcedure(procedureType) &&
            self.findIndex((i) => i.procedure_type === procedureType) === index
          );
        case "Breast":
          return (
            isTorsoProcedure(procedureType) &&
            self.findIndex((i) => i.procedure_type === procedureType) === index
          );
        case "Body":
          return (
            isBodyProcedure(procedureType) &&
            self.findIndex((i) => i.procedure_type === procedureType) === index
          );
        default:
          return false;
      }
    });

    filteredData.sort((a, b) =>
      a.procedure_type.localeCompare(b.procedure_type),
    );

    return filteredData.map((item, index) => {
      return (
        <ProcedureButtonLightBlue
          key={index}
          procedure={item.procedure_type}
          selected={item.procedure_type === selectedProcedure}
          onPress={() => {
            setSelectedImage(item.procedure_view);
            setSelectedProcedure(item.procedure_type);
          }}
        />
      );
    });
  };

  const renderImageComparisonSlider = () => {
    return transformedData.map((item, index) => (
      <View
        key={index}
        style={{
          height: "100%",
          display:
            item.procedure_type === selectedProcedure &&
            item.procedure_view === selectedImage
              ? "flex"
              : "none",
        }}
      >
        <ImageComparisonSlider
          adjustText={
            selectedProcedure
              ? adjustTextMap[selectedProcedure]
              : "Adjust the output"
          }
          imageUrls={item.downloaded_after_urls}
          metaData={item.downloaded_meta_data}
          handleTurnOnOffScrollingStep3={handleTurnOnOffScrollingStep3}
        />
      </View>
    ));
  };

  const renderStepContent = () => {
    if (currentStep === 1 && addPatientOrProcedure === "procedure")
      return (
        <>
          <View style={{ marginBottom: "auto", width: "100%", flex: 1 }}>
            <Tab
              tabNames={["Breast", "Body", "Face"]}
              disabledTabIndexes={disabledTabIndexesAddProcedure}
              tabContent={[
                <View
                  key={0}
                  style={{
                    flexWrap: "wrap",
                    height: 310,
                    gap: 12,
                    alignItems: isMobile ? "center" : "flex-start",
                  }}
                >
                  {availableBreastProcedure.map((item, index) => (
                    <ProcedureButton
                      key={index}
                      procedure={item.procedure}
                      selected={selectedProceduresAddProcedure.includes(
                        item.procedure,
                      )}
                      onPress={handlePressProcedureButton}
                    />
                  ))}
                </View>,
                <View
                  key={1}
                  style={{
                    flexWrap: "wrap",
                    height: 310,
                    gap: 12,
                  }}
                >
                  {availableBodyProcedure.map((item, index) => (
                    <ProcedureButton
                      key={index}
                      procedure={item.procedure}
                      selected={selectedProceduresAddProcedure.includes(
                        item.procedure,
                      )}
                      onPress={handlePressProcedureButton}
                    />
                  ))}
                </View>,
                <View
                  key={2}
                  style={{
                    flex: 1,
                    flexWrap: "wrap",
                    gap: 12,
                  }}
                >
                  {availableFaceProcedure.map((item, index) => (
                    <ProcedureButton
                      key={index}
                      procedure={item.procedure}
                      selected={selectedProceduresAddProcedure.includes(
                        item.procedure,
                      )}
                      onPress={handlePressProcedureButton}
                    />
                  ))}
                </View>,
              ]}
            />
            {!isMobile && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  height: 100,
                  gap: 12,
                }}
              >
                <Text
                  style={[
                    global.text,
                    global.fontWeight600,
                    { paddingVertical: 7 },
                  ]}
                >
                  Selected Procedure:
                </Text>
                {selectedProceduresAddProcedure.map((procedure, index) => (
                  <TextLabel key={index} name={capitalizeString(procedure)} />
                ))}
              </View>
            )}
            {isMobile && (
              <View style={{ marginTop: "auto", marginBottom: 10 }}>
                <ScrollView
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                >
                  <View style={{ gap: 12, flexDirection: "row" }}>
                    {selectedProceduresAddProcedure.map((procedure, index) => (
                      <TextLabel
                        key={index}
                        name={capitalizeString(procedure)}
                      />
                    ))}
                  </View>
                </ScrollView>
              </View>
            )}
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PrimaryButton
              text={"Next"}
              disabled={selectedProceduresAddProcedure.length === 0}
              roundBorderRadius
              onPress={handlePressNextStep1Procedure}
            />
          </View>
        </>
      );

    if (currentStep === 1 && addPatientOrProcedure === "patient")
      return (
        <>
          <View
            style={{
              width: "100%",
              maxWidth: 380,
              marginTop: 16,
              marginBottom: "auto",
            }}
          >
            <View style={{ gap: 10 }}>
              <InputPatientDetail
                name={"First Name:"}
                value={patientData.firstName}
                onChangeText={(text) =>
                  setPatientData({ ...patientData, firstName: text })
                }
              />
              <InputPatientDetail
                name={"Last Name:"}
                value={patientData.lastName}
                onChangeText={(text) =>
                  setPatientData({ ...patientData, lastName: text })
                }
              />
              <InputPatientDetail
                name={"Email:"}
                value={patientData.email}
                onChangeText={(text) =>
                  setPatientData({ ...patientData, email: text })
                }
              />
              <InputPatientDetailPhone
                value={patientData.phone}
                onChange={(text) =>
                  setPatientData({ ...patientData, phone: text })
                }
              />
              <View style={{ zIndex: 3 }}>
                <InputPatientDetailDropdown
                  value={[patientData.sex]}
                  name="Sex:"
                  onSelect={(value) =>
                    setPatientData({ ...patientData, sex: value[0] })
                  }
                  selections={sexes}
                />
              </View>
              <View style={{ zIndex: 2 }}>
                <InputPatientDetailDropdown
                  value={
                    patientData.areaInterest ? [patientData.areaInterest] : []
                  }
                  name="Body Part:"
                  selections={areaInterests}
                  onSelect={(value) => {
                    const procedures = supportedProcedures.filter(
                      (procedure) => {
                        if (value[0] === "Face") {
                          return isFaceProcedure(procedure);
                        }
                        if (value[0] === "Breast") {
                          return isTorsoProcedure(procedure);
                        }
                        if (value[0] === "Body") {
                          return isBodyProcedure(procedure);
                        }
                        return false;
                      },
                    );
                    if (value[0] === "Face")
                      setUploadImageData(initialUploadFaceImageData);
                    if (value[0] === "Breast")
                      setUploadImageData(initialUploadTorsoImageData);
                    if (value[0] === "Body")
                      setUploadImageData(initialUploadBodyImageData);
                    setProcedureSelections(procedures);
                    setPatientData({
                      ...patientData,
                      areaInterest: value[0],
                      procedures: [],
                    });
                  }}
                />
              </View>
              {patientData.areaInterest !== "" ? (
                <View style={{ zIndex: 1 }}>
                  <InputPatientDetailDropdown
                    name="Procedures:"
                    value={patientData.procedures}
                    multiple
                    onSelect={(values) =>
                      setPatientData({ ...patientData, procedures: values })
                    }
                    selections={procedureSelections}
                  />
                </View>
              ) : null}
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: 10,
            }}
          >
            <PrimaryButton
              text={"Next"}
              disabled={
                patientData.firstName === "" ||
                patientData.lastName === "" ||
                patientData.email === "" ||
                patientData.phone === "" ||
                patientData.sex === "" ||
                patientData.areaInterest === "" ||
                patientData.procedures.length === 0
              }
              roundBorderRadius
              width={81}
              loading={isSubmitting}
              onPress={handlePressNextStep1Patient}
            />
          </View>
        </>
      );

    if (currentStep === 2)
      return (
        <>
          <View
            style={{
              width: "100%",
              marginBottom: "auto",
              marginTop: isMobile ? 0 : "auto",
            }}
          >
            <Tab
              tabNames={[
                addPatientOrProcedure === "patient"
                  ? (patientData.areaInterest as TTabName)
                  : tabProcedureStep23,
              ]}
              // TODO: Put this back when the body scan start working again
              // tabRight={
              //   <SecondaryButton
              //     text=""
              //     roundBorderRadius
              //     icon={<Camera />}
              //     onPress={() => setModalVisible(true)}
              //   />
              // }
              tabContent={[
                <View key={0}>
                  <ScrollView
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={{
                      marginTop: isMobile ? 0 : 12,
                      marginBottom: isMobile ? 12 : 44,
                    }}
                  >
                    <View style={{ gap: 12, flexDirection: "row" }}>
                      {patientData.procedures.map((procedure, index) => (
                        <TextLabel
                          key={index}
                          name={capitalizeString(procedure)}
                        />
                      ))}
                    </View>
                  </ScrollView>
                  <View
                    style={{
                      gap: 16,
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {uploadImageData.map((item) => (
                      <UploadImage
                        key={item.name}
                        isAISupported={item.isAISupported}
                        viewTypeImage={item.viewTypeImage}
                        disabled={isSubmitting}
                        initialValue={item.file}
                        name={item.name}
                        setImageState={(file, fileUrl) =>
                          handleFileUpload(item.name, file, fileUrl)
                        }
                      />
                    ))}
                  </View>
                  <Text
                    style={{
                      fontFamily: "Urbanist400",
                      fontSize: 14,
                      fontStyle: "italic",
                      marginTop: isMobile ? 12 : 44,
                    }}
                  >
                    Views marked with{" "}
                    <Text style={{ color: "#00B7F4" }}>AI*</Text> support AI
                    powered visualizations
                  </Text>
                </View>,
              ]}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <PrimaryButton
              text={"Simulate"}
              roundBorderRadius
              width={120}
              loading={isSubmitting}
              disabled={!isAtLeastOneImageUpload}
              onPress={() => {
                if (addPatientOrProcedure === "patient") handleSubmitPatient();
                if (addPatientOrProcedure === "procedure")
                  handleSubmitProcedure();
              }}
            />
          </View>
        </>
      );

    if (currentStep === 3 && isOutOfFreeSimulation) {
      return (
        <View
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: 36,
          }}
        >
          <View>
            <Text
              style={{
                fontFamily: "Urbanist400",
                fontWeight: "400",
                fontSize: 20,
                textAlign: "center",
                margin: "auto",
              }}
            >
              You're out of free monthly simulations!
            </Text>
            <Text
              style={{
                fontFamily: "Urbanist400",
                fontWeight: "400",
                fontSize: 20,
                textAlign: "center",
                margin: "auto",
              }}
            >
              Upgrade your plan to continue simulating.
            </Text>
          </View>
          <PrimaryButton
            roundBorderRadius
            text={"Upgrade Now"}
            onPress={() => navigate("/upgrade")}
          />
        </View>
      );
    }

    if (currentStep === 3)
      return (
        <>
          <ScrollView
            scrollEnabled={disableScrollingStepThree}
            style={{
              flex: 1,
              width: "100%",
              marginTop: 24,
            }}
          >
            <View
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                flexDirection: isMobile ? "column" : "row",
                gap: 12,
              }}
            >
              <View style={{ width: isMobile ? "100%" : 210, zIndex: 9999 }}>
                {isMobile && renderProceduresMobile()}
                {!isMobile && (
                  <Tab
                    tabNames={[
                      addPatientOrProcedure === "patient"
                        ? (patientData.areaInterest as TTabName)
                        : tabProcedureStep23,
                    ]}
                    initialTab={tabSimulate}
                    // @ts-ignore comment
                    onTabPress={(tabName) => setTabSimulate(tabName)}
                    tabContent={[
                      <View key={0}>
                        <ScrollView
                          style={{
                            height: 320,
                            marginTop: 12,
                          }}
                          showsVerticalScrollIndicator={false}
                        >
                          <View style={{ gap: 12 }}>
                            {renderProcedures(
                              addPatientOrProcedure === "patient"
                                ? (patientData.areaInterest as TAreaInterest)
                                : (tabProcedureStep23 as TAreaInterest),
                            )}
                          </View>
                        </ScrollView>
                      </View>,
                    ]}
                  />
                )}
              </View>
              <View
                style={{
                  width: isMobile ? "100%" : 300,
                  paddingHorizontal: 16,
                }}
              >
                {renderImageComparisonSlider()}
              </View>
              <View
                style={[
                  styles.fiveImagesContainer,
                  { marginBottom: 12 },
                  { flexDirection: isMobile ? "row" : "column" },
                  isMobile && { width: "100%" },
                ]}
              >
                <Text
                  style={[
                    global.text,
                    global.fontSize20,
                    global.fontWeight600,
                    global.textAlignCenter,
                    { display: isMobile ? "none" : "flex" },
                  ]}
                  numberOfLines={1}
                >
                  Patient Images
                </Text>
                {renderPatientImages()}
              </View>
            </View>
          </ScrollView>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: 24,
            }}
          >
            <PrimaryButton
              text={"Save"}
              loading={isSubmitting}
              width={83}
              roundBorderRadius
              onPress={() => onClose()}
            />
          </View>
        </>
      );
  };

  const getIframeSrc = () => {
    if (addPatientOrProcedure === "patient") {
      switch (patientData.areaInterest) {
        case "Face":
          return iframeSrcFace;
        case "Breast":
          return iframeSrcTorso;
        case "Body":
          return iframeSrcBody;
        default:
          return "";
      }
    } else {
      switch (tabProcedureStep23) {
        case "Face":
          return iframeSrcFace;
        case "Breast":
          return iframeSrcTorso;
        case "Body":
          return iframeSrcBody;
        default:
          return "";
      }
    }
  };

  return (
    <View style={[styles.modalView, { maxHeight: isMobile ? 2000 : 600 }]}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          Add a New Patient: Step {currentStep} of 3
        </Text>
        <Pressable onPress={() => onClose()}>
          <Close />
        </Pressable>
      </View>
      {renderStepContent()}
      <Modal visible={modalVisible} transparent animationType="fade">
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
          }}
        >
          <View
            style={{
              width: isMobile ? "100%" : 400,
              height: isMobile ? "100%" : 650,
              backgroundColor: "#fff",
              paddingRight: 8,
              borderRadius: 16,
              elevation: 8,
              shadowColor: "black",
              shadowOffset: { width: 2, height: 2 },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              overflow: "hidden",
            }}
          >
            <Pressable
              onPress={() => setModalVisible(false)}
              style={{ position: "absolute", top: 25, right: 20 }}
            >
              <Close />
            </Pressable>
            <iframe
              src={getIframeSrc()}
              style={{
                border: 0,
                display: getIframeSrc() ? "flex" : "none",
              }}
              allow="camera"
              height="100%"
              width="100%"
              scrolling="no"
            >
              Browser doesn't support iframe
            </iframe>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxWidth: 900,
    height: "100%",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    shadowColor: "#000",
    padding: 20,
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  titleContainer: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  fiveImagesContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
    borderWidth: 4,
    borderColor: "transparent",
  },
  selectedImageStyle: {
    borderColor: "#0000004D",
    // @ts-ignore comment
    filter: "brightness(0.8)",
  },
  unselectedProfileImage: {
    color: "#00B7F4",
    paddingVertical: 12,
    paddingHorizontal: 5,
  },
  preOpText: {
    backgroundColor: "#00B7F41A",
    fontSize: 16,
    fontFamily: "Urbanist600",
    textAlign: "center",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 10,
    marginRight: "auto",
  },
  title: {
    fontFamily: "Urbanist600",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    letterSpacing: 0.2,
    fontStyle: "normal",
  },
});

export default AddPatientModal;
