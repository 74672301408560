import React from "react";
import { View } from "react-native";
import Svg, { Path, Defs, ClipPath, G, Rect } from "react-native-svg";

interface GoogleIconProps {
  size?: number;
}

const GoogleIcon: React.FC<GoogleIconProps> = ({ size = 25 }) => {
  return (
    <View style={{ width: size, height: size }}>
      <Svg width={25} height={25} viewBox="0 0 25 25" fill="none">
        <G clipPath="url(#clip0_8259_986)">
          <Path
            d="M24.4886 12.5951C24.4886 11.5821 24.4069 10.8428 24.2302 10.0762H12.7391V14.6486H19.4841C19.3481 15.785 18.6138 17.4962 16.9819 18.6462L16.959 18.7992L20.5923 21.6328L20.844 21.6581C23.1558 19.5087 24.4886 16.3462 24.4886 12.5951Z"
            fill="#4285F4"
          />
          <Path
            d="M12.7391 24.6421C16.0436 24.6421 18.8177 23.5468 20.844 21.6577L16.9819 18.6458C15.9484 19.3713 14.5613 19.8779 12.7391 19.8779C9.50254 19.8779 6.75557 17.7286 5.77635 14.7578L5.63282 14.7701L1.85489 17.7135L1.80548 17.8517C3.8181 21.8766 7.95219 24.6421 12.7391 24.6421Z"
            fill="#34A853"
          />
          <Path
            d="M5.77635 14.7581C5.51797 13.9915 5.36844 13.17 5.36844 12.3213C5.36844 11.4724 5.51797 10.6511 5.76275 9.88442L5.75591 9.72115L1.93063 6.73047L1.80548 6.7904C0.975976 8.46062 0.500008 10.3362 0.500008 12.3213C0.500008 14.3064 0.975976 16.1819 1.80548 17.8521L5.77635 14.7581Z"
            fill="#FBBC05"
          />
          <Path
            d="M12.7391 4.76415C15.0373 4.76415 16.5875 5.76353 17.4715 6.59869L20.9256 3.2035C18.8042 1.21843 16.0436 0 12.7391 0C7.95219 0 3.8181 2.7654 1.80548 6.79028L5.76276 9.8843C6.75557 6.91354 9.50254 4.76415 12.7391 4.76415Z"
            fill="#EB4335"
          />
        </G>
        <Defs>
          <ClipPath id="clip0_8259_986">
            <Rect
              width={24}
              height={24.7273}
              fill="white"
              transform="translate(0.5)"
            />
          </ClipPath>
        </Defs>
      </Svg>
    </View>
  );
};

export default GoogleIcon;
