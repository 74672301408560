import React from "react";
import { Text, TextStyle, StyleSheet } from "react-native";

type NormalTextProps = {
  text: string;
  style?: TextStyle;
  numberOfLines?: number;
  fontWeight?: "600" | "500" | "400";
};

const NormalText: React.FC<NormalTextProps> = ({
  text,
  style,
  numberOfLines = 100,
  fontWeight = "500",
}) => {
  return (
    <Text
      style={[
        styles.titleText,
        style,
        fontWeight === "400" && styles.title400,
        fontWeight === "500" && styles.title500,
        fontWeight === "600" && styles.title600,
      ]}
      numberOfLines={numberOfLines}
    >
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  titleText: {
    fontSize: 16,
    color: "#000",
  },
  title400: {
    fontFamily: "Urbanist400",
    fontWeight: "400",
  },
  title500: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
  },
  title600: {
    fontFamily: "Urbanist600",
    fontWeight: "600",
  },
});

export default NormalText;
