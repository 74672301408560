import { TProcedure } from "../utils/types";

export const capitalizeString = (inputString: string) => {
  if (inputString === "BBL") return inputString;

  let words;
  let capitalizedString;
  if (inputString.includes("_")) {
    words = inputString.split("_");
    capitalizedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } else {
    words = inputString.split(" ");
    capitalizedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
  return capitalizedString;
};

export const convertDateToYYYYMMDD = (date: string): string => {
  const tempDate = new Date(date);
  const year = tempDate.getFullYear();
  const month = String(tempDate.getMonth() + 1).padStart(2, "0");
  const day = String(tempDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export function isFaceProcedure(procedure: TProcedure): boolean {
  const faceProcedures: TProcedure[] = [
    "FACE_LIFT",
    "RHINOPLASTY",
    "BLEPHAROPLASTY",
    "RHYTIDECTOMY",
    "OTOPLASTY",
    "FOREHEAD_LIFT",
    "CHIN_SURGERY",
    "CHEEK_AUGMENTATION",
    "BOTOX_INJECTIONS",
    "DERMAL_FILLERS",
    "LASER_TREATMENTS",
    "MICRODERMABRASION",
    "LASER_HAIR_REMOVAL",
  ];

  return faceProcedures.includes(procedure);
}

export function isTorsoProcedure(procedure: TProcedure): boolean {
  const torsoProcedures: TProcedure[] = [
    "BREAST_AUGMENTATION",
    "BREAST_REDUCTION",
    "BREAST_LIFT",
    "BREAST_LIFT_AND_AUGMENTATION",
  ];

  return torsoProcedures.includes(procedure);
}

export function isBodyProcedure(procedure: TProcedure): boolean {
  const bodyProcedures: TProcedure[] = ["LIPOSUCTION", "TUMMY_TUCK", "BBL"];

  return bodyProcedures.includes(procedure);
}
