import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";

import global from "../../styles/globalStyles";
import {
  DashboardHeaderNonLogin,
  InputCode,
  PatientDetailSimulation,
} from "../uncommon/native/index";
import { PrimaryButton } from "../common/native";
import {
  TReturnGetImagesBatchBatchId,
  getMgmtImagesBatchBatchId,
} from "../../services/api";

const ShareSimulation = () => {
  const [step, setStep] = useState<1 | 2>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInputCode, setUserInputCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [patientImageData, setPatientImageData] = useState<
    TReturnGetImagesBatchBatchId[]
  >([]);

  const handleOnPressVerify = async () => {
    setLoading(true);
    try {
      const simulationId =
        window.location.href.replace(/\/+$/, "").split("/").pop() || "";
      const getMgmtImagesBatchBatchIdRes = await getMgmtImagesBatchBatchId(
        simulationId,
        userInputCode,
      );
      setPatientImageData(getMgmtImagesBatchBatchIdRes);
      setStep(2);
    } catch (error) {
      setErrorMessage("Wrong code");
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <DashboardHeaderNonLogin />
      <View style={{ padding: 16, alignItems: "center", flex: 1 }}>
        {step === 1 && (
          <>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                maxWidth: 422,
                width: "100%",
                gap: 16,
              }}
            >
              <Text style={[global.title, global.textAlignCenter]}>
                Insert Code
              </Text>
              <InputCode storeUserInputCode={setUserInputCode} />
              {errorMessage && (
                <Text style={[global.errorText, global.textAlignCenter]}>
                  {errorMessage}
                </Text>
              )}
              <PrimaryButton
                text="Verify"
                fullWidth
                onPress={handleOnPressVerify}
                loading={loading}
                disabled={userInputCode.length === 6 ? false : true}
              />
            </View>
          </>
        )}
        {step === 2 && (
          <View style={{ width: "100%" }}>
            <PatientDetailSimulation patientImageData={patientImageData} />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default ShareSimulation;
