import React from "react";
import { StyleSheet, Text, View } from "react-native";

type TextLabelProps = {
  name: string;
};

const TextLabel: React.FC<TextLabelProps> = ({ name }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text} numberOfLines={1}>
        {name}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 183, 244, 0.10)",
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 10,
  },
  text: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    color: "#000",
  },
});

export default TextLabel;
