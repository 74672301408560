export interface CoreApiConfig {
  protocol: string;
  host: string;
}

export interface AppConfig {
  coreApi: CoreApiConfig;
}

let config: AppConfig;

if (process.env.EXPO_PUBLIC_ENV === "dev") {
  config = require("./dev").config;
} else if (process.env.EXPO_PUBLIC_ENV === "staging") {
  config = require("./staging").config;
} else if (process.env.EXPO_PUBLIC_ENV === "production") {
  config = require("./production").config;
} else {
  config = require("./local").config;
}

const getClientDomain = (): string => {
  let clientDomain: string = "";
  if (process.env.EXPO_PUBLIC_ENV === "dev") {
    clientDomain = "https://develop.d2zlqohvq9zdx.amplifyapp.com/";
  } else if (process.env.EXPO_PUBLIC_ENV === "staging") {
    clientDomain = "https://develop.d2zlqohvq9zdx.amplifyapp.com/";
  } else if (process.env.EXPO_PUBLIC_ENV === "production") {
    clientDomain = "https://app.clinicos.ai/";
  } else {
    clientDomain = "http://localhost:3000/";
  }
  return clientDomain;
};

const clientDomain = getClientDomain();

export { clientDomain, config };
