import React from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";
import * as Progress from "react-native-progress";
import { Clock } from "../../../assets";

type ProgressBarProps = {
  progress: number;
  title: string;
  timeLeft: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  title,
  timeLeft,
}) => {
  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  return (
    <View
      style={{
        width: "100%",
        maxWidth: isMobile ? 644 : 300,
        paddingHorizontal: isMobile ? 20 : 0,
      }}
    >
      {!isMobile ? (
        <View style={styles.headerContainer}>
          <Text style={styles.title}>{title}</Text>
          <View style={styles.timeContainer}>
            <Clock color="#868B97" />
            <Text style={styles.timeText}>{timeLeft}</Text>
          </View>
        </View>
      ) : null}
      <View style={styles.progressContainer}>
        <View
          style={[
            styles.marker,
            {
              backgroundColor: progress >= 0.17 ? "#fff" : "#00B7F4",
              left: "17%",
            },
          ]}
        />
        <View
          style={[
            styles.marker,
            {
              backgroundColor: progress >= 0.43 ? "#fff" : "#00B7F4",
              left: "45%",
            },
          ]}
        />
        <View
          style={[
            styles.marker,
            {
              backgroundColor: progress >= 0.75 ? "#fff" : "#00B7F4",
              left: "75%",
            },
          ]}
        />
        <Progress.Bar
          progress={progress}
          width={null}
          height={10}
          color="#00B7F4"
          unfilledColor="#DDDEE0"
          borderWidth={0}
          borderRadius={10}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    marginBottom: 8,
  },
  title: {
    fontSize: 16,
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#00B7F4",
  },
  timeContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,
  },
  timeText: {
    fontSize: 12,
    fontFamily: "Urbanist400",
    fontWeight: "400",
    color: "#868B97",
  },
  progressContainer: {
    position: "relative",
  },
  marker: {
    position: "absolute",
    top: "50%",
    transform: [{ translateY: -2 }],
    width: 4,
    height: 4,
    borderRadius: 10,
    zIndex: 1,
  },
});

export default ProgressBar;
