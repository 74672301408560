import React from "react";
import { View, Text, useWindowDimensions } from "react-native";
import {
  InputPatientDetail,
  NormalText,
  PrimaryButton,
  SecondaryButton,
} from "../../../common/native";
import { InputPatientDetailSurgeonPhoto } from "../../../common/web";
import { SessionContext } from "../../../../contexts";
import {
  postClinicPhoto,
  postClinicProfile,
  TPostClinicPhotoRequest,
  TPostClinicProfileRequest,
} from "../../../../services/api-clinic";

export type TProfile = {
  email: string;
  leadSurgeonName: string;
  clinicAddress: string;
  leadSurgeonPhoto: Blob | string | null;
};

type ProfileTabProps = {
  profile: TProfile;
  setProfile: React.Dispatch<React.SetStateAction<TProfile>>;
  onContinue: () => void;
};

const ProfileTab: React.FC<ProfileTabProps> = ({
  profile,
  setProfile,
  onContinue,
}) => {
  const session = React.useContext(SessionContext)?.session;

  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const isProfileComplete = Object.values(profile).every(
    (value) => value !== "" && value !== null,
  );

  const handleOnClickContinue = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(profile.email)) {
      setError("Invalid email address format");
      return;
    }

    try {
      setLoading(true);
      setError("");
      if (!session) throw new Error("Session is not defined");
      if (!profile.leadSurgeonPhoto)
        throw new Error("Lead surgeon photo is not defined");

      let photo_url = "";
      if (profile.leadSurgeonPhoto instanceof Blob) {
        const postClinicPhotoBody: TPostClinicPhotoRequest = {
          imageBlob: profile.leadSurgeonPhoto,
        };
        const postClinicPhotoRes = await postClinicPhoto(
          session,
          postClinicPhotoBody,
        );
        photo_url = postClinicPhotoRes.photo_url;
      }

      if (typeof profile.leadSurgeonPhoto === "string") {
        photo_url = profile.leadSurgeonPhoto;
      }

      const postClinicProfileBody: TPostClinicProfileRequest = {
        surgeon_info: {
          name: profile.leadSurgeonName,
          clinic_address: profile.clinicAddress,
          photo_url: photo_url,
        },
        emails: [profile.email],
      };
      await postClinicProfile(session, postClinicProfileBody);
      onContinue();
    } catch (error) {
      setError("Failed to set up your profile, please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flex: 1, gap: 10, marginTop: 10 }}>
      <NormalText text="Set up your profile" fontWeight="600" />
      <View style={{ maxWidth: 550, gap: 10 }}>
        <InputPatientDetail
          name={"Email for new lead notifications"}
          textWidth={isMobile ? 150 : 260}
          placeholder="email address"
          value={profile.email}
          onChangeText={(text) => setProfile({ ...profile, email: text })}
        />
        <InputPatientDetail
          name={"Lead surgeon name"}
          textWidth={isMobile ? 150 : 260}
          placeholder="name"
          value={profile.leadSurgeonName}
          onChangeText={(text) =>
            setProfile({ ...profile, leadSurgeonName: text })
          }
        />
        <InputPatientDetail
          name={"Clinic address"}
          textWidth={isMobile ? 150 : 260}
          placeholder="clinic address"
          value={profile.clinicAddress}
          onChangeText={(text) =>
            setProfile({ ...profile, clinicAddress: text })
          }
        />
        <InputPatientDetailSurgeonPhoto
          value={profile.leadSurgeonPhoto}
          onPhotoSelect={(imageBlob) =>
            setProfile({ ...profile, leadSurgeonPhoto: imageBlob })
          }
        />
        {error ? (
          <Text
            style={{
              fontFamily: "Urbanist500",
              fontWeight: "500",
              color: "red",
            }}
          >
            {error}
          </Text>
        ) : null}
      </View>
      <View
        style={{
          marginTop: "auto",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        <SecondaryButton
          text={"Continue without setting up"}
          roundBorderRadius
          width={275}
          onPress={onContinue}
        />
        <PrimaryButton
          text={"Continue"}
          disabled={!isProfileComplete}
          loading={loading}
          roundBorderRadius
          width={275}
          onPress={() => handleOnClickContinue()}
        />
      </View>
    </View>
  );
};

export default ProfileTab;
