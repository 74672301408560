import React, { useState, useEffect, useContext, useRef } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  StyleSheet,
  Text,
  View,
  Pressable,
  useWindowDimensions,
  Animated,
  SafeAreaView,
  ScrollView,
} from "react-native";

import { SessionContext } from "../../../contexts";
import { supabase } from "../../../utils/supabase";

import {
  Simulate as SimulateIcon,
  Patients as PatientsIcon,
  CaretLeft,
  Star,
  Analytics,
  Profile as ProfileIcon,
  Person,
} from "../../../assets/index";
import { NormalText } from "../../common/native";
import { DashboardHeader } from "../../uncommon/native/index";
import { ModalOpener } from "../../uncommon/native/modals/index";
import { THomeTab } from "../../../utils/types";
import { getFirstDailyAnalytics } from "../../../services/api-analytics";

const DESKTOPTABSIZESMALL = 65;
const TABSIZE = 200;

const HomeLayout: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const navigate = useNavigate();
  const session = useContext(SessionContext)?.session;

  const animatedTabSize = useRef(new Animated.Value(TABSIZE)).current;
  const spinValue = useRef(new Animated.Value(0)).current;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (session && !session.clinicId) setIsModalOpen(true);

    if (location.href.includes("patients")) return;
    if (location.href.includes("analytics")) return;
    if (location.href.includes("simulate")) return;
    if (location.href.includes("get-started")) return;
    if (location.href.includes("upgrade")) return;
    const fetchFirstLogVisitedScreen = async () => {
      try {
        if (session?.accessToken && session?.clinicId) {
          await getFirstDailyAnalytics(
            session?.accessToken,
            session?.clinicId,
            "onboarding-tier1",
          );
          navigate("patients");
        }
      } catch (error: any) {
        navigate("get-started");
      }
    };

    if (session) fetchFirstLogVisitedScreen();
  }, [session]);

  const spin = () => {
    Animated.timing(spinValue, {
      // @ts-ignore
      toValue: spinValue._value === 0 ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const animateTabSize = (toValue: number) => {
    Animated.timing(animatedTabSize, {
      toValue,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const animatedHeight = useRef(new Animated.Value(0)).current;

  const handleToggleHamburger = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    Animated.timing(animatedHeight, {
      toValue: isMobileMenuOpen ? 0 : height - 55,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const renderTabs = (isShowIcon: boolean) => {
    const tabs: THomeTab[] = [
      "Patients",
      "Analytics",
      "Simulate",
      "Get Started",
      "Upgrade",
    ];
    return tabs.map((tab) => {
      return (
        <NavLink
          key={tab}
          onClick={() => handleToggleHamburger()}
          to={`/${tab.toLowerCase().replace(" ", "-")}`}
          style={({ isActive }) => ({
            ...{
              textDecoration: "none",
              background: isActive ? "rgba(0, 183, 244, 0.1)" : "transparent",
              marginTop: tab === "Get Started" && isShowIcon ? "auto" : "0",
            },
          })}
        >
          <View style={styles.tabButton}>
            {isShowIcon && tab === "Analytics" && <Analytics />}
            {isShowIcon && tab === "Patients" && <PatientsIcon />}
            {isShowIcon && tab === "Simulate" && <SimulateIcon />}
            {isShowIcon && tab === "Get Started" && <Person />}
            {isShowIcon && tab === "Upgrade" && <Star />}
            <NormalText text={tab} numberOfLines={1} />
          </View>
        </NavLink>
      );
    });
  };

  if (!session) return null;

  return (
    <SafeAreaView>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <DashboardHeader
          handleToggleHamburger={handleToggleHamburger}
          isMobileMenuOpen={isMobileMenuOpen}
        />
        {isMobile ? (
          <Animated.View
            style={[
              styles.mobileMenu,
              {
                height: animatedHeight,
              },
            ]}
          >
            {renderTabs(false)}
            <Pressable
              style={[styles.tabButton]}
              onPress={async () => {
                try {
                  const { error } = await supabase.auth.signOut();
                  if (error) throw new Error("Error signing out");
                  if (!error) navigate("/signin");
                } catch (error) {}
              }}
            >
              <NormalText text="Sign Out" />
            </Pressable>
          </Animated.View>
        ) : null}
        <View
          style={[
            styles.dashboardBody,
            {
              height: height - 55,
              display: "flex",
              justifyContent: "center",
            },
          ]}
        >
          {!isMobile ? (
            <Animated.View
              style={[
                styles.dashboardBodyLeft,
                {
                  zIndex: 1,
                  width: animatedTabSize,
                },
              ]}
            >
              {renderTabs(true)}
              <Pressable
                style={{
                  zIndex: 999,
                  position: "absolute",
                  right: 0,
                  top: 28,
                  transform: "translate(50%, 0%)",
                  borderRadius: 50,
                  backgroundColor: "#fff",
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  elevation: 5,
                }}
                onPress={() => {
                  animateTabSize(
                    // @ts-ignore
                    animatedTabSize._value === TABSIZE
                      ? DESKTOPTABSIZESMALL
                      : TABSIZE,
                  );
                  spin();
                }}
              >
                <Animated.View
                  style={{
                    transform: [
                      {
                        rotate: spinValue.interpolate({
                          inputRange: [0, 1],
                          outputRange: ["0deg", "180deg"],
                        }),
                      },
                    ],
                  }}
                >
                  <CaretLeft />
                </Animated.View>
              </Pressable>
            </Animated.View>
          ) : null}
          <Animated.View
            style={[
              {
                padding: isMobile ? 10 : 20,
                width: isMobile
                  ? "100%"
                  : animatedTabSize.interpolate({
                      inputRange: [DESKTOPTABSIZESMALL, TABSIZE],
                      outputRange: [
                        width - DESKTOPTABSIZESMALL,
                        width - TABSIZE,
                      ],
                    }),
              },
            ]}
          >
            <Outlet />
          </Animated.View>
        </View>
        <ModalOpener
          visible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          modalType={"FillOutClinicInfo"}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  dashboardBody: {
    flexDirection: "row",
    width: "100%",
  },
  dashboardBodyLeft: {
    width: "100%",
    gap: 12,
    paddingVertical: 20,
    borderRightColor: "#DDDEE0",
    borderRightWidth: 1,
  },
  tabButton: {
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 24,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 4,
  },
  mobileMenu: {
    gap: 8,
    marginTop: 10,
    width: "100%",
    backgroundColor: "#fff",
    position: "absolute",
    top: 55,
    left: 0,
    right: 0,
    overflow: "hidden",
    zIndex: 9999,
  },
});

export default HomeLayout;
