import { FC, useEffect, useRef, useState } from "react";
import "./ImageCarousel.css";
import NextBtn from "../../../../assets/tryai-icons/NextBtn";
import PrevBtn from "../../../../assets/tryai-icons/PrevBtn";
import AddPhoto from "../../../../assets/icons/AddPhoto.icon";

type ImageCarouselProps = {
  saveCurrentImage: (value: Blob | string) => void;
  imageUrls: string[];
};

const ImageCarousel: FC<ImageCarouselProps> = ({
  saveCurrentImage,
  imageUrls,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [testWidth, setTestWidth] = useState<number>(0);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [prevBtnDisplay, setPrevBtnDisplay] = useState<boolean>(false);
  const [nextBtnDisplay, setNextBtnDisplay] = useState<boolean>(true);

  const isMobile = () => {
    const userAgent = navigator.userAgent;
    const mobileRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (userAgent === undefined) return false;
    return mobileRegex.test(userAgent);
  };

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        const width = sliderRef.current.getBoundingClientRect().width;
        setTestWidth(width / 3 - (width / 3) * 0.04);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleScroll = () => {
    if (sliderRef.current) {
      const scrollLeft = sliderRef.current.scrollLeft;
      const width = sliderRef.current.getBoundingClientRect().width;
      const scrollLeftNormalize = scrollLeft / width;
      setScrollPosition(scrollLeftNormalize);
      if (scrollLeftNormalize === 0) {
        setPrevBtnDisplay(false);
      } else {
        setPrevBtnDisplay(true);
      }
      if (scrollLeftNormalize >= 1.3) {
        setNextBtnDisplay(false);
      } else {
        setNextBtnDisplay(true);
      }
    }
  };

  const handleClickNextBtn = () => {
    if (sliderRef.current) {
      const width = sliderRef.current.getBoundingClientRect().width;
      if (sliderRef.current) {
        sliderRef.current.scrollBy({
          left: width / 3 + 3,
          behavior: "smooth",
        });
      }
    }
  };

  const handleClickPrevBtn = () => {
    if (sliderRef.current) {
      const width = sliderRef.current.getBoundingClientRect().width;
      if (sliderRef.current) {
        sliderRef.current.scrollBy({
          left: -(width / 3 + 3),
          behavior: "smooth",
        });
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      saveCurrentImage(selectedFile);
    } else {
      alert("Please select a valid image file.");
      event.target.value = "";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1%",
        }}
      >
        <div
          id="clinicos__prev-btn"
          style={{
            display: "initial",
            // display: isMobile() ? "none" : "initial",
            opacity: prevBtnDisplay ? "1" : "0",
            cursor: prevBtnDisplay ? "pointer" : "initial",
            pointerEvents: prevBtnDisplay ? "initial" : "none",
          }}
          onClick={handleClickPrevBtn}
        >
          <PrevBtn />
        </div>
        <div
          id="clinicos__image-slider"
          ref={sliderRef}
          style={{
            all: "initial",
            display: "flex",
            overflowX: "scroll",
            scrollSnapType: "x mandatory",
            width: `${isMobile() ? "90%" : "100%"}`,
            gap: "2%",
            borderRadius: "3px",
          }}
          onScroll={handleScroll}
        >
          <div
            style={{
              width: `${testWidth}px`,
              minWidth: `${testWidth}px`,
              height: `${testWidth}px`,
              borderRadius: "18px",
              cursor: "pointer",
              borderStyle: "solid",
              borderColor: "#8f91953b",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddPhoto />
            <span
              style={{
                fontFamily: "Urbanist500",
                fontWeight: "500",
                color: "#8f91953b",
              }}
            >
              Add photo
            </span>
            <input
              type="file"
              accept="image/*"
              multiple={false}
              onChange={handleFileChange}
              style={{
                position: "absolute",
                inset: 0,
                opacity: 0,
                cursor: "pointer",
                height: "inherit",
                width: "inherit",
              }}
            />
          </div>
          {imageUrls.map((image, index) => (
            <img
              style={{
                width: `${testWidth}px`,
                height: `${testWidth}px`,
                aspectRatio: 1,
                objectFit: "cover",
                borderRadius: "18px",
                cursor: "pointer",
                userSelect: "none",
              }}
              key={index}
              src={image}
              alt={`Image ${index}`}
              draggable={false}
              onClick={() => saveCurrentImage(image)}
            />
          ))}
        </div>
        <div
          id="clinicos__next-btn"
          style={{
            display: "initial",
            opacity: nextBtnDisplay ? "1" : "0",
            cursor: nextBtnDisplay ? "pointer" : "initial",
            pointerEvents: nextBtnDisplay ? "initial" : "none",
          }}
          onClick={handleClickNextBtn}
        >
          <NextBtn />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "2px",
          justifyItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "4px",
            position: "relative",
            margin: "5px 0 16px 0",
            padding: 0,
          }}
        >
          {imageUrls.map((_, index) => (
            <div
              key={index}
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "10px",
                background: "#EDEDED",
              }}
            />
          ))}
          <div
            style={{
              width: "32px",
              height: "8px",
              borderRadius: "10px",
              background: "#00B7F4",
              position: "absolute",
              left: scrollPosition * 36,
              margin: 0,
              padding: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
