import React, { useState } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import { useNavigate } from "react-router-dom";

import global from "../../styles/globalStyles";
import { supabase } from "../../utils/supabase";

import {
  Input,
  Header,
  TextLink,
  PrimaryButton,
  OrSeperator,
  FooterLinks,
  GoogleLoginBtn,
} from "../common/native/index";

const Signin = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const login = (email: string, password: string) =>
    supabase.auth.signInWithPassword({ email, password });

  const handleOnPressLogInWithEmail = async () => {
    setLoading(true);
    try {
      const { data, error } = await login(email, password);
      if (error) setErrorMessage(error.message);
      if (!error) navigate("/");
    } catch (error) {
      setErrorMessage("Error in Logging In");
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Header />
      <View
        style={{
          width: "100%",
          maxWidth: 434,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text style={[global.title, { marginBottom: 12 }]}>Welcome Back</Text>
        <Text
          style={[
            global.text,
            global.fontWeight600,
            global.fontSize18,
            { marginBottom: 24 },
          ]}
        >
          New to ClinicOS?{" "}
          <TextLink
            to={"/signup"}
            text={"Sign up"}
            style={[global.blue, global.fontSize18]}
          />
        </Text>
        <GoogleLoginBtn name={"Login with Google"} />
        <OrSeperator />
        {errorMessage !== "" && (
          <Text style={global.errorText}>{errorMessage}</Text>
        )}
        <Input
          type="email-address"
          name="Email"
          placeholder="name@work-email.com"
          value={email}
          onChangeText={setEmail}
          required
        />
        <Input
          name="Password"
          placeholder="Enter your password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          required
        />
        <View style={styles.forgotPasswordContainer}>
          <TextLink to="/forgot-password" text="Forgot password?" />
        </View>
        <PrimaryButton
          text="Log In with Email"
          fullWidth
          onPress={handleOnPressLogInWithEmail}
          disabled={email && password ? false : true}
          loading={loading}
        />
      </View>
      <FooterLinks />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    minHeight: "100%",
    width: "100%",
    gap: 32,
    padding: 16,
  },
  forgotPasswordContainer: {
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    paddingTop: 12,
    paddingBottom: 32,
  },
});

export default Signin;
