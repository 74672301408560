import { TProcedure } from "../../../../utils/types";

export const simulateTabBreastAugImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-1.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-2.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-11.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-4.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-5.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-6.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-7.png",
];

export const simulateTabBreastLiftImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-8.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-9.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-10.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-11.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-12.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-13.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-14.png",
];

export const simulateTabBreastReduxImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-15.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-16.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-17.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-18.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-19.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-20.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-21.png",
];

export const simulateTabBreastLiftPlusAugImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-40.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-9.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-10.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-41.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-12.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-13.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-14.png",
];

export const simulateTabBblImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-22.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-23.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-24.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-25.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-26.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-27.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-28.png",
];

export const simulateTabTummyTuckImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-29.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-30.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-31.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-32.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-33.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-34.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-35.png",
];

export const simulateTabFaceLiftImages = [
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-36.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-37.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-38.png",
  "https://clinic-os.com/try-our-ai-assets%2Fmodel-39.png",
];

export const simulateTabBreastProcedure: TProcedure[] = [
  "BREAST_AUGMENTATION",
  "BREAST_LIFT",
  "BREAST_REDUCTION",
  "BREAST_LIFT_AND_AUGMENTATION",
];

export const simulateTabBodyProcedure: TProcedure[] = ["BBL", "TUMMY_TUCK"];
