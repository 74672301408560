import React from "react";
import { View } from "react-native";

interface LeftRightHandleProps {
  color?: string;
}

const LeftRightHandle: React.FC<LeftRightHandleProps> = ({ color }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4883 14.9269C29.4883 22.935 22.9964 29.4269 14.9883 29.4269C6.98015 29.4269 0.488281 22.935 0.488281 14.9269C0.488281 6.91875 6.98015 0.42688 14.9883 0.42688C22.9964 0.42688 29.4883 6.91875 29.4883 14.9269Z"
        fill={color || "rgba(128, 128, 128, 0.2)"} // Transparent gray color
      />
      <path
        d="M20.5 9.8878L26.125 14.9269L20.5 19.9698Z"
        fill={color || "rgba(255, 255, 255, 0.9)"} // Slightly transparent white color
      />
      <path
        d="M9.5 9.8878L3.875 14.9269L9.5 19.9698Z"
        fill={color || "rgba(255, 255, 255, 0.9)"} // Slightly transparent white color
      />
      <path
        opacity="0.05"
        d="M15.2266 3.27844L15.2266 27.0489"
        stroke="black"
        strokeWidth="1.90164"
      />
    </svg>
  );
};

export default LeftRightHandle;
