import React from "react";
import { Modal, StyleSheet, View } from "react-native";

import { TModal, TPlan, TSimulationLinkAndPin } from "../../../../utils/types";

import ShareReportPdfModal from "./ShareReportPdfModal";
import ShareEngageLinkModal from "./ShareEngageLinkModal";
import FillOutClinicInfoModal from "./FillOutClinicInfoModal";
import InviteAMemberModal from "./InviteAMemberModal";
import AddPatientOrProcedureModal from "./AddPatientOrProcedureModal";
import ConfirmUpdatePlanModal from "./ConfirmUpdatePlanModal";
import { TPatientInfo } from "../../../../services/api-clinic";

type ModalOpenerProps = {
  visible: boolean;
  modalType: TModal;
  onClose: () => void;
  addNewPatient?: ((newPatient: TPatientInfo) => void) | null;
  currentPatientUuid?: string | null;
  simulationLinkAndPin?: TSimulationLinkAndPin | null;
  planToUpgrade?: TPlan | null;
};

const ModalOpener: React.FC<ModalOpenerProps> = ({
  visible,
  onClose,
  modalType,
  addNewPatient = null,
  currentPatientUuid = null,
  simulationLinkAndPin = null,
  planToUpgrade = null,
}) => {
  const handleModalClose = () => {
    onClose();
  };

  const renderModal = () => {
    switch (modalType) {
      case "AddANewPatient":
        if (!addNewPatient) return null;
        return (
          <AddPatientOrProcedureModal
            onClose={handleModalClose}
            addNewPatient={addNewPatient}
            currentPatientUuid={null}
            addPatientOrProcedure="patient"
          />
        );
      case "AddANewProcedure":
        if (!currentPatientUuid) return null;
        return (
          <AddPatientOrProcedureModal
            onClose={handleModalClose}
            addNewPatient={null}
            addPatientOrProcedure="procedure"
            currentPatientUuid={currentPatientUuid}
          />
        );
      case "ShareEngageLink":
        return <ShareEngageLinkModal onClose={handleModalClose} />;
      case "ShareReportPdf":
        return (
          <ShareReportPdfModal
            onClose={handleModalClose}
            simulationLinkAndPin={simulationLinkAndPin}
          />
        );
      case "FillOutClinicInfo":
        return <FillOutClinicInfoModal onClose={handleModalClose} />;
      case "InviteAMember":
        return <InviteAMemberModal onClose={handleModalClose} />;
      case "ConfirmUpdatePlan":
        if (!planToUpgrade) return null;
        return (
          <ConfirmUpdatePlanModal
            onClose={handleModalClose}
            planToUpgrade={planToUpgrade}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        if (modalType === "FillOutClinicInfo") return;
        handleModalClose();
      }}
    >
      <View style={styles.centeredView}>{renderModal()}</View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    padding: 16,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ModalOpener;
