import React, { useState } from "react";
import Input from "react-phone-number-input/input";

type InputPatientDetailPhoneProps = {
  value?: string;
  onChange?: (phone: string) => void;
};

const InputPatientDetailPhone: React.FC<InputPatientDetailPhoneProps> = ({
  value = "+1",
  onChange,
}) => {
  const [phone, setPhone] = useState<string | undefined>(value);

  const handleChange = (newValue?: string) => {
    setPhone(newValue);
    if (onChange) {
      onChange(newValue || "");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <p
        style={{
          width: "130px",
          fontFamily: "Urbanist500",
          fontWeight: 500,
          fontSize: 16,
          color: "#282B33",
          letterSpacing: 0.032,
          padding: 0,
          margin: 0,
          flexShrink: 0, // Add this line
        }}
      >
        Phone:
      </p>
      <Input
        style={{
          width: "100%",
          maxWidth: "226px",
          fontFamily: "Urbanist600",
          fontSize: 16,
          color: "#282B33",
          letterSpacing: 0.032,
          padding: "0px 16px",
          margin: 0,
          height: "30px",
          border: "1px solid #C4C6CD",
          borderRadius: "10px",
        }}
        placeholder=""
        country="US"
        international
        withCountryCallingCode
        value={phone}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputPatientDetailPhone;
