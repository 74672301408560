import React from "react";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import { TProcedure } from "../../../utils/types";
import { capitalizeString } from "../../../utils/functions";
import { procedureIconMap } from "../../../utils/constant";

type ProcedureButtonProps = {
  procedure: TProcedure;
  selected: boolean;
  onPress: (name: TProcedure) => void;
  isBeta?: boolean;
  disabled?: boolean;
};

const ProcedureButton: React.FC<ProcedureButtonProps> = ({
  procedure,
  selected,
  onPress,
  isBeta = false,
  disabled = false,
}) => {
  return (
    <Pressable
      disabled={disabled}
      onPress={() => onPress(procedure)}
      style={[
        styles.container,
        {
          backgroundColor: selected ? "#00B7F4" : "#fff",
          opacity: disabled ? 0.5 : 1,
        },
      ]}
    >
      <View style={styles.iconContainer}>
        <Image
          style={styles.icon}
          source={{ uri: procedureIconMap[procedure] }}
        />
      </View>
      {isBeta ? (
        <Text
          style={[styles.text]}
        >{`${capitalizeString(procedure)} (Beta)`}</Text>
      ) : (
        <Text style={[styles.text]}>{capitalizeString(procedure)}</Text>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    width: 275,
    paddingHorizontal: 16,
    paddingVertical: 10,
    gap: 10,
    borderRadius: 20,
    elevation: 4,
    shadowColor: "rgba(0, 0, 0, 0.16)",
    shadowRadius: 10,
    shadowOpacity: 0.6,
    userSelect: "none",
  },
  iconContainer: {
    backgroundColor: "#F8F8F8",
    borderRadius: 50,
    padding: 10,
  },
  icon: {
    width: 25,
    height: 25,
    aspectRatio: 1,
  },
  text: {
    color: "#212121",
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    letterSpacing: 0.2,
  },
});

export default ProcedureButton;
