import React from "react";
import { Header, PrimaryButton, TitleText } from "../common/native";
import { Text, View, StyleSheet } from "react-native";
import { useNavigate } from "react-router-dom";

const CheckoutReturn = () => {
  const navigate = useNavigate();
  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.containerContent}>
        <TitleText text={"Thank you for your purchase!"} />
        <PrimaryButton
          text="Back to Home"
          onPress={() => navigate("/")}
          roundBorderRadius
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerContent: {
    alignItems: "center",
    margin: "auto",
    gap: 12,
  },
});

export default CheckoutReturn;
