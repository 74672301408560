import React, { useContext } from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";

import { VictoryArea, VictoryAxis, VictoryChart } from "victory-native";
import { SessionContext } from "../../../../contexts";
import { TGetFormUsageMetricsV2Response } from "../../../../services/api-analytics";
import { NormalText } from "../../../common/native";

type AreaChartProps = {
  weekMonthOrYear: string;
  formUsageMetrics: TGetFormUsageMetricsV2Response;
};

const AreaChart: React.FC<AreaChartProps> = ({
  weekMonthOrYear,
  formUsageMetrics,
}) => {
  const session = useContext(SessionContext);
  if (!session) return null;

  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const getTickValues = () => {
    if (weekMonthOrYear === "Week") {
      return Object.keys(
        formUsageMetrics.form_usage_analytics.daily_site_visits,
      ).map((date) => date.slice(5));
    }

    if (weekMonthOrYear === "Month") {
      return Object.keys(
        formUsageMetrics.form_usage_analytics.daily_site_visits,
      ).map((date, index) => {
        if (index % 7 === 0) {
          return date.slice(5);
        }
        return " ".repeat(index);
      });
    }

    if (weekMonthOrYear === "Year") {
      return Object.keys(
        formUsageMetrics.form_usage_analytics.daily_site_visits,
      ).map((date, index) => {
        if (index % 30 === 0) {
          return date.slice(0, 7);
        }
        return " ".repeat(index);
      });
    }
  };

  return (
    <View style={styles.analyticsElementContainer}>
      <NormalText
        text="Website Visits"
        fontWeight="600"
        style={{ marginBottom: 20, paddingLeft: 20, paddingTop: 20 }}
      />
      {formUsageMetrics.form_usage_analytics.site_visits === 0 ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            gap: 10,
          }}
        >
          <NormalText
            text="This form has not been viewed yet"
            fontWeight="600"
          />
          <NormalText text="No data to show" />
        </View>
      ) : (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: -25,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              gap: 20,
              position: "absolute",
              top: 10,
              right: 40,
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 50,
                  backgroundColor: "#00B7F4",
                }}
              />
              <NormalText
                text="Site Visits"
                fontWeight="500"
                style={{ color: "#D7D8DC" }}
              />
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 50,
                  backgroundColor: "#4CBF8B",
                }}
              />
              <NormalText
                text="Form Visits"
                fontWeight="500"
                style={{ color: "#D7D8DC" }}
              />
            </View>
          </View>
          <VictoryChart width={width}>
            <defs>
              <linearGradient
                id="gradientBlue"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#00B7F4" stopOpacity={1} />
                <stop offset="100%" stopColor="#00B7F4" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient
                id="gradientGreen"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#4CBF8B" stopOpacity={1} />
                <stop offset="100%" stopColor="#4CBF8B" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <VictoryAxis
              dependentAxis
              style={{
                axis: { stroke: "transparent" }, // This makes the axis line disappear
                grid: {
                  stroke: "#D7D8DC",
                  strokeWidth: 1,
                },
                tickLabels: {
                  textAnchor: "end",
                  fontSize: isMobile ? 12 : 14,
                  padding: 10,
                  fill: "#868B97", // Change this to your desired color
                },
              }}
            />
            <VictoryAxis
              tickValues={getTickValues()}
              style={{
                axis: { stroke: "#D7D8DC", strokeWidth: 1 }, // This makes the axis line disappear
                tickLabels: {
                  angle: 45,
                  textAnchor: "start",
                  fontSize: isMobile ? 12 : 14,
                  padding: 5,
                  fill: "#868B97", // Change this to your desired color
                },
              }}
            />
            <VictoryArea
              interpolation={"monotoneX"}
              style={{
                data: {
                  fill: "url(#gradientBlue)",
                  strokeWidth: 3,
                  stroke: "#00B7F4",
                },
              }}
              data={Object.entries(
                formUsageMetrics.form_usage_analytics.daily_site_visits,
              ).map(([date, visits], index) => {
                return {
                  x: index,
                  y: visits,
                };
              })}
            />
            <VictoryArea
              interpolation={"monotoneX"}
              style={{
                data: {
                  fill: "url(#gradientGreen)",
                  strokeWidth: 3,
                  stroke: "#4CBF8B",
                },
              }}
              data={Object.entries(
                formUsageMetrics.form_usage_analytics.daily_form_visits,
              ).map(([date, visits], index) => {
                return {
                  x: index,
                  y: visits,
                };
              })}
            />
          </VictoryChart>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  analyticsElementContainer: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#DDDEE0",
    paddingBottom: 10,
  },
});

export default AreaChart;
