import React, { useState } from "react";

const PrevBtn: React.FC = () => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseDown = () => {
    setClicked(true);
  };

  const handleMouseUp = () => {
    setClicked(false);
  };

  const handleTouchStart = () => {
    setClicked(true);
  };

  const handleTouchEnd = () => {
    setClicked(false);
  };

  return (
    <svg
      width="20"
      height="38"
      viewBox="0 0 20 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <rect
        x="0.974609"
        y="37.6392"
        width="37.377"
        height="18.6885"
        rx="9.34426"
        transform="rotate(-90 0.974609 37.6392)"
        fill={clicked ? "#EFEFEF" : hovered ? "#00B7F4" : "#EFEFEF"}
      />
      <path
        d="M12.6484 18.9484C12.6484 18.7615 12.5737 18.584 12.4429 18.4532L9.18172 15.2107C9.0509 15.0705 8.87336 15.0051 8.69582 15.0051C8.50893 15.0051 8.33139 15.0705 8.19123 15.2107C7.92024 15.491 7.92024 15.9302 8.20057 16.2012L10.9571 18.9484L8.20057 21.6956C7.92024 21.9666 7.92024 22.4151 8.19122 22.6861C8.47155 22.9664 8.91073 22.9664 9.18172 22.6861L12.4429 19.4437C12.5737 19.3128 12.6484 19.1353 12.6484 18.9484Z"
        fill={clicked ? "#B5B5B5" : hovered ? "#F2F2F2" : "#B5B5B5"}
        transform="rotate(180 10 19)"
      />
    </svg>

    // <svg
    //   width="26"
    //   height="49"
    //   viewBox="0 0 26 49"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   onMouseEnter={handleMouseEnter}
    //   onMouseLeave={handleMouseLeave}
    //   onMouseDown={handleMouseDown}
    //   onMouseUp={handleMouseUp}
    //   onTouchStart={handleTouchStart}
    //   onTouchEnd={handleTouchEnd}
    // >
    //   <rect
    //     y="48.89"
    //     width="48.89"
    //     height="25.9725"
    //     rx="12.9863"
    //     transform="rotate(-90 0 48.89)"
    //     fill={clicked ? "#EFEFEF" : hovered ? "#00B7F4" : "#EFEFEF"}
    //   />
    //   <path
    //     d="M8 24.45C8 24.85 8.16 25.23 8.44 25.51L15.42 32.45C15.7 32.75 16.08 32.89 16.46 32.89C16.86 32.89 17.24 32.75 17.54 32.45C18.12 31.85 18.12 30.91 17.52 30.33L11.62 24.45L17.52 18.57C18.12 17.99 18.12 17.03 17.54 16.45C16.94 15.85 16 15.85 15.42 16.45L8.44 23.39C8.16 23.67 8 24.05 8 24.45Z"
    //     fill={clicked ? "#B5B5B5" : hovered ? "#F2F2F2" : "#B5B5B5"}
    //   />
    // </svg>
  );
};

export default PrevBtn;
