import { TAreaInterest, TProcedure, TSex, TUploadImage } from "../utils/types";

export const initialUploadTorsoImageData: TUploadImage[] = [
  {
    name: "Left Side",
    viewType: "TORSO_LEFT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbreast-pose-bold%2Fbreast-procedures-transparent-left-bold.png",
    isAISupported: false,
  },
  {
    name: "Left Oblique",
    viewType: "TORSO_LEFT_45",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbreast-pose-bold%2Fbreast-procedures-transparent-left-oblique-bold.png",
    isAISupported: false,
  },
  {
    name: "Front",
    viewType: "TORSO_FRONT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbreast-pose-bold%2Fbreast-procedures-transparent-front-bold.png",
    isAISupported: false,
  },
  {
    name: "Right Oblique",
    viewType: "TORSO_RIGHT_45",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbreast-pose-bold%2Fbreast-procedures-transparent-right-oblique-bold.png",
    isAISupported: false,
  },
  {
    name: "Right Side",
    viewType: "TORSO_RIGHT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbreast-pose-bold%2Fbreast-procedures-transparent-right-bold.png",
    isAISupported: false,
  },
];

export const initialUploadBodyImageData: TUploadImage[] = [
  {
    name: "Front",
    viewType: "BODY_FRONT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbody-pose-bold%2Fbody-procedures-transparent-front-bold.png",
    isAISupported: false,
  },
  {
    name: "Back",
    viewType: "BODY_BACK",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbody-pose-bold%2Fbody-procedures-transparent-back-bold.png",
    isAISupported: false,
  },
  {
    name: "Left Side",
    viewType: "BODY_LEFT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbody-pose-bold%2Fbody-procedures-transparent-left-bold.png",
    isAISupported: false,
  },
  {
    name: "Right Side",
    viewType: "BODY_RIGHT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fbody-pose-bold%2Fbody-procedures-transparent-right-bold.png",
    isAISupported: false,
  },
];

export const initialUploadFaceImageData: TUploadImage[] = [
  {
    name: "Left Side",
    viewType: "HEAD_LEFT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fface-pose-bold%2Fface-procedures-transparent-left-bold.png",
    isAISupported: false,
  },
  {
    name: "Left Oblique",
    viewType: "HEAD_LEFT_45",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fface-pose-bold%2Fface-procedures-transparent-left-oblique-bold.png",
    isAISupported: false,
  },
  {
    name: "Front",
    viewType: "HEAD_FRONT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fface-pose-bold%2Fface-procedures-transparent-front-bold.png",
    isAISupported: false,
  },
  {
    name: "Right Oblique",
    viewType: "HEAD_RIGHT_45",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fface-pose-bold%2Fface-procedures-transparent-right-oblique-bold.png",
    isAISupported: false,
  },
  {
    name: "Right Side",
    viewType: "HEAD_RIGHT",
    file: null,
    fileUrl: "",
    presignedBeforeUrl: "",
    viewTypeImage:
      "https://clinic-os.com/flow-assets%2Fbody-scanner-ai%2Fface-pose-bold%2Fface-procedures-transparent-right-bold.png",
    isAISupported: false,
  },
];

export const iframeSrcBody = `${process.env.EXPO_PUBLIC_ENV === "production" ? "https://main.dp9ch69srufqq.amplifyapp.com" : "https://develop.d1lclqnbikydqw.amplifyapp.com"}/?formName=onboarding-scan-body-prod&clinicSdkKey=407e489a-e04d-4a69-aed4-6ee9c563390a&themeColor=%2300B7F4#Body_Scan_Instruction_1`;
export const iframeSrcTorso = `${process.env.EXPO_PUBLIC_ENV === "production" ? "https://main.dp9ch69srufqq.amplifyapp.com" : "https://develop.d1lclqnbikydqw.amplifyapp.com"}/?formName=onboarding-scan-torso-prod&clinicSdkKey=407e489a-e04d-4a69-aed4-6ee9c563390a&themeColor=%2300B7F4#Body_Scan_Instruction_1`;
export const iframeSrcFace = `${process.env.EXPO_PUBLIC_ENV === "production" ? "https://main.dp9ch69srufqq.amplifyapp.com" : "https://develop.d1lclqnbikydqw.amplifyapp.com"}/?formName=onboarding-scan-face-prod&clinicSdkKey=407e489a-e04d-4a69-aed4-6ee9c563390a&themeColor=%2300B7F4#Body_Scan_Instruction_1`;

export const procedureIconMap: Record<TProcedure, string> = {
  BREAST_AUGMENTATION:
    "https://clinic-os.com/icons-plastic-surgery/breast_augmentation_icon.webp",
  BREAST_REDUCTION:
    "https://clinic-os.com/icons-plastic-surgery/breast_reduction_icon.webp",
  BREAST_LIFT:
    "https://clinic-os.com/icons-plastic-surgery/breast_lift_icon.webp",
  BREAST_LIFT_AND_AUGMENTATION:
    "https://clinic-os.com/icons-plastic-surgery/breast_augmentation_icon.webp",
  RHINOPLASTY:
    "https://clinic-os.com/icons-plastic-surgery/rhinoplasty_icon.webp",
  OTOPLASTY: "https://clinic-os.com/icons-plastic-surgery/otoplasty_icon.webp",
  FOREHEAD_LIFT:
    "https://clinic-os.com/icons-plastic-surgery/forehead_lift_icon.webp",
  CHIN_SURGERY:
    "https://clinic-os.com/icons-plastic-surgery/chin_surgery_icon.webp",
  LIPOSUCTION:
    "https://clinic-os.com/icons-plastic-surgery/liposuction_icon.webp",
  BODY_LIFT: "https://clinic-os.com/icons-plastic-surgery/body_lift_icon.webp",
  BUTTOCK_AUGMENTATION:
    "https://clinic-os.com/icons-plastic-surgery/buttock_augmentation_icon.webp",
  BBL: "https://clinic-os.com/icons-plastic-surgery/bbl_icon.webp",
  ARM_LIFT: "https://clinic-os.com/icons-plastic-surgery/arm_lift_icon.webp",
  THIGH_LIFT:
    "https://clinic-os.com/icons-plastic-surgery/thigh_lift_icon.webp",
  BOTOX_INJECTIONS:
    "https://clinic-os.com/icons-plastic-surgery/botox_injections_icon.webp",
  TUMMY_TUCK: "https://clinic-os.com/icons-plastic-surgery/tummy_tuck.png",
  FACE_LIFT: "https://clinic-os.com/icons-plastic-surgery/face_lift_icon.webp",
  BLEPHAROPLASTY: "",
  RHYTIDECTOMY: "",
  CHEEK_AUGMENTATION: "",
  BURN_REPAIR: "",
  SCAR_REVISION: "",
  HAND_SURGERY: "",
  RECONSTRUCTIVE_SURGERY: "",
  DERMAL_FILLERS: "",
  LASER_TREATMENTS: "",
  MICRODERMABRASION: "",
  LASER_HAIR_REMOVAL: "",
};

export const availableBreastProcedure: {
  procedure: TProcedure;
  procedureIcon: string;
}[] = [
  {
    procedure: "BREAST_AUGMENTATION",
    procedureIcon: procedureIconMap.BREAST_AUGMENTATION,
  },
  {
    procedure: "BREAST_LIFT",
    procedureIcon: procedureIconMap.BREAST_LIFT,
  },
  {
    procedure: "BREAST_REDUCTION",
    procedureIcon: procedureIconMap.BREAST_REDUCTION,
  },
  {
    procedure: "BREAST_LIFT_AND_AUGMENTATION",
    procedureIcon: procedureIconMap.BREAST_LIFT_AND_AUGMENTATION,
  },
];

export const availableBodyProcedure: {
  procedure: TProcedure;
  procedureIcon: string;
}[] = [
  {
    procedure: "BBL",
    procedureIcon: procedureIconMap.BBL,
  },
  {
    procedure: "TUMMY_TUCK",
    procedureIcon: procedureIconMap.TUMMY_TUCK,
  },
];

export const availableFaceProcedure: {
  procedure: TProcedure;
  procedureIcon: string;
}[] = [
  // {
  //   procedure: "FACE_LIFT",
  //   procedureIcon: procedureIconMap.FACE_LIFT,
  // },
];

export const adjustTextMap: Record<TProcedure | "", string> = {
  BREAST_AUGMENTATION: "Adjust the implant size",
  BREAST_LIFT_AND_AUGMENTATION: "Adjust the implant size",
  BREAST_LIFT: "Adjust amount of lift",
  BBL: "Adjust BBL amount",
  BREAST_REDUCTION: "",
  TUMMY_TUCK: "",
  RHINOPLASTY: "Adjust the output",
  BLEPHAROPLASTY: "Adjust the output",
  RHYTIDECTOMY: "Adjust the output",
  OTOPLASTY: "Adjust the output",
  FOREHEAD_LIFT: "Adjust the output",
  CHIN_SURGERY: "Adjust the output",
  CHEEK_AUGMENTATION: "Adjust the output",
  LIPOSUCTION: "Adjust the output",
  BODY_LIFT: "Adjust the output",
  BUTTOCK_AUGMENTATION: "Adjust the output",
  ARM_LIFT: "Adjust the output",
  THIGH_LIFT: "Adjust the output",
  BURN_REPAIR: "Adjust the output",
  SCAR_REVISION: "Adjust the output",
  HAND_SURGERY: "Adjust the output",
  RECONSTRUCTIVE_SURGERY: "Adjust the output",
  BOTOX_INJECTIONS: "Adjust the output",
  DERMAL_FILLERS: "Adjust the output",
  LASER_TREATMENTS: "Adjust the output",
  MICRODERMABRASION: "Adjust the output",
  LASER_HAIR_REMOVAL: "Adjust the output",
  "": "Adjust the output",
  FACE_LIFT: "Adjust the output",
};

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "District of Columbia",
  "Other",
];

export const titles = ["Mr.", "Mrs.", "Miss.", "Dr.", "Prof.", "Other"];

export const roles = ["Admin", "Member"];

export const sexes: TSex[] = ["Male", "Female", "Other"];

export const areaInterests: TAreaInterest[] = ["Face", "Breast", "Body"];

export const supportedProcedures: TProcedure[] = [
  "BREAST_AUGMENTATION",
  "BREAST_LIFT",
  "BREAST_LIFT_AND_AUGMENTATION",
  "BREAST_REDUCTION",
  "BBL",
  "TUMMY_TUCK",
  // "FACE_LIFT",
  // "Rhinoplasty",
  // "Blepharoplasty",
  // "Rhytidectomy",
  // "Otoplasty",
  // "Forehead Lift",
  // "Chin Surgery",
  // "Cheek Augmentation",
  // "Liposuction",
  // "Tummy Tuck",
  // "Body Lift",
  // "Buttock Augmentation",
  // "BBL",
  // "Arm Lift",
  // "Thigh Lift",
  // "Burn Repair",
  // "Scar Revision",
  // "Hand Surgery",
  // "Reconstructive Surgery",
  // "Botox Injections",
  // "Dermal Fillers",
  // "Laser Treatments",
  // "Microdermabrasion",
  // "Laser Hair Removal",
];
