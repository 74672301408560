import React, { useContext } from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";

import { SessionContext } from "../../../../contexts";
import { NormalText } from "../../../common/native";
import { TGetFormUsageMetricsV2Response } from "../../../../services/api-analytics";

type FunnelChartProps = {
  formUsageMetrics: TGetFormUsageMetricsV2Response;
};

const FunnelChart: React.FC<FunnelChartProps> = ({ formUsageMetrics }) => {
  const session = useContext(SessionContext);
  if (!session) return null;

  const { width, height } = useWindowDimensions();

  const funnelFirstElementRef = React.useRef(null);
  const funnelSecondElementRef = React.useRef(null);
  const funnelThirdElementRef = React.useRef(null);

  const [funnelFirstElementWidth, setFunnelFirstElementWidth] =
    React.useState<number>(0);
  const [funnelSecondElementWidth, setFunnelSecondElementWidth] =
    React.useState<number>(0);
  const [funnelThirdElementWidth, setFunnelThirdElementWidth] =
    React.useState<number>(0);

  React.useEffect(() => {
    // @ts-ignore
    const funnelFirstElementWidth = funnelFirstElementRef?.current?.offsetWidth;
    setFunnelFirstElementWidth(funnelFirstElementWidth);
    const funnelSecondElementWidth =
      // @ts-ignore
      funnelSecondElementRef?.current?.offsetWidth;
    setFunnelSecondElementWidth(funnelSecondElementWidth);
    // @ts-ignore
    const funnelThirdElementWidth = funnelThirdElementRef?.current?.offsetWidth;
    setFunnelThirdElementWidth(funnelThirdElementWidth);
  }, [width, height, formUsageMetrics]);

  return (
    <View style={{ flex: 1, minWidth: 350 }}>
      <View style={[styles.analyticsElementContainer]}>
        <NormalText
          text="Funnel Analytics"
          style={{ marginBottom: 20 }}
          fontWeight="600"
        />
        {formUsageMetrics.form_usage_analytics.site_visits === 0 ? (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              gap: 10,
            }}
          >
            <NormalText
              text="This form has not been viewed yet"
              fontWeight="600"
            />
            <NormalText text="No data to show" />
          </View>
        ) : (
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <View
                ref={funnelFirstElementRef}
                style={[styles.funnelSquare, { width: "100%" }]}
              >
                <NormalText
                  text={formUsageMetrics.form_usage_analytics.site_visits.toString()}
                />
                <NormalText text="100%" />
              </View>

              <View
                style={{
                  width: "100%",
                  borderTopWidth: 25,
                  borderTopColor: "rgba(0, 183, 244, 0.5)",
                  borderLeftColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftWidth:
                    (funnelFirstElementWidth - funnelSecondElementWidth) / 2,
                  borderRightWidth:
                    (funnelFirstElementWidth - funnelSecondElementWidth) / 2,
                }}
              />

              <View
                ref={funnelSecondElementRef}
                style={[
                  styles.funnelSquare,
                  {
                    width: `${Math.round(
                      (formUsageMetrics.form_usage_analytics.form_visits /
                        formUsageMetrics.form_usage_analytics.site_visits) *
                        100,
                    )}%`,
                  },
                ]}
              >
                <NormalText
                  text={formUsageMetrics.form_usage_analytics.form_visits.toString()}
                />
                <NormalText
                  text={`${Math.round(
                    (formUsageMetrics.form_usage_analytics.form_visits /
                      formUsageMetrics.form_usage_analytics.site_visits) *
                      100,
                  )}%`}
                />
              </View>

              <View
                style={{
                  width: funnelSecondElementWidth,
                  borderTopWidth: 25,
                  borderTopColor: "rgba(0, 183, 244, 0.5)",
                  borderLeftColor: "transparent",
                  borderRightColor: "transparent",
                  borderLeftWidth:
                    (funnelSecondElementWidth - funnelThirdElementWidth) / 2,
                  borderRightWidth:
                    (funnelSecondElementWidth - funnelThirdElementWidth) / 2,
                }}
              />

              <View
                ref={funnelThirdElementRef}
                style={[
                  styles.funnelSquare,
                  {
                    width: `${
                      (formUsageMetrics.form_usage_analytics.form_submissions /
                        formUsageMetrics.form_usage_analytics.site_visits) *
                      100
                    }%`,
                  },
                ]}
              >
                <NormalText
                  text={formUsageMetrics.form_usage_analytics.form_submissions.toString()}
                />
                <NormalText
                  text={`${Math.round(
                    (formUsageMetrics.form_usage_analytics.form_submissions /
                      formUsageMetrics.form_usage_analytics.site_visits) *
                      100,
                  )}%`}
                />
              </View>
            </View>

            <View
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              }}
            >
              <NormalText text="Site Visits" style={{ marginTop: 15 }} />
              <NormalText text="Form Visits" />
              <NormalText text="New Leads" style={{ marginBottom: 15 }} />
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  analyticsElementContainer: {
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#DDDEE0",
    padding: 20,
  },
  funnelSquare: {
    backgroundColor: "#00B7F4",
    gap: 5,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
  },
});

export default FunnelChart;
