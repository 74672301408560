import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import React from "react";
import { ScrollView } from "react-native";
import { TPlan } from "../../../../utils/types";
import { SessionContext } from "../../../../contexts";
import { config } from "../../../../config";

const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY ?? "";
const stripePromise = loadStripe(stripePublishableKey);
const protocol = config.coreApi.protocol;
const host = config.coreApi.host;
const urlPrefix = `${protocol}://${host}/clinics/v1`;

type TStripCheckoutFormProps = {
  plan: TPlan;
};

const StripCheckoutForm: React.FC<TStripCheckoutFormProps> = ({
  plan,
}: {
  plan: TPlan;
}) => {
  const session = React.useContext(SessionContext)?.session;
  if (!session) return null;

  const fetchClientSecret = React.useCallback(async () => {
    try {
      const response = await fetch(
        `${urlPrefix}/billing/${session?.clinicId}/create-stripe-checkout-session`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session?.accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ plan }),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!data.client_secret) {
        throw new Error("Client secret not found in the response");
      }

      return data.client_secret;
    } catch (error) {
      console.error("Error fetching client secret:", error);
      throw error;
    }
  }, [session, plan]);

  const options = { fetchClientSecret };

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </ScrollView>
  );
};

export default StripCheckoutForm;
