import React from "react";
import Upload from "../../../../assets/tryai-icons/Upload";

interface UploadImageButtonProps {
  saveCurrentImage: (image: Blob) => void;
}

const UploadImageButton: React.FC<UploadImageButtonProps> = ({
  saveCurrentImage,
}) => {
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      saveCurrentImage(selectedFile);
    } else {
      alert("Please select a valid image file.");
      event.target.value = "";
    }
  };

  return (
    <div id="upload-image__btn">
      <input
        id="upload-image__input"
        type="file"
        accept="image/*"
        multiple={false}
        onChange={handleFileChange}
      />
      <span id="upload-image__span">Upload a photo</span>
      <div id="upload-image__upload-icon">
        <Upload />
      </div>
    </div>
  );
};

export default UploadImageButton;
