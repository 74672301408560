import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, TouchableHighlight } from "react-native";
import { TReturnGetImagesBatchBatchId } from "../../../services/api";
import { TProcedure } from "../../../utils/types";
import { adjustTextMap } from "../../../utils/constant";
import ImageComparisonSlider from "../web/image-comparison-slider/ImageComparisonSlider";

type DisplayOneSquareCompareProps = {
  currentImageData: TReturnGetImagesBatchBatchId[];
  selectedProcedure: TProcedure | "";
};

const DisplayOneSquareCompare = ({
  currentImageData,
  selectedProcedure,
}: DisplayOneSquareCompareProps) => {
  if (currentImageData && currentImageData.length === 0) return null;

  const [selectedImageData, setSelectedImageData] =
    useState<TReturnGetImagesBatchBatchId | null>(null);
  const [imageData, setImageData] = useState<TReturnGetImagesBatchBatchId[]>(
    [],
  );

  useEffect(() => {
    const filteredData = currentImageData.filter((item) =>
      item.uploadType.includes("TransformImage"),
    );
    if (filteredData.length === 0) return;
    setImageData(filteredData);
    setSelectedImageData(filteredData[0]);
  }, [currentImageData, selectedProcedure]);

  if (!selectedImageData) return null;

  return (
    <View style={styles.container}>
      <ImageComparisonSlider
        adjustText={adjustTextMap[selectedProcedure]}
        imageUrls={selectedImageData.presignedUrls}
        metaData={{
          success: true,
          reason: null,
          implantSize: null,
        }}
      />
      <View style={styles.fiveImagesContainer}>
        {imageData.map((image, index) => (
          <TouchableHighlight
            key={index}
            onPress={() => setSelectedImageData(image)}
            underlayColor="#DDDDDD"
          >
            <Image
              style={[
                styles.image,
                image === selectedImageData && styles.selectedImageStyle,
              ]}
              source={{
                uri:
                  image && image.presignedUrls
                    ? image.presignedUrls[image.presignedUrls.length - 1]
                    : "",
              }}
            />
          </TouchableHighlight>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    gap: 14,
  },
  fiveImagesContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  image: {
    width: 90,
    height: 90,
    borderRadius: 10,
    borderWidth: 5,
    borderColor: "transparent",
  },
  selectedImageStyle: {
    borderColor: "#0000004D",
    // @ts-ignore comment
    filter: "brightness(0.8)",
  },
});

export default DisplayOneSquareCompare;
