import React from "react";
import { Text, TextStyle, StyleSheet } from "react-native";

type TitleTextProps = {
  text: string;
  style?: TextStyle;
  numberOfLines?: number;
  fontWeight?: "600" | "700";
};

const TitleText: React.FC<TitleTextProps> = ({
  text,
  style,
  numberOfLines = 3,
  fontWeight = "600",
}) => {
  return (
    <Text
      style={[
        styles.titleText,
        style,
        fontWeight === "600" && styles.fontWeight600,
        fontWeight === "700" && styles.fontWeight700,
      ]}
      numberOfLines={numberOfLines}
    >
      {text}
    </Text>
  );
};

const styles = StyleSheet.create({
  titleText: {
    fontSize: 24,
    color: "#000",
  },
  fontWeight700: {
    fontFamily: "Urbanist700",
    fontWeight: "700",
  },
  fontWeight600: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
  },
});

export default TitleText;
