import React, { FC, useState } from "react";
import {
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
  Text,
} from "react-native";

interface InputProps extends TextInputProps {
  type?: "default" | "email-address" | "numeric" | "phone-pad";
  name: string;
  textWidth?: number;
  inputWidth?: number;
  required?: boolean;
  placeholder?: string;
  style?: any;
  inputHeight?: number;
}

const InputPatientDetail: FC<InputProps> = ({
  type = "default",
  name,
  placeholder = "",
  value,
  onChangeText,
  style,
  inputHeight = 30,
  textWidth = 120,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState(value || "");

  const handleChangeText = (text: string) => {
    setInputValue(text);
    if (onChangeText) {
      onChangeText(text);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ width: textWidth }}>
        <Text style={styles.name}>{name}</Text>
      </View>
      <TextInput
        style={[
          styles.textInput,
          { height: inputHeight },
          { color: inputValue ? "#282B33" : "#C4C6CD" },
        ]}
        value={inputValue}
        keyboardType={type}
        placeholder={placeholder}
        onChangeText={handleChangeText}
        multiline={inputHeight > 30 ? true : false}
        maxLength={500}
        {...otherProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  },
  name: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    color: "#282B33",
  },
  textInput: {
    width: "100%",
    maxWidth: 260,
    fontFamily: "Urbanist500",
    fontWeight: "500",
    borderRadius: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    paddingHorizontal: 12,
    fontSize: 16,
    color: "#000000",
    backgroundColor: "#fff",
  },
});

export default InputPatientDetail;
