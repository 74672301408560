import React, { useContext, useState, useEffect } from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { SessionContext } from "../../../../contexts";
import { VictoryPie } from "victory-native";
import { NormalText } from "../../../common/native";
import { TGetFormUsageMetricsV2Response } from "../../../../services/api-analytics";

type PieChartProps = {
  formUsageMetrics: TGetFormUsageMetricsV2Response;
};

type Category = "facebook" | "instagram" | "google" | "direct" | "other";

const CATEGORIES: Category[] = [
  "facebook",
  "instagram",
  "google",
  "direct",
  "other",
];

const COLOR_SCALE = {
  facebook: "#1E8CB1",
  instagram: "#024767",
  google: "#4CBF8B",
  direct: "#76D7C4",
  other: "#FFFACD",
  // This is for another color #009B59
};

const PieChart: React.FC<PieChartProps> = ({ formUsageMetrics }) => {
  const session = useContext(SessionContext);
  if (!session) return null;

  const [percentages, setPercentages] = useState<Record<Category, number>>(
    Object.fromEntries(CATEGORIES.map((cat) => [cat, 0])) as Record<
      Category,
      number
    >,
  );

  useEffect(() => {
    const totalReferrers =
      formUsageMetrics.referrer_count_analytics.total_referrers;

    const newPercentages = Object.fromEntries(
      CATEGORIES.map((cat) => [
        cat,
        (formUsageMetrics.referrer_count_analytics.referrers_counts[cat] /
          totalReferrers) *
          100,
      ]),
    ) as Record<Category, number>;

    setPercentages(newPercentages);
  }, [formUsageMetrics]);

  const sortedCategories = CATEGORIES.sort(
    (a, b) => percentages[b] - percentages[a],
  );

  const sortedColorScale = sortedCategories.map((cat) => COLOR_SCALE[cat]);

  const PieChartLegendItem = ({ category }: { category: Category }) => {
    if (percentages[category] === 0) return null;

    return (
      <View style={styles.legendItem}>
        <View
          style={[
            styles.legendColor,
            { backgroundColor: COLOR_SCALE[category] },
          ]}
        />
        <NormalText
          text={`${category.charAt(0).toUpperCase() + category.slice(1)} ${percentages[category].toFixed(2)}%`}
        />
      </View>
    );
  };

  return (
    <View style={styles.leadsPerSocialChannel}>
      <NormalText
        text={"Visits Per Channel"}
        style={{ marginBottom: 20 }}
        fontWeight="600"
      />
      {formUsageMetrics.form_usage_analytics.site_visits === 0 ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            gap: 10,
          }}
        >
          <NormalText
            text="This form has not been viewed yet"
            fontWeight="600"
          />
          <NormalText text="No data to show" />
        </View>
      ) : (
        <View style={styles.chartContainer}>
          <View style={{ flex: 1, maxWidth: 230 }}>
            <VictoryPie
              colorScale={sortedColorScale}
              style={{
                data: { stroke: "#fff", strokeWidth: 5 },
                labels: { fill: "transparent" },
              }}
              data={sortedCategories.map((cat, index) => ({
                x: index + 1,
                y: percentages[cat],
              }))}
            />
          </View>
          <View style={styles.legendContainer}>
            {sortedCategories.map((category) => (
              <PieChartLegendItem key={category} category={category} />
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  leadsPerSocialChannel: {
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#DDDEE0",
    padding: 20,
    minWidth: 350,
  },
  chartContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
  legendContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 20,
  },
  legendItem: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  legendColor: {
    width: 10,
    height: 10,
    borderRadius: 50,
  },
});

export default PieChart;
