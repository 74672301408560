import React, { useEffect, useState } from "react";
import {
  View,
  Image,
  StyleSheet,
  TouchableHighlight,
  useWindowDimensions,
} from "react-native";
import { TReturnGetImagesBatchBatchId } from "../../../services/api";

type DisplayImageOneSquareProps = {
  currentImageData: TReturnGetImagesBatchBatchId[];
  isAIImages: boolean;
};

const DisplayOneSquare = ({
  currentImageData,
  isAIImages,
}: DisplayImageOneSquareProps) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 644;

  const [selectedImageData, setSelectedImageData] =
    useState<TReturnGetImagesBatchBatchId | null>(null);
  const [imageData, setImageData] = useState<TReturnGetImagesBatchBatchId[]>(
    [],
  );

  useEffect(() => {
    const filteredData = currentImageData.filter((item) =>
      item.uploadType.includes(isAIImages ? "Transform" : "Patient"),
    );
    if (filteredData.length === 0) return;
    setImageData(filteredData);
    setSelectedImageData(filteredData[0]);
  }, [currentImageData, isAIImages]);

  if (imageData && imageData.length === 0) return null;

  return (
    <View style={styles.container}>
      <View style={styles.twoImagesContainer}>
        <Image
          style={styles.selectedImage}
          source={{
            uri:
              selectedImageData && selectedImageData.presignedUrls.length !== 0
                ? selectedImageData.presignedUrls[
                    selectedImageData.presignedUrls.length - 1
                  ]
                : "",
          }}
        />
      </View>
      <View style={styles.fiveImagesContainer}>
        {imageData.map((image, index) => (
          <TouchableHighlight
            key={index}
            onPress={() => setSelectedImageData(image)}
            underlayColor="#DDDDDD"
            style={{
              maxWidth: 78,
              maxHeight: 78,
              ...(isMobile ? { flex: 1 } : { width: 90, height: 90 }),
              aspectRatio: 1,
            }}
          >
            <Image
              style={[
                styles.image,
                image === selectedImageData && styles.selectedImageStyle,
              ]}
              source={{
                uri:
                  image && image.presignedUrls.length !== 0
                    ? image.presignedUrls[image.presignedUrls.length - 1]
                    : "",
              }}
            />
          </TouchableHighlight>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    gap: 14,
  },
  twoImagesContainer: {
    flexDirection: "row",
    justifyContent: "center",
    gap: 24,
  },
  fiveImagesContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  selectedImage: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: 5,
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    borderWidth: 5,
    borderColor: "transparent",
  },
  selectedImageStyle: {
    borderColor: "#0000004D",
    // @ts-ignore comment
    filter: "brightness(0.8)",
  },
});

export default DisplayOneSquare;
