import React from "react";
import { Text, Pressable, StyleSheet, ActivityIndicator } from "react-native";

interface SecondaryButtonProps {
  text: string;
  onPress: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  roundBorderRadius?: boolean;
  width?: number;
  icon?: React.ReactNode;
  loading?: boolean;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  text,
  onPress,
  icon = null,
  disabled = false,
  fullWidth = false,
  roundBorderRadius = false,
  width = -1,
  loading = false,
}) => {
  if (loading) {
    return (
      <Pressable
        style={[
          styles.button,
          fullWidth && { width: "100%" },
          roundBorderRadius && { borderRadius: 80 },
          width !== -1 && { width: width },
          text === "" && { width: 36 },
          { height: text ? 46 : 36 },
          { paddingVertical: text ? 12 : 3 },
          { paddingHorizontal: text ? 24 : 3 },
        ]}
        onPress={onPress}
        disabled
      >
        <ActivityIndicator size="small" color="#000" />
      </Pressable>
    );
  }

  return (
    <Pressable
      style={[
        styles.button,
        disabled && styles.disabledButton,
        fullWidth && { width: "100%" },
        width !== -1 && { width: width },
        roundBorderRadius && { borderRadius: 80 },
        text === "" && { width: 36 },
        { height: text ? 46 : 36 },
        { paddingVertical: text ? 12 : 3 },
        { paddingHorizontal: text ? 24 : 3 },
      ]}
      onPress={onPress}
      disabled={disabled}
    >
      <>
        {icon ? icon : null}
        {text && <Text style={styles.text}>{text}</Text>}
      </>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: "#fff",
    paddingVertical: 12,
    paddingRight: 24,
    borderRadius: 10,
    height: 46,
    borderColor: "#282B33",
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
  },
  text: {
    fontFamily: "Urbanist500",
    textAlign: "center",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    letterSpacing: 0.032,
    fontStyle: "normal",
    color: "#282B33",
    userSelect: "none",
  },
  disabledButton: {
    backgroundColor: "#C4C6CD",
  },
  disabledText: {
    color: "#fff",
  },
});

export default SecondaryButton;
