import React, { useState } from "react";

import ReactRange from "./ReactRange";
import ImageSlider from "./ImageSlider";
import { ActivityIndicator } from "react-native";

type ImageComparisonSliderProps = {
  imageUrls: string[];
  metaData: any;
  adjustText?: string;
  handleTurnOnOffScrollingStep3?: (bool: boolean) => void;
};

const ImageComparisonSlider: React.FC<ImageComparisonSliderProps> = ({
  imageUrls,
  metaData,
  adjustText = "Adjust the output",
  handleTurnOnOffScrollingStep3 = () => {},
}: ImageComparisonSliderProps): JSX.Element => {
  const [value, setValue] = useState<number>(5);

  const handleRangeChange = (value: number) => {
    setValue(value);
  };

  if (metaData && metaData.success === false)
    return <p style={{ fontFamily: "Urbanist400" }}>{metaData.reason}</p>;

  if (imageUrls.length === 0)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ActivityIndicator size="large" color="#00B7F4" />
      </div>
    );

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ImageSlider
        value={value}
        afterImageUrls={imageUrls}
        handleTurnOnOffScrollingStep3={handleTurnOnOffScrollingStep3}
      />
      {adjustText ? (
        <>
          <p
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontFamily: "Urbanist600",
              marginTop: 16,
            }}
          >
            {adjustText}
          </p>
          <div
            style={{
              alignSelf: "center",
              width: "90%",
            }}
          >
            <ReactRange
              value={value}
              min={0}
              max={imageUrls.length - 1}
              implantSize={metaData.implant_size_in_cc}
              onChange={handleRangeChange}
              handleTurnOnOffScrollingStep3={handleTurnOnOffScrollingStep3}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{ fontSize: "16px", fontFamily: "Urbanist600", margin: 0 }}
            >
              Less
            </p>
            <p
              style={{ fontSize: "16px", fontFamily: "Urbanist600", margin: 0 }}
            >
              More
            </p>
          </div>
        </>
      ) : null}
    </div>
  ); //
};

export default ImageComparisonSlider;
