import React, { useContext, useEffect, useState } from "react";
import { View, StyleSheet, Text, ScrollView } from "react-native";
import {
  NormalText,
  PrimaryButton,
  SecondaryButton,
  TitleText,
} from "../../../common/native";

import { SessionContext } from "../../../../contexts";
import { Stars } from "../../../../assets";
import { ModalOpener } from "../../../uncommon/native/modals";
import { TPlan } from "../../../../utils/types";
import { patchUpdatePlan } from "../../../../services/api";
import { mixpanel } from "../../../../utils/mixpanel";
import StripCheckoutForm from "./StripeCheckoutForm";

type UpgradeProps = {};

const Upgrade: React.FC<UpgradeProps> = ({}) => {
  const session = useContext(SessionContext)?.session;

  const [entryButtonLoading, setEntryButtonLoading] = useState<boolean>(false);
  const [coreButtonLoading, setCoreButtonLoading] = useState<boolean>(false);
  const [premiumButtonLoading, setPremiumButtonLoading] =
    useState<boolean>(false);

  const [isPlanUpdated, setIsPlanUpdated] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [entryPlanButtonText, setEntryPlanButtonText] =
    useState<string>("Upgrade");
  const [corePlanButtonText, setCorePlanButtonText] =
    useState<string>("Upgrade");
  const [customPlanButtonText, setCustomPlanButtonText] =
    useState<string>("Upgrade");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [planToUpgrade, setPlanToUpgrade] = useState<TPlan | null>(null);

  useEffect(() => {
    if (!session) console.error("Session not found");

    setEntryPlanButtonText(
      session?.planType === "FREE"
        ? "Upgrade"
        : session?.planType === "ENTRY"
          ? "Current Plan"
          : "Downgrade",
    );

    setCorePlanButtonText(
      session?.planType === "FREE" || session?.planType === "ENTRY"
        ? "Upgrade"
        : session?.planType === "CORE"
          ? "Current Plan"
          : "Downgrade",
    );

    setCustomPlanButtonText(
      session?.planType === "FREE" ||
        session?.planType === "ENTRY" ||
        session?.planType === "CORE"
        ? "Contact Us"
        : "Current Plan",
    );
  }, []);

  const [plan, setPlan] = useState<TPlan | null>(null);

  if (plan) {
    return <StripCheckoutForm plan={plan} />;
  }

  const handleOnPressButton = async (
    planButton: TPlan,
    planButtonCurrentText: string,
  ) => {
    if (!session) return;
    // Don't allow to upgrade to the same plan
    if (planButtonCurrentText === "Current Plan") return;

    // Show payment form if the user is on the free plan
    mixpanel.track("Package Selected", {
      "User Email": session.userEmail,
      "Clinic Name": session.clinicName,
      Package: planButton,
    });
    if (session?.planType === "FREE") {
      setPlan(planButton);
      return;
    }

    // Show modal to confirm downgrade
    if (planButtonCurrentText === "Downgrade") {
      setPlanToUpgrade(planButton);
      setIsModalOpen(true);
    }

    // Upgrade to a plan
    if (planButtonCurrentText === "Upgrade") {
      if (planButton === "ENTRY") setEntryButtonLoading(true);
      if (planButton === "CORE") setCoreButtonLoading(true);
      if (planButton === "PREMIUM") setPremiumButtonLoading(true);
      try {
        if (!session) throw new Error("Session not found");
        await patchUpdatePlan(session, planButton);
        setIsPlanUpdated(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (error) {
        setIsError(true);
      }
      if (planButton === "ENTRY") setEntryButtonLoading(false);
      if (planButton === "CORE") setCoreButtonLoading(false);
      if (planButton === "PREMIUM") setPremiumButtonLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPlanToUpgrade(null);
  };

  const renderCardItems = (texts: string[]) => {
    return texts.map((text, index) => (
      <NormalText key={index} text={text} style={{ marginBottom: 16 }} />
    ));
  };

  if (!session) return null;

  if (isError)
    return (
      <View style={styles.messageContainer}>
        <NormalText text={"Something went wrong, please try again later"} />
      </View>
    );

  if (isPlanUpdated)
    return (
      <View style={styles.messageContainer}>
        <NormalText text={"Plan updated! Refresh in 3 second!"} />
      </View>
    );

  return (
    <View style={styles.upgradeContainer}>
      <ModalOpener
        visible={isModalOpen}
        modalType={"ConfirmUpdatePlan"}
        onClose={closeModal}
        planToUpgrade={planToUpgrade}
      />
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={{ width: "100%" }}
      >
        <TitleText
          text={"Upgrade"}
          style={{ marginTop: 5, marginBottom: 20 }}
        />
        <View style={styles.cardsContainer}>
          <View style={styles.cardContainer}>
            <TitleText text={"Entry"} fontWeight="700" />
            <NormalText text={"$49 / month"} style={{ marginBottom: 36 }} />
            {renderCardItems([
              "10 free simulations per month",
              "$0.50 per simulation",
              "Patient Photo Capture Widget",
              "Progress Photo Generator",
              "24/7 Customer Support",
              "Dedicated Account Manager",
            ])}
            <View style={{ marginTop: "auto" }} />
            <SecondaryButton
              text={entryPlanButtonText}
              loading={entryButtonLoading}
              onPress={() => handleOnPressButton("ENTRY", entryPlanButtonText)}
              roundBorderRadius
            />
          </View>

          <View style={{ width: "100%", maxWidth: 347 }}>
            <View style={styles.mostPopularContainer}>
              <TitleText
                text={"Most Popular"}
                style={{ color: "#fff" }}
                fontWeight="700"
              />
            </View>
            <View
              style={[
                styles.cardContainer,
                { borderTopStartRadius: 0, borderTopEndRadius: 0 },
              ]}
            >
              <TitleText text={"Core"} fontWeight="700" />
              <Text
                style={{
                  fontFamily: "Urbanist500",
                  fontWeight: "500",
                  color: "#000",
                  fontSize: 16,
                  marginRight: 10,
                  marginBottom: 36,
                }}
              >
                <Text
                  style={{
                    textDecorationLine: "line-through",
                  }}
                >
                  $199
                </Text>{" "}
                <Text style={{ color: "#00B7F4" }}>$99</Text> / month
              </Text>
              {renderCardItems([
                "Everything in Entry, plus...",
                "100 free simulations per month",
                "AI Post-op Simulation Widget",
                "SEO Optimization",
              ])}
              <View style={{ marginTop: "auto" }} />
              <PrimaryButton
                text={corePlanButtonText}
                loading={coreButtonLoading}
                onPress={() => handleOnPressButton("CORE", corePlanButtonText)}
                roundBorderRadius
              />
            </View>
          </View>

          <View style={styles.cardContainer}>
            <TitleText text={"Premium"} fontWeight="700" />
            <View style={{ flexDirection: "row" }}>
              <Stars />
              <NormalText text={"Custom"} style={{ marginBottom: 36 }} />
            </View>
            {renderCardItems([
              "Everything in Core, plus...",
              "1k free simulations per month",
              "Custom AI for your practice",
              "Custom CRM/EHR Integration",
              "Custom Website Integration",
            ])}
            <View style={{ marginTop: "auto" }} />
            <SecondaryButton
              text={customPlanButtonText}
              loading={premiumButtonLoading}
              onPress={() => {
                mixpanel.track("Package Selected", {
                  "User Email": session.userEmail,
                  "Clinic Name": session.clinicName,
                  Package: "PREMIUM",
                });
                window.open(
                  "https://calendly.com/supercharge-your-clinic/15-min-convo",
                  "_blank",
                );
              }}
              roundBorderRadius
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  upgradeContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  cardsContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-end",
    gap: 20,
    paddingBottom: 20,
  },
  cardContainer: {
    width: "100%",
    maxWidth: 347,
    height: 469,
    padding: 36,
    borderRadius: 20,
    backgroundColor: "#fff",
    borderColor: "rgba(233, 233, 233, 0.60)",
    borderWidth: 1,
    shadowColor: "#00B7F4",
    shadowOffset: { width: 10, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 5,
  },
  mostPopularContainer: {
    width: "100%",
    maxWidth: 347,
    backgroundColor: "#00B7F4",
    paddingVertical: 5,
    justifyContent: "center",
    alignItems: "center",
    borderStartStartRadius: 20,
    borderStartEndRadius: 20,
  },
  messageContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Upgrade;
