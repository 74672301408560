import React from "react";
import { View } from "react-native";

interface DownloadProps {
  color?: string;
}

const Download: React.FC<DownloadProps> = ({ color = "#fff" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="download">
        <path
          id="Vector"
          d="M11.75 2C11.152 2 10.6667 2.48501 10.6667 3.08333V14.5255L7.41667 11.3102L5.89349 12.8333L10.9711 17.9456C11.3947 18.3681 12.1053 18.3681 12.5289 17.9456L17.6065 12.8333L16.0833 11.3102L12.8333 14.5255V3.08333C12.8333 2.48501 12.348 1.99989 11.75 2ZM3.08333 15C2.48533 15 2 15.4853 2 16.0833V18.25C2 20.6431 3.94025 22.5833 6.33333 22.5833H17.1667C19.5598 22.5833 21.5 20.6431 21.5 18.25V16.0833C21.5 15.4853 21.0147 15 20.4167 15C19.8187 15 19.3333 15.4853 19.3333 16.0833V18.25C19.3333 19.4471 18.3638 20.4167 17.1667 20.4167H6.33333C5.13625 20.4167 4.16667 19.4471 4.16667 18.25V16.0833C4.16667 15.4853 3.68133 15 3.08333 15Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Download;
