import { config } from "../config";
import { TSession } from "../utils/types";

const protocol = config.coreApi.protocol;
const host = config.coreApi.host;
const urlPrefix = `${protocol}://${host}/clinics/v1/clinic`;

export type TGetSimulationResponse = {
  simulations: {
    id: number;
    clinic_id: number;
  }[];
};
export const getSimulations = async (
  session: TSession,
  start_date: Date,
  end_date: Date,
): Promise<TGetSimulationResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const formattedStartDate =
      start_date.toISOString().split("T")[0] + "T00:00:00.000000";
    const formattedEndDate =
      end_date.toISOString().split("T")[0] + "T23:59:59.000000";
    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/simulations?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting user ID:", error);
    throw error;
  }
};

export type TGetClinicInfoResponse = {
  clinic_name: string;
  logo_url: string;
  surgeons: {
    name: string;
    photo_url: string;
    address: string;
  }[];
  procedures: any[];
};
export const getClinicInfo = async (
  accessToken: string,
  clinicId: string,
): Promise<TGetClinicInfoResponse> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(`${urlPrefix}/${clinicId}/info`, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    const result: TGetClinicInfoResponse = {
      clinic_name: data.clinic_name,
      logo_url: data.logo_url,
      surgeons: data.surgeons.map((surgeon: any) => ({
        name: surgeon.name,
        photo_url: surgeon.photo_url,
        address: surgeon.address,
      })),
      procedures: data.procedures,
    };
    return result;
  } catch (error) {
    console.error("Error getting clinic info", error);
    throw error;
  }
};

export type TPostClinicPhotoRequest = {
  imageBlob: Blob;
};
export type TPostClinicPhotoResponse = {
  photo_url: string;
};
export const postClinicPhoto = async (
  session: TSession,
  body: TPostClinicPhotoRequest,
): Promise<TPostClinicPhotoResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const clinicPhotoRes = await fetch(
      `${urlPrefix}/${session.clinicId}/clinic-logo`,
      {
        method: "POST",
        headers,
      },
    );

    if (!clinicPhotoRes.ok) {
      throw new Error(`HTTP error ${clinicPhotoRes.status}`);
    }

    const clinicPhotoJson = await clinicPhotoRes.json();

    const putRes = await fetch(clinicPhotoJson.upload_url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/png",
      },
      body: body.imageBlob,
    });
    if (!putRes.ok) {
      const text = await putRes.text();
      throw new Error(`HTTP Error: ${putRes.status} - ${text}`);
    }

    const result: TPostClinicPhotoResponse = {
      photo_url: `https://www.clinic-logo-assets.clinic-os.com/${clinicPhotoJson.file_name}`,
    };
    return result;
  } catch (error) {
    console.error("Error posting clinic photo", error);
    throw error;
  }
};

export type TPostClinicProfileRequest = {
  surgeon_info: {
    name: string;
    clinic_address: string;
    photo_url: string;
  };
  emails: string[];
};
export type TPostClinicProfileResponse = {
  surgeon_info: {
    name: string;
    clinic_address: string;
    photo_url: string;
  };
  emails: string[];
};
export const postClinicProfile = async (
  session: TSession,
  body: TPostClinicProfileRequest,
): Promise<TPostClinicProfileResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(`${urlPrefix}/${session.clinicId}/profile`, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    const result: TPostClinicProfileResponse = {
      surgeon_info: {
        name: data.surgeon_info.name,
        clinic_address: data.surgeon_info.clinic_address,
        photo_url: data.surgeon_info.photo_url,
      },
      emails: data.emails,
    };
    return result;
  } catch (error) {
    console.error("Error getting clinic info", error);
    throw error;
  }
};

export type TPatientInfo = {
  areasOfInterest: string[];
  createdAt: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  proceduresOfInterest: string[];
  sex: string;
  updatedAt: string;
  uuid: string;
};

export type TGetPatientsResponse = TPatientInfo[];

export const getPatients = async (
  session: TSession,
): Promise<TGetPatientsResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await fetch(`${urlPrefix}/${session.clinicId}/patients`, {
      method: "GET",
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    const data = await response.json();
    const result: TGetPatientsResponse = data.patients.map((item: any) => ({
      areasOfInterest: item.areas_of_interest || [],
      createdAt: item.created_at || "",
      dateOfBirth: item.dob || "",
      email: item.email || "",
      firstName: item.first_name || "",
      lastName: item.last_name || "",
      phone: item.phone || "+1",
      proceduresOfInterest: item.procedures_of_interest || [],
      sex: item.sex || "",
      updatedAt: item.updated_at || "",
      uuid: item.uuid || "",
    }));
    return result;
  } catch (error) {
    console.error("Error getting patients", error);
    throw error;
  }
};

export type TPostPatientsResponse = TPatientInfo;
export const postPatients = async (
  session: TSession,
  patientData: any,
): Promise<TPostPatientsResponse> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await fetch(`${urlPrefix}/${session.clinicId}/patients`, {
      method: "POST",
      headers,
      body: JSON.stringify(patientData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    const result: TPostPatientsResponse = {
      areasOfInterest: data.areas_of_interest || [],
      createdAt: data.created_at || "",
      dateOfBirth: data.dob || "",
      email: data.email || "",
      firstName: data.first_name || "",
      lastName: data.last_name || "",
      phone: data.phone || "+1",
      proceduresOfInterest: data.procedures_of_interest || [],
      sex: data.sex || "",
      updatedAt: data.updated_at || "",
      uuid: data.uuid || "",
    };
    return result;
  } catch (error) {
    console.error("Error adding a patient", error);
    throw error;
  }
};
