import { v4 as uuidv4 } from "uuid";

import { config } from "../config";
import {
  TSession,
  TUserInfo,
  TProcedure,
  TView,
  TTransformedItem,
  TSimulationRow,
  TUpload,
  TRole,
  TPlan,
} from "../utils/types";
import { procedureIconMap } from "../utils/constant";

const protocol = config.coreApi.protocol;
const host = config.coreApi.host;
const urlPrefix = `${protocol}://${host}/clinics/v1/clinic`;

export const getAdminToken = async () => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(`${protocol}://${host}/api/v1/users/login`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        email: process.env.CLINICOS_API_ADMIN_USERNAME,
        password: process.env.CLINICOS_API_ADMIN_PASSWORD,
      }),
    });

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();
    return j;
  } catch (error) {
    throw error;
  }
};

export type TReturnGetImagesBatchBatchId = {
  procedureType: TProcedure[];
  viewType: TView | null;
  presignedUrls: string[];
  uploadType: TUpload;
};

export const getMgmtImagesBatchBatchId = async (
  shareId: string,
  pin: string,
): Promise<TReturnGetImagesBatchBatchId[]> => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/shared/${shareId}/batch/images?pin=${pin}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    const result = data.images.map((item: any) => {
      const procedureTypeViewTypePresignedUrls: TReturnGetImagesBatchBatchId = {
        procedureType: item.image_data.procedure_types,
        viewType: item.image_data.view_type,
        uploadType: item.image_data.upload_type,
        presignedUrls: item.presigned_urls,
      };
      return procedureTypeViewTypePresignedUrls;
    });
    return result;
  } catch (error) {
    throw error;
  }
};

export const createAClinic = async (
  adminToken: string,
  display_name: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${adminToken}`,
      "Content-Type": "application/json",
    };
    const uuid = uuidv4();
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/mgmt/clinics`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          display_name: display_name,
          sdk_keys: [uuid],
        }),
      },
    );

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();
    return j;
  } catch (error) {
    console.error("Error in batchTransform:", error);
    throw error;
  }
};

export const getClinicSdkKey = async (adminToken: string, clinicId: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${adminToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/mgmt/clinics/${clinicId}/sdk-keys`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error getting Clinic Sdk Key", error);
    throw error;
  }
};

export const addUserToClinic = async (
  adminToken: string,
  clinicId: string,
  userId: string,
  role: TRole,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${adminToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/mgmt/clinics/${clinicId}/users`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          user_uuid: userId,
          role: role,
        }),
      },
    );

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();
    return j;
  } catch (error) {
    console.error("Error in batchTransform:", error);
    throw error;
  }
};

export const getUserInfo = async (
  accessToken: string,
  userId: string,
): Promise<TUserInfo | null> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/user/${userId}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();

    const userInfo: TUserInfo = {
      role: data.user_info[0].role,
      clinicId: data.user_info[0].clinic_id,
      clinicName: data.user_info[0].display_name,
      planType: data.user_info[0].plan_type,
    };

    return userInfo;
  } catch (error) {
    console.error("Error getting user info:", error);
    const userInfo: TUserInfo = {
      role: "",
      clinicId: "",
      clinicName: "",
      planType: "",
    };
    return userInfo;
  }
};

export const getSupportedViewTypeAndProcedureType = async (
  session: TSession,
  viewTypes: TView[],
): Promise<
  {
    viewType: TView;
    procedureType: TProcedure;
  }[]
> => {
  try {
    const headers = {
      Authorization: `Bearer ${session.adminToken}`,
      "Content-Type": "application/json",
    };
    const queryParams = new URLSearchParams();
    viewTypes.forEach((viewType) => {
      queryParams.append("view_types", viewType);
    });
    const queryString = queryParams.toString();

    const response = await fetch(
      `${protocol}://${host}/clinics/v1/mgmt/info/supported-procedures-for-view?${queryString}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();
    const result = j.map((item: { procedure_type: any; view_type: string }) => {
      return {
        procedureType: item.procedure_type,
        viewType: item.view_type,
      };
    });
    return result;
  } catch (error) {
    console.error("Error in getting supported procedures for view:", error);
    throw error;
  }
};

export const getSupportedProceduresForView = async (
  session: TSession,
  data: { presignedBeforeUrl: string; viewType: TView }[],
): Promise<
  {
    image_url: string;
    procedure_view: TView;
    procedure_type: TProcedure;
  }[]
> => {
  try {
    const headers = {
      Authorization: `Bearer ${session.adminToken}`,
      "Content-Type": "application/json",
    };

    const viewTypes = data.map((item) => item.viewType);
    const queryParams = new URLSearchParams();
    viewTypes.forEach((viewType) => {
      queryParams.append("view_types", viewType);
    });
    const queryString = queryParams.toString();

    const response = await fetch(
      `${protocol}://${host}/clinics/v1/mgmt/info/supported-procedures-for-view?${queryString}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();

    const result = j.map((obj1: { procedure_type: any; view_type: string }) => {
      const obj2 = data.find(
        (obj: { viewType: string }) => obj.viewType === obj1.view_type,
      );
      return {
        procedure_type: obj1.procedure_type,
        procedure_view: obj1.view_type,
        image_url: obj2?.presignedBeforeUrl,
      };
    });

    result.sort(
      (a: { procedure_view: TView }, b: { procedure_view: TView }) => {
        const viewTypeOrder = {
          TORSO_FRONT: 1,
          TORSO_LEFT_45: 2,
          TORSO_LEFT: 3,
          TORSO_RIGHT_45: 4,
          TORSO_RIGHT: 5,
          BODY_FRONT: 7,
          BODY_BACK: 8,
          BODY_LEFT: 9,
          BODY_RIGHT: 10,
          HEAD_FRONT: 11,
          HEAD_LEFT_45: 12,
          HEAD_LEFT: 13,
          HEAD_RIGHT_45: 14,
          HEAD_RIGHT: 15,
        };

        // @ts-ignore
        const viewTypeA = viewTypeOrder[a.procedure_view] || Infinity;
        // @ts-ignore
        const viewTypeB = viewTypeOrder[b.procedure_view] || Infinity;

        return viewTypeA - viewTypeB;
      },
    );

    return result;
  } catch (error) {
    console.error("Error in batchTransform:", error);
    throw error;
  }
};

export const postImagesBatch = async (
  session: TSession,
  patientId: string,
  viewTypeFileProcedureType: any[],
): Promise<{
  batchId: number;
  viewTypeAndPresignedBeforeUrls: {
    viewType: string;
    presignedBeforeUrl: string;
  }[];
}> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  const bodyContent = {
    images: viewTypeFileProcedureType.map((item) => {
      return {
        view_type: item.viewType,
        procedure_types: item.procedureTypes,
      };
    }),
  };

  try {
    let response = await fetch(
      `${urlPrefix}/${session.clinicId}/patients/${patientId}/images/batch`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(bodyContent),
      },
    );

    if (!response.ok) throw new Error(`HTTP error ${response.status}`);

    let j = await response.json();

    const presigned_before_urls = await Promise.all(
      j.presigned_urls.map(
        async (item: { put_url: string; get_url: string }, index: number) => {
          const response = await fetch(item.put_url, {
            method: "PUT",
            headers: {
              "Content-Type": "image/png",
            },
            body: viewTypeFileProcedureType[index].file,
          });

          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }

          return item.get_url;
        },
      ),
    );
    const viewTypeAndPresignedBeforeUrls = presigned_before_urls.map(
      (item, index) => {
        return {
          viewType: viewTypeFileProcedureType[index].viewType,
          presignedBeforeUrl: item,
        };
      },
    );

    return {
      batchId: j.batch_id,
      viewTypeAndPresignedBeforeUrls: viewTypeAndPresignedBeforeUrls,
    };
  } catch (error) {
    console.error("Error getting user ID:", error);
    throw error;
  }
};

export const postTransformsBatch = async (
  session: TSession,
  patientId: string,
  batchId: string | number,
  imageUrlProcedureTypeProcedureView: {
    image_url: string;
    procedure_view: TView;
    procedure_type: TProcedure;
  }[],
): Promise<TTransformedItem[] | null> => {
  try {
    const headers = {
      Authorization: `Bearer ${session.accessToken}`,
      "Content-Type": "application/json",
    };
    const body = {
      request: imageUrlProcedureTypeProcedureView,
      batch_id: batchId,
    };

    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/patients/${patientId}/transforms/batch`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`HTTP Error: ${response.status} - ${text}`);
    }

    const j = await response.json();
    const transformedData = imageUrlProcedureTypeProcedureView.map(
      (item, index) => ({
        ...item,
        presigned_after_urls: j.response[index].presigned_urls,
        meta_data: j.response[index].metadata_url,
        downloaded_after_urls: [],
        downloaded_meta_data: {
          success: true,
          reason: null,
          implantSize: null,
        },
        procedure_icon: procedureIconMap[item.procedure_type],
      }),
    );

    return transformedData;
  } catch (error) {
    console.error("Error in batchTransform:", error);
    throw error;
  }
};

export const pollForData = async (
  url: string,
  interval = 500,
  maxRetries = 2000,
) => {
  let retryCount = 0;

  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      retryCount++;
      if (retryCount >= maxRetries) {
        throw error;
      }
    }
    return null;
  };

  async function pollForDataRecursive() {
    const data = await fetchData();
    if (data) {
      return {
        success: data.status.success,
        reason: data.status.reason,
        implantSize: data.status.implant_size_in_cc,
      };
    } else {
      await new Promise((resolve) => setTimeout(resolve, interval));
      return pollForDataRecursive();
    }
  }

  return pollForDataRecursive();
};

export const checkUrlAvailability = async (url: string) => {
  while (true) {
    const response = await fetch(url, {
      method: "GET",
    });
    if (response.ok) {
      return url;
    }
    await new Promise((resolve) => setTimeout(resolve, 4000));
  }
};

export const getImagesBatch = async (
  session: TSession,
  patientId: string,
): Promise<TSimulationRow[]> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/patients/${patientId}/images/batch`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();

    const simulationRows = data.batches.map(
      (item: {
        id: any;
        created_at: any;
        procedure_types: any;
        updated_at: any;
        upload_type: any;
      }) => {
        const simulationRow: TSimulationRow = {
          id: item.id,
          created_at: item.created_at,
          procedure_types: item.procedure_types,
          updated_at: item.updated_at,
          upload_type: item.upload_type,
        };
        return simulationRow;
      },
    );
    return simulationRows;
  } catch (error) {
    console.error("Error getting user ID:", error);
    throw error;
  }
};

export const postTransformBatchShare = async (
  session: TSession,
  patientId: string,
  batchId: string,
): Promise<{
  pin: string;
  shareId: string;
}> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  const body = {
    batch_id: batchId,
  };

  try {
    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/patients/${patientId}/transforms/batch/share`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();
    const result = {
      pin: data.pin ?? "",
      shareId: data.share_id ?? "",
    };
    return result;
  } catch (error) {
    console.error("Error getting user ID:", error);
    throw error;
  }
};

export const getImagesBatchBatchId = async (
  session: TSession,
  patientId: string,
  batchId: string,
): Promise<TReturnGetImagesBatchBatchId[]> => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await fetch(
      `${urlPrefix}/${session.clinicId}/patients/${patientId}/images/batch/${batchId}`,
      {
        method: "GET",
        headers,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }

    const data = await response.json();

    const result = data.images.map((item: any) => {
      const procedureTypeViewTypePresignedUrls: TReturnGetImagesBatchBatchId = {
        procedureType: item.image_data.procedure_types,
        viewType: item.image_data.view_type,
        uploadType: item.image_data.upload_type,
        presignedUrls: item.presigned_urls,
      };
      return procedureTypeViewTypePresignedUrls;
    });

    return result;
  } catch (error) {
    console.error("Error getting user ID:", error);
    throw error;
  }
};

export const patchUpdatePlan = async (session: TSession, plan: TPlan) => {
  const headers = {
    Authorization: `Bearer ${session.accessToken}`,
    "Content-Type": "application/json",
  };

  const body = JSON.stringify({ plan: plan });

  try {
    const response = await fetch(
      `${protocol}://${host}/clinics/v1/billing/${session.clinicId}/update-subscription`,
      {
        method: "PATCH",
        headers,
        body,
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating plan:", error);
    throw error;
  }
};

export type TGetClinicSdkKeysResponse = {
  sdk_keys: string[];
};

export const getClinicSdkKeys = async (
  sessionAccessToken: string,
  clinicId: string,
) => {
  try {
    const headers = {
      Authorization: `Bearer ${sessionAccessToken}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(`${urlPrefix}/${clinicId}/sdk-keys`, {
      method: "GET",
      headers,
    });
    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`);
    }
    const data = await response.json();
    if (data.sdk_keys.length === 0) {
      throw new Error("No sdk keys found");
    }

    return data;
  } catch (error) {
    console.error("Error getting clinic sdk keys:", error);
    throw error;
  }
};
