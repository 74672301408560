import React, { useEffect } from "react";
import { useFonts } from "expo-font";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import * as Sentry from "@sentry/react-native";
import {
  CheckoutReturn,
  ForgotPassword,
  Home,
  HomeAnalytics,
  HomeGetStarted,
  HomePatients,
  HomeSimulate,
  HomeUpgrade,
  NotFound,
  ResetPassword,
  SetupPassword,
  ShareSimulation,
  Signin,
  Signup,
} from "./src/components/pages";
import { SessionContext } from "./src/contexts";
import { TSession } from "./src/utils/types";
import SupabaseNavigation from "./src/navigation/SupabaseNavigation";

if (process.env.EXPO_PUBLIC_ENV === "production")
  Sentry.init({
    dsn: "https://7506e5f02920782657c02b4962883778@o4505506489106432.ingest.us.sentry.io/4507393117126656",
    tracesSampleRate: 1.0,
    _experiments: {
      profilesSampleRate: 1.0,
    },
  });

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<SupabaseNavigation />}>
      <Route path="/" element={<Home />}>
        <Route path="analytics" element={<HomeAnalytics />} />
        <Route path="patients" element={<HomePatients />} />
        <Route path="simulate" element={<HomeSimulate />} />
        <Route path="get-started" element={<HomeGetStarted />} />
        <Route path="upgrade" element={<HomeUpgrade />} />
      </Route>
      <Route path="/return" element={<CheckoutReturn />} />
      <Route path="/simulation/:id" element={<ShareSimulation />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/setup-password" element={<SetupPassword />} />
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const App = () => {
  const [session, setSession] = React.useState<TSession | null>(null);

  useFonts({
    Urbanist100: require("./src/assets/fonts/Urbanist-100.ttf"),
    Urbanist200: require("./src/assets/fonts/Urbanist-200.ttf"),
    Urbanist300: require("./src/assets/fonts/Urbanist-300.ttf"),
    Urbanist400: require("./src/assets/fonts/Urbanist-400.ttf"),
    Urbanist500: require("./src/assets/fonts/Urbanist-500.ttf"),
    Urbanist600: require("./src/assets/fonts/Urbanist-600.ttf"),
    Urbanist700: require("./src/assets/fonts/Urbanist-700.ttf"),
    Urbanist800: require("./src/assets/fonts/Urbanist-800.ttf"),
  });

  if (process.env.EXPO_PUBLIC_ENV === "production")
    return (
      <SessionContext.Provider value={{ session, setSession }}>
        <RouterProvider router={router} />
      </SessionContext.Provider>
    );

  return (
    <React.StrictMode>
      <SessionContext.Provider value={{ session, setSession }}>
        <RouterProvider router={router} />
      </SessionContext.Provider>
    </React.StrictMode>
  );
};

export default Sentry.wrap(App);
