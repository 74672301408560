import React from "react";
import { supabase } from "../utils/supabase";
import {
  getAdminToken,
  getClinicSdkKeys,
  getUserInfo,
  TGetClinicSdkKeysResponse,
} from "../services/api";

import { clientDomain } from "../config";
import { getClinicInfo, TGetClinicInfoResponse } from "../services/api-clinic";
import { Outlet, useNavigate } from "react-router-dom";
import { SessionContext } from "../contexts";
import { getFirstDailyAnalytics } from "../services/api-analytics";

const SupabaseNavigation = () => {
  const sessionContext = React.useContext(SessionContext);

  const navigate = useNavigate();
  const isResetPasswordRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    const isShareSimulation = location.href.includes(
      `${clientDomain}simulation/`,
    );
    if (isShareSimulation) return;

    const { data } = supabase.auth.onAuthStateChange(
      async (event: any, session: any) => {
        if (session && session.access_token && session.user.id) {
          try {
            const userInfo = await getUserInfo(
              session.access_token,
              session.user.id,
            );

            const { token } = await getAdminToken();
            let clinicInfo: TGetClinicInfoResponse | null = null;
            let getClinicSdkKeyResponse: TGetClinicSdkKeysResponse | null =
              null;

            if (userInfo?.clinicId) {
              clinicInfo = await getClinicInfo(
                session.access_token,
                userInfo?.clinicId,
              );
              getClinicSdkKeyResponse = await getClinicSdkKeys(
                session.access_token,
                userInfo?.clinicId,
              );
            }

            let deployStatus = false;
            try {
              if (session && userInfo?.clinicId) {
                await getFirstDailyAnalytics(
                  session.access_token,
                  userInfo?.clinicId,
                  "onboarding-tier1",
                );
                deployStatus = true;
              }
            } catch (error: any) {
              deployStatus = false;
            }

            sessionContext?.setSession({
              role: userInfo?.role || "",
              clinicId: userInfo?.clinicId || "",
              clinicName: userInfo?.clinicName || "",
              planType: userInfo?.planType || "",
              accessToken: session.access_token,
              userId: session.user.id,
              userEmail: session.user.email,
              adminToken: token,
              logoUrl: clinicInfo?.logo_url || "",
              deployStatus: deployStatus,
              clinicSdkKeys:
                (getClinicSdkKeyResponse && getClinicSdkKeyResponse.sdk_keys) ||
                [],
              surgeons:
                clinicInfo?.surgeons.map((surgeon) => ({
                  name: surgeon.name || "",
                  photoUrl: surgeon.photo_url || "",
                  address: surgeon.address || "",
                })) || [],
            });
          } catch (error) {}
        }
        if (event === "PASSWORD_RECOVERY") {
          navigate("/reset-password");
          isResetPasswordRef.current = true;
        } else if (event === "SIGNED_OUT") {
        } else if (event === "SIGNED_IN") {
        } else if (event === "INITIAL_SESSION") {
          const getUser = async () => {
            const isCheckout = location.href.includes(
              `${clientDomain}checkout`,
            );
            if (isCheckout) return;
            const isSignup = location.href.includes(`${clientDomain}signup`);
            if (isSignup) return;
            const isSignin = location.href.includes(`${clientDomain}signin`);
            if (isSignin) return;
            const isForgotPassword = location.href.includes(
              `${clientDomain}forgot-password`,
            );
            if (isForgotPassword) return;
            const isSetUpPassword = location.href.includes(
              `${clientDomain}setup-password`,
            );
            if (isSetUpPassword) return;
            if (isResetPasswordRef.current) return;

            const { data } = await supabase.auth.getUser();
            const { user: currentUser } = data;
            if (!currentUser) navigate("/signin");
          };
          await getUser();
        }
      },
    );

    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return <Outlet />;
};

export default SupabaseNavigation;
