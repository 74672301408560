import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";

import { PlusBlack } from "../../../assets/index";

type UploadImageProps = {
  name: string;
  initialValue: Blob | null;
  viewTypeImage: string;
  isAISupported: boolean;
  setImageState: (file: Blob | null, previewUrl: string | null) => void;
  disabled?: boolean;
};

const UploadImage: React.FC<UploadImageProps> = ({
  name,
  initialValue,
  setImageState,
  disabled = false,
  isAISupported,
  viewTypeImage,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 644;

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  useEffect(() => {
    if (initialValue) {
      setImageState(initialValue, URL.createObjectURL(initialValue));
      setPreviewUrl(URL.createObjectURL(initialValue));
    }
  }, [initialValue]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;
    if (!file) return;

    if (!allowedExtensions.exec(file.name)) {
      return;
    }
    setPreviewUrl(URL.createObjectURL(file));
    setImageState(file, URL.createObjectURL(file));
  };

  return (
    <View
      style={[
        styles.container,
        !isMobile && { flex: 1 },
        { width: isMobile ? 90 : "auto" },
      ]}
    >
      <Text
        style={[styles.name, { fontSize: isMobile ? 15 : 16 }]}
        numberOfLines={1}
      >
        {name}
      </Text>
      <View style={[styles.uploadFileContainer]}>
        {previewUrl && (
          <>
            <img
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              src={previewUrl}
              alt="Preview"
            />
            <input
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleFileUpload}
              disabled={disabled}
            />
          </>
        )}
        {!previewUrl && (
          <>
            <View style={styles.plusAndAddPhotoContainer}>
              <PlusBlack />
              <Text
                style={[styles.addPhoto, { fontSize: isMobile ? 12 : 14 }]}
                numberOfLines={1}
              >
                Add Photo
              </Text>
            </View>
            <img
              style={{
                position: "absolute",
                objectFit: "inherit",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "100%",
                opacity: 0.15,
              }}
              src={viewTypeImage}
              alt="Preview"
            />
            <input
              style={{
                position: "absolute",
                zIndex: 1,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor: disabled ? "not-allowed" : "pointer",
              }}
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={handleFileUpload}
              disabled={disabled}
            />
            {isAISupported && <Text style={styles.AIText}>AI*</Text>}
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 174,
    flexDirection: "column",
    gap: 10,
  },
  name: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#282B33",
  },
  AIText: {
    position: "absolute",
    top: 0,
    right: 0,
    marginTop: 5,
    marginRight: 5,
    fontSize: 14,
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#00B7F4",
  },
  addPhoto: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    color: "#000",
  },
  uploadFileContainer: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    overflow: "hidden",
  },
  plusAndAddPhotoContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    left: "50%",
    top: "50%",
    cursor: "pointer",
    // @ts-ignore comment
    transform: [{ translateX: "-50%" }, { translateY: "-50%" }],
  },
});

export default UploadImage;
