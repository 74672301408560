import {
  InteractionManager,
  Pressable,
  TextInput,
  View,
  Text,
  StyleSheet,
  Platform,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";

const CELL_COUNT = 6;
interface CodeInputProps {
  storeUserInputCode: (code: string) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ storeUserInputCode }) => {
  const [code, setCode] = useState<string>("");
  const [containerIsFocused, setContainerIsFocused] = useState(false);

  const codeDigitsArray = new Array(CELL_COUNT).fill(0);
  const ref = useRef(null);

  const toDigitInput = (_value: number, idx: number) => {
    const emptyInputChar = " ";
    const digit = code?.[idx] || emptyInputChar;

    const isCurrentDigit = idx === code?.length;
    const isLastDigit = idx === CELL_COUNT - 1;
    const isCodeFull = code?.length === CELL_COUNT;

    const isFocused = isCurrentDigit || (isLastDigit && isCodeFull);
    const containerStyle =
      containerIsFocused && isFocused
        ? [styles.codeInputCellContainer, styles.inputContainerFocused]
        : styles.codeInputCellContainer;
    return (
      <View key={idx} style={containerStyle}>
        <Text style={styles.text}>{digit}</Text>
      </View>
    );
  };

  const handleOnPress = () => {
    setContainerIsFocused(true);
    // @ts-ignore comment
    ref?.current?.focus();
  };

  const handleOnBlur = () => {
    setContainerIsFocused(false);
  };

  useEffect(() => {
    storeUserInputCode(code);
  }, [code]);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      setContainerIsFocused(true);
      // @ts-ignore comment
      ref?.current?.focus();
    });
  }, []);

  return (
    <View style={styles.container}>
      <Pressable style={styles.inputsContainer} onPress={handleOnPress}>
        {codeDigitsArray.map(toDigitInput)}
      </Pressable>
      <TextInput
        ref={ref}
        value={code}
        onChangeText={setCode}
        onEndEditing={handleOnBlur}
        keyboardType="number-pad"
        returnKeyType="done"
        textContentType="oneTimeCode"
        maxLength={CELL_COUNT}
        style={styles.hiddenCodeInput}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    color: "#000",
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 32,
    fontStyle: "normal",
  },
  container: {
    alignItems: "center",
    width: "100%",
  },
  inputsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputContainerFocused: {
    borderColor: "blue",
  },
  hiddenCodeInput: {
    position: "absolute",
    height: 0,
    width: 0,
    opacity: 0,
  },
  codeInputCellContainer: {
    width: "14%",
    marginHorizontal: "1%",
    height: 80,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#282B33",
    borderWidth: 2,
  },
});
export default CodeInput;
