import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  FlatList,
  StyleSheet,
  Pressable,
  Animated,
} from "react-native";

import { capitalizeString } from "../../../utils/functions";
import { CaretDown, Check } from "../../../assets/index";

type InputPatientDetailDropdownProps = {
  name: string;
  value: string[];
  selections: string[];
  multiple?: boolean;
  allowSearch?: boolean;
  onSelect?: ((value: string[]) => void) | null;
  textWidth?: number;
};

const InputPatientDetailDropdown: FC<InputPatientDetailDropdownProps> = ({
  name,
  value,
  selections,
  multiple = false,
  allowSearch = false,
  onSelect = null,
  textWidth = 120,
}) => {
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState<string[]>(selections);
  const [selectedData, setSelectedData] = useState<string[]>(value);
  const searchRef = useRef<TextInput>(null);
  const [rotateAnim] = useState(new Animated.Value(0));
  const [clickedOutside, setClickedOutside] = useState(false);
  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    setData(selections);
    setSelectedData(value);
  }, [selections]);

  const toggleCaret = () => {
    setClicked(!clicked);
    const rotateTo = clicked ? 0 : 1;
    Animated.timing(rotateAnim, {
      toValue: rotateTo,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const onSearch = (searchValue: string) => {
    if (searchValue.length < search.length) {
      let tempSelections = selections;
      tempSelections = tempSelections.filter((item) => {
        return item.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });
      setData(tempSelections);
    } else if (searchValue !== "") {
      let tempSelections = data.filter((item) => {
        return item.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });
      setData(tempSelections);
    } else {
      setData(selections);
    }
  };

  const handleWindowClick = useCallback(
    (event: { target: any }) => {
      if (
        dropdownContainerRef.current &&
        // @ts-ignore
        !dropdownContainerRef.current.contains(event.target)
      ) {
        setClickedOutside(true);
      }
    },
    [dropdownContainerRef],
  );

  useEffect(() => {
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [handleWindowClick]);

  useEffect(() => {
    if (clickedOutside) {
      toggleCaret();
      setClickedOutside(false);
    }
  }, [clickedOutside]);

  return (
    <View style={styles.container}>
      <View style={{ width: textWidth }}>
        <Text style={styles.name}>{name}</Text>
      </View>
      <View style={{ position: "relative", flex: 1 }}>
        <Pressable
          style={[styles.placeHolder, clicked && styles.placeHolderClicked]}
          onPress={toggleCaret}
        >
          <Text style={styles.placeHolderText}>
            {selectedData.length === 0
              ? "None"
              : selectedData.map((item) => capitalizeString(item) + "\n")}
          </Text>
          <Animated.View
            style={{
              transform: [
                {
                  rotate: rotateAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["0deg", "180deg"],
                  }),
                },
              ],
            }}
          >
            <CaretDown />
          </Animated.View>
        </Pressable>
        {clicked && (
          <Animated.View
            ref={dropdownContainerRef}
            style={[styles.dropdownContainer, { opacity: rotateAnim }]}
          >
            {allowSearch && (
              <View style={{ marginBottom: 4 }}>
                <TextInput
                  placeholder="Search ..."
                  value={search}
                  ref={searchRef}
                  onChangeText={(txt) => {
                    setSearch(txt);
                    onSearch(txt);
                  }}
                  style={styles.placeHolderText}
                />
              </View>
            )}
            <FlatList
              data={data}
              renderItem={({ item, index }) => {
                if (selectedData.includes(item))
                  return (
                    <Pressable
                      key={index}
                      style={[styles.selectedDataItem]}
                      onPress={() => {
                        let temp;
                        if (multiple) {
                          temp = selectedData.filter((data) => data !== item);
                        } else {
                          temp = [item];
                        }
                        setSelectedData(temp);

                        if (onSelect) onSelect(temp);
                        if (!multiple) toggleCaret();
                        onSearch("");
                        setSearch("");
                      }}
                    >
                      <Text style={styles.placeHolderText}>
                        {capitalizeString(item)}
                      </Text>
                      <View style={styles.checkIconContainer}>
                        <Check />
                      </View>
                    </Pressable>
                  );
                return (
                  <Pressable
                    key={index}
                    onPress={() => {
                      let temp;
                      if (multiple) {
                        temp = [...selectedData, item];
                      } else {
                        temp = [item];
                      }
                      setSelectedData(temp);
                      if (onSelect) onSelect(temp);
                      if (!multiple) toggleCaret();
                      onSearch("");
                      setSearch("");
                    }}
                  >
                    <Text style={styles.placeHolderText}>
                      {capitalizeString(item)}
                    </Text>
                  </Pressable>
                );
              }}
            />
          </Animated.View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  },
  name: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    color: "#282B33",
  },
  placeHolder: {
    width: "100%",
    maxWidth: 260,
    fontFamily: "Urbanist500",
    fontWeight: "500",
    borderRadius: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    paddingHorizontal: 12,
    fontSize: 16,
    color: "#000000",
    marginLeft: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  placeHolderText: {
    color: "#000",
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    letterSpacing: 0.032,
    width: "100%",
    paddingVertical: 4,
    paddingHorizontal: 2,
    userSelect: "none",
  },
  placeHolderClicked: {
    borderWidth: 1,
    borderColor: "#085ccc",
  },
  dropdownContainer: {
    width: "100%",
    maxWidth: 260,
    height: "auto",
    maxHeight: 200,
    backgroundColor: "#fff",
    borderRadius: 6,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.08,
    shadowRadius: 16,
    padding: 4,
    position: "absolute",
    top: 36,
  },
  checkIconContainer: {
    position: "absolute",
    right: 8,
  },
  selectedDataItem: {
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 4,
    backgroundColor: "#F8F8F8",
  },
});

export default InputPatientDetailDropdown;
