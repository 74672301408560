import React from "react";
import { View } from "react-native";

interface PersonProps {
  size?: number;
}

const Person: React.FC<PersonProps> = ({ size = 25 }) => {
  return (
    <View style={{ width: size, height: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM18.1935 20.4194C16.4516 21.7258 14.2742 22.4516 12 22.4516C9.67742 22.4516 7.5 21.7258 5.80645 20.4194V19.7419C5.80645 18.0484 7.16129 16.6452 8.90323 16.6452C9.43548 16.6452 10.2097 17.2258 12 17.2258C13.7419 17.2258 14.5161 16.6452 15.0968 16.6452C16.7903 16.6452 18.1935 18.0484 18.1935 19.7419V20.4194ZM19.6452 19.1129C19.3065 16.8387 17.4194 15.0968 15.0968 15.0968C14.0806 15.0968 13.5968 15.6774 12 15.6774C10.3548 15.6774 9.87097 15.0968 8.90323 15.0968C6.53226 15.0968 4.64516 16.8387 4.30645 19.1129C2.56452 17.2258 1.54839 14.7581 1.54839 12C1.54839 6.24194 6.19355 1.54839 12 1.54839C17.7581 1.54839 22.4516 6.24194 22.4516 12C22.4516 14.7581 21.3871 17.2258 19.6452 19.1129ZM12 5.41935C9.62903 5.41935 7.74194 7.35484 7.74194 9.67742C7.74194 12.0484 9.62903 13.9355 12 13.9355C14.3226 13.9355 16.2581 12.0484 16.2581 9.67742C16.2581 7.35484 14.3226 5.41935 12 5.41935ZM12 12.3871C10.5 12.3871 9.29032 11.1774 9.29032 9.67742C9.29032 8.22581 10.5 6.96774 12 6.96774C13.4516 6.96774 14.7097 8.22581 14.7097 9.67742C14.7097 11.1774 13.4516 12.3871 12 12.3871Z"
          fill="#383844"
        />
      </svg>
    </View>
  );
};

export default Person;
