import React from "react";
import { View } from "react-native";

interface AddPhotoProps {
  size?: number;
}

const AddPhoto: React.FC<AddPhotoProps> = ({ size = 36 }) => {
  return (
    <View style={{ width: size, height: size }}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_11217_5595)">
          <g clipPath="url(#clip1_11217_5595)">
            <path
              d="M7.12646 21.3085C8.76752 19.1116 11.3884 17.6895 14.3414 17.6895C16.4435 17.6895 18.3774 18.4102 19.9093 19.618"
              stroke="#868B97"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.0551 27.3315H4.05506C3.37309 27.3315 2.71902 27.0604 2.2368 26.5783C1.75456 26.0961 1.48364 25.442 1.48364 24.7601V4.18863C1.48364 3.50663 1.75456 2.85259 2.2368 2.37034C2.71902 1.88811 3.37309 1.61719 4.05506 1.61719H24.6265C25.3085 1.61719 25.9625 1.88811 26.4449 2.37034C26.927 2.85259 27.198 3.50663 27.198 4.18863V11.9029"
              stroke="#868B97"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.3408 13.8315C16.471 13.8315 18.1979 12.1046 18.1979 9.97433C18.1979 7.84408 16.471 6.11719 14.3408 6.11719C12.2106 6.11719 10.4836 7.84408 10.4836 9.97433C10.4836 12.1046 12.2106 13.8315 14.3408 13.8315Z"
              stroke="#868B97"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.8748 27.1623C17.9724 27.0052 17.9724 25.7098 18.8748 25.5528C22.144 24.984 24.7442 22.4939 25.4538 19.2524L25.5082 19.0039C25.7034 18.1121 26.9734 18.1065 27.1763 18.9966L27.2423 19.2862C27.9783 22.5125 30.579 24.9815 33.8393 25.5487C34.7463 25.7065 34.7463 27.0085 33.8393 27.1664C30.579 27.7334 27.9783 30.2025 27.2423 33.4289L27.1763 33.7184C26.9734 34.6084 25.7034 34.603 25.5082 33.711L25.4538 33.4626C24.7442 30.221 22.144 27.7308 18.8748 27.1623Z"
              stroke="#868B97"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_11217_5595">
            <rect width="36" height="36" fill="white" />
          </clipPath>
          <clipPath id="clip1_11217_5595">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </View>
  );
};

export default AddPhoto;
