import { TProcedure } from "../utils/types";

let urlPrefix: string;
if (process.env.EXPO_PUBLIC_ENV === "production")
  urlPrefix = "https://api.computex.ai/clinics/v1/forms";
else urlPrefix = "https://api-staging.computex.ai/clinics/v1/forms";

export const clinicSdkKey: string = "407e489a-e04d-4a69-aed4-6ee9c563390a";

export type Session = {
  id: string;
  accessToken: string;
};

export const createSession = async (abortController: AbortController) => {
  const headers = {
    Authorization: `sdk-key ${clinicSdkKey}`,
  };
  const response = await fetch(`${urlPrefix}/sessions`, {
    method: "POST",
    headers,
    signal: abortController.signal,
  });
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`HTTP error: ${response.status} - ${errorText}`);
  }
  const j = await response.json();
  const session = {
    id: j.id,
    accessToken: j.access_token,
  };
  return session;
};

export const getBeforeImageUrl = async (
  session: Session,
  selectedImage: Blob | string,
  abortController: AbortController,
) => {
  const headers = {
    Authorization: `session ${session.accessToken}`,
  };
  let response = await fetch(`${urlPrefix}/patient-images`, {
    method: "POST",
    headers,
    signal: abortController.signal,
  });
  if (!response.ok) {
    const text = await response.text();
    throw new Error(`HTTP Error: ${response.status} - ${text}`);
  }
  let j = await response.json();

  response = await fetch(j.upload_url, {
    method: "PUT",
    headers: {
      "Content-Type": "image/png",
    },
    body: selectedImage,
    signal: abortController.signal,
  });
  if (response.ok) {
    return j.download_url;
  } else {
    const text = await response.text();
    throw new Error(`HTTP Error: ${response.status} - ${text}`);
  }
};
const checkUrlAvailability = async (
  url: string,
  abortController: AbortController,
) => {
  while (true) {
    const response = await fetch(url, {
      method: "GET",
      signal: abortController.signal,
    });

    if (response.ok) return url;

    await new Promise((resolve) => setTimeout(resolve, 500));
  }
};

export const getAfterImageUrls = async (
  session: Session,
  beforeImageUrl: string,
  procedure: TProcedure,
  abortController: AbortController,
) => {
  const headers = {
    Authorization: `session ${session.accessToken}`,
    "Content-Type": "application/json",
  };
  let response: any;
  if (procedure === "BREAST_AUGMENTATION") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "BREAST_AUGMENTATION",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (procedure === "BREAST_LIFT") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "BREAST_LIFT",
        procedure_view: "TORSO_FRONT",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (procedure === "BREAST_REDUCTION") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "BREAST_REDUCTION",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (procedure === "BREAST_LIFT_AND_AUGMENTATION") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "BREAST_LIFT_AND_AUGMENTATION",
        procedure_view: "TORSO_FRONT",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (procedure === "BBL") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "BBL",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (procedure === "TUMMY_TUCK") {
    response = await fetch(`${urlPrefix}/transforms`, {
      method: "POST",
      body: JSON.stringify({
        image_url: beforeImageUrl,
        procedure_type: "TUMMY_TUCK",
        procedure_view: "BODY_FRONT",
      }),
      headers,
      signal: abortController.signal,
    });
  }

  if (!response.ok) {
    const text = await response.text();
    throw new Error(`HTTP Error: ${response.status} - ${text}`);
  }

  let j = await response.json();

  const downloadMetaData = async (url: string) => {
    if (!url) {
      return null;
    }
    while (true) {
      const metadataResponse = await fetch(url, {
        method: "GET",
      });
      if (metadataResponse.ok) {
        const jsonData = await metadataResponse.json();
        return jsonData;
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    }
  };

  const metaData = j.metadata_url
    ? await downloadMetaData(j.metadata_url)
    : null;

  if (metaData && metaData.status.success === false) {
    return {
      imageUrls: [],
      metaData: {
        reason: metaData.status.reason,
        success: metaData.status.success,
        implant_size_in_cc: metaData.implant_size_in_cc ?? null,
      },
    };
  }

  const imageUrls =
    j.download_urls && j.download_urls.length > 0
      ? await Promise.all(
          j.download_urls.map((url: string, abortController: AbortController) =>
            checkUrlAvailability(url, abortController),
          ),
        )
      : [];

  return {
    imageUrls,
    metaData: {
      reason: metaData.reason,
      success: metaData.status.success,
      implant_size_in_cc: metaData.implant_size_in_cc ?? null,
    },
  };
};
