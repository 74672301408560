import React from "react";
import { useWindowDimensions, View, StyleSheet } from "react-native";

import { TModal } from "../../../../utils/types";

import {
  PrimaryButton,
  SecondaryButton,
  TitleText,
} from "../../../common/native";
import { Plus, Upload } from "../../../../assets";
import { ModalOpener } from "../../../uncommon/native/modals";
import { mixpanel } from "../../../../utils/mixpanel";
import { SessionContext } from "../../../../contexts";
import PatientList from "./PatientList";
import {
  getPatients,
  TGetPatientsResponse,
  TPatientInfo,
} from "../../../../services/api-clinic";
import PatientDetail from "./PatientDetail";

type PatientsProps = {};

const Patients: React.FC<PatientsProps> = ({}) => {
  const session = React.useContext(SessionContext)?.session;

  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;
  const [modal, setModal] = React.useState<{
    visible: boolean;
    modalType: TModal;
  }>({ visible: false, modalType: "" });
  const [isErrorGettingPatients, setIsErrorGettingPatients] =
    React.useState<boolean>(false);

  const [patients, setPatients] = React.useState<TPatientInfo[]>([]);
  const [patientDetail, setPatientDetail] = React.useState<TPatientInfo | null>(
    null,
  );

  const storePatientDetail = (patientDetail: TPatientInfo) => {
    setPatientDetail(patientDetail);
  };
  const setPatientDetailToNull = () => {
    setPatientDetail(null);
  };

  const addNewPatient = (newPatientInfo: TPatientInfo) => {
    setPatients([...patients, newPatientInfo]);
  };

  React.useEffect(() => {
    const fetchPatients = async () => {
      try {
        if (!session) return;
        const response: TGetPatientsResponse = await getPatients(session);
        setPatients(response);
      } catch (error) {
        console.error("Error fetching patients:", error);
        setIsErrorGettingPatients(true);
      }
    };
    fetchPatients();
  }, [session]);

  if (!session) return;

  if (patientDetail) {
    return (
      <PatientDetail
        currentPatientDetail={patientDetail}
        setPatientDetailToNull={setPatientDetailToNull}
      />
    );
  }

  return (
    <>
      <View style={styles.titleContainer}>
        <TitleText
          text="Patient Management"
          style={{ marginTop: 5 }}
          numberOfLines={1}
        />
        <View style={styles.buttonsContainer}>
          <PrimaryButton
            text={isMobile ? "" : "Add a new patient"}
            roundBorderRadius
            icon={<Plus />}
            onPress={() =>
              setModal({ visible: true, modalType: "AddANewPatient" })
            }
          />
          <SecondaryButton
            text={isMobile ? "" : "Share visualization link"}
            roundBorderRadius
            icon={<Upload color="#000" />}
            onPress={() => {
              mixpanel.track("PressedShareLinkButton", {
                "User Email": session?.userEmail,
                "Clinic Name": session?.clinicName,
              });
              setModal({ visible: true, modalType: "ShareEngageLink" });
            }}
          />
        </View>
      </View>
      <PatientList
        storePatientDetail={storePatientDetail}
        patients={patients}
        addNewPatient={addNewPatient}
        isErrorGettingPatients={isErrorGettingPatients}
      />
      <ModalOpener
        visible={modal.visible}
        modalType={modal.modalType}
        onClose={() => setModal({ visible: false, modalType: "" })}
        addNewPatient={addNewPatient}
      />
    </>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    gap: 12,
    marginBottom: 10,
  },
  buttonsContainer: {
    flexDirection: "row",
    gap: 12,
    marginLeft: "auto",
  },
});

export default Patients;
