import React from "react";

const Upload: React.FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15.8088V19.8088C21 20.3393 20.7893 20.848 20.4142 21.2231C20.0391 21.5981 19.5304 21.8088 19 21.8088H5C4.46957 21.8088 3.96086 21.5981 3.58579 21.2231C3.21071 20.848 3 20.3393 3 19.8088V15.8088M17 8.80884L12 3.80884M12 3.80884L7 8.80884M12 3.80884V15.8088"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Upload;
