import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, Pressable, View, Linking } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";

import global from "../../../../styles/globalStyles";
import { SessionContext } from "../../../../contexts";
import { getClinicSdkKey } from "../../../../services/api";

import { PrimaryButton } from "../../../common/native/index";

import {
  Close,
  Message,
  GoogleMail,
  Mail,
  Instagram,
} from "../../../../assets/index";

interface ShareEngageLinkModalProps {
  onClose: () => void;
}

const ShareEngageLinkModal: React.FC<ShareEngageLinkModalProps> = ({
  onClose,
}) => {
  const [copyText, setCopyText] = useState<string>(
    // `${domain}/?formName=${formName}&clinicSdkKey=${sdkKey}&themeColor=${themeColor}#Initial_Screen`
    "Click here to share form link",
  );

  const session = useContext(SessionContext)?.session;
  const adminToken = session?.adminToken;
  const clinicId = session?.clinicId;

  useEffect(() => {
    const getFormData = async () => {
      if (session) {
        try {
          let clinicSdkKey;

          if (adminToken && clinicId)
            clinicSdkKey = await getClinicSdkKey(adminToken, clinicId);

          clinicSdkKey = clinicSdkKey.results[0];

          const domain =
            process.env.EXPO_PUBLIC_ENV === "production"
              ? "https://main.dp9ch69srufqq.amplifyapp.com"
              : "https://develop.d1lclqnbikydqw.amplifyapp.com";

          const formName = "onboarding-tier1";
          const themeColor = "%2302b7f4";

          setCopyText(
            process.env.EXPO_PUBLIC_ENV === "production"
              ? `https://clinicos.ai/patient-form?clinicSdkKey=${clinicSdkKey}`
              : `https://clinicos-ai-v2-9ad5a8.webflow.io/patient-form?clinicSdkKey=${clinicSdkKey}`,
          );
        } catch (error: any) {
          console.error("Error fetching data:", error);
        }
      }
    };

    getFormData();
  }, []);

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleIconPress = (url: string) => {
    Linking.openURL(url);
  };

  const handleModalClose = () => {
    onClose();
  };

  return (
    <View style={styles.modalView}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Share photo capture link</Text>
        <Pressable onPress={handleModalClose}>
          <Close />
        </Pressable>
      </View>
      <View style={styles.copyContainer}>
        <Text style={[global.text]} numberOfLines={1} ellipsizeMode="tail">
          {copyText}
        </Text>
        <PrimaryButton
          text={isCopied ? "Copied" : "Copy"}
          roundBorderRadius
          onPress={() => {
            Clipboard.setString(copyText);
            setIsCopied(true);
          }}
        />
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxWidth: 465,
    height: 200,
    padding: 24,
    gap: 24,
    borderRadius: 10,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Urbanist600",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    letterSpacing: 0.2,
    fontStyle: "normal",
  },
  iconsContainer: {
    flexDirection: "row",
    marginRight: "auto",
    gap: 10,
  },
  iconPressable: {
    width: 60,
    height: 60,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "#DDDEE0",
    backgroundColor: "#F8F8F8",
    position: "relative",
  },
  copyContainer: {
    width: "100%",
    margin: "auto",
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default ShareEngageLinkModal;
