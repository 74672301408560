import React, { useState, ChangeEvent } from "react";
import { AddSurgeonPhoto } from "../../../assets";

type InputPatientDetailSurgeonPhotoProps = {
  value: Blob | string | null;
  onPhotoSelect: (photo: Blob) => void;
};

const InputPatientDetailSurgeonPhoto: React.FC<
  InputPatientDetailSurgeonPhotoProps
> = ({ value, onPhotoSelect }) => {
  const [photo, setPhoto] = useState<Blob | string | null>(value);
  const [error, setError] = useState<string | null>(null);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const allowedExtensions = /(\.png|\.jpeg|\.jpg)$/i;
    if (!file) {
      setError("Unknown error occurred. Please try again a different photo.");
      return;
    }

    if (!allowedExtensions.exec(file.name)) {
      setError(
        "Invalid file format. Please upload a .png, .jpeg, or .jpg file.",
      );
      setPhoto(null); // Reset photo if the format is incorrect
      setTimeout(() => {
        setError(null); // Hide the error message after 2 seconds
      }, 3000);
      return;
    }

    setPhoto(file);
    onPhotoSelect(file);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: 75,
        gap: 10,
      }}
    >
      <label
        style={{
          fontFamily: "Urbanist500",
          fontWeight: "500",
          fontSize: 16,
          color: "#282B33",
        }}
      >
        Lead surgeon photo
      </label>
      {error ? (
        <p
          style={{
            fontFamily: "Urbanist500",
            fontWeight: "500",
            color: "red",
            fontSize: 16,
          }}
        >
          {error}
        </p>
      ) : (
        <div
          style={{
            position: "relative",
            width: "75px",
            height: "75px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            margin: "0 auto",
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            style={{
              position: "absolute",
              opacity: 0,
              width: "100%",
              height: "100%",
              inset: "0px",
            }}
          />
          {photo ? (
            <img
              src={
                typeof photo === "string"
                  ? photo
                  : photo
                    ? URL.createObjectURL(photo)
                    : ""
              }
              alt="Surgeon"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <AddSurgeonPhoto />
          )}
        </div>
      )}
    </div>
  );
};

export default InputPatientDetailSurgeonPhoto;
