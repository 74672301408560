import React from "react";
import { View, Image, ActivityIndicator, StyleSheet } from "react-native";

interface TryAIComponentLoadingProps {
  currentImage: Blob | string;
  isMobile: boolean;
}

const TryAIComponentLoading: React.FC<TryAIComponentLoadingProps> = ({
  currentImage,
  isMobile,
}) => {
  return (
    <View
      style={{
        width: isMobile ? "100%" : 316,
        aspectRatio: 1,
        marginHorizontal: "auto",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      {currentImage && (
        <Image
          source={{
            uri:
              typeof currentImage === "string"
                ? currentImage
                : URL.createObjectURL(currentImage),
          }}
          style={styles.image}
        />
      )}
      <ActivityIndicator
        size="large"
        color="#00B7F4"
        style={styles.loadingOverlay}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
});

export default TryAIComponentLoading;
