import React from "react";
import { View } from "react-native";
import {
  InputPatientDetail,
  InputPatientDetailColorPicker,
  InputPatientDetailDropdown,
  NormalText,
  PrimaryButton,
} from "../../../common/native";

export type TAppearance = {
  xOffset: string;
  yOffset: string;
  themeColor: string;
  textColor: string;
  position: "Bottom Right" | "Bottom Left";
  ctaStateMobile: "Display Call to Action" | "Hide Call to Action";
  ctaStateDesktop: "Display Call to Action" | "Hide Call to Action";
  isDesktopView: "Desktop" | "Mobile";
};

type AppearanceTabProps = {
  appearance: TAppearance;
  onContinue: () => void;
  onUpdateAppearance: (field: keyof TAppearance, value: string) => void;
};

const AppearanceTab: React.FC<AppearanceTabProps> = ({
  appearance,
  onContinue,
  onUpdateAppearance,
}) => {
  const [iframeSrc, setIframeSrc] = React.useState<string>("");

  React.useEffect(() => {
    if (!appearance) return;

    let position;
    if (appearance.position === "Bottom Left") position = "left";
    else position = "right";

    let isCtaOpenByDefault;
    if (appearance.isDesktopView === "Desktop") {
      isCtaOpenByDefault =
        appearance.ctaStateDesktop === "Display Call to Action"
          ? "true"
          : "false";
    } else {
      isCtaOpenByDefault =
        appearance.ctaStateMobile === "Display Call to Action"
          ? "true"
          : "false";
    }

    let horizontalOffset = `${appearance.xOffset}px`;
    let verticalOffset = `${appearance.yOffset}px`;
    let themeColor = encodeURIComponent(appearance.themeColor);
    let textColor = encodeURIComponent(appearance.textColor);

    setIframeSrc(
      `https://www.clinicos.ai/acquisition-tab-user-app-iframe?themeColor=${themeColor}&textColor=${textColor}&position=${position}&isCtaOpenByDefaultMobile=${isCtaOpenByDefault}&horizontalOffset=${horizontalOffset}&verticalOffset=${verticalOffset}`,
    );
  }, [appearance]);

  return (
    <View style={{ flex: 1, gap: 10, marginTop: 10 }}>
      <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 10 }}>
        <View
          style={{ flex: 1, width: "100%", minWidth: 350, gap: 10, zIndex: 1 }}
        >
          <NormalText text="Customize Widget Appearance" fontWeight="600" />
          <NormalText text="Adjust the widget settings and view the widget in the preview pane" />
          <View style={{ width: "100%", maxWidth: 400, gap: 10 }}>
            <InputPatientDetailColorPicker
              name="Theme Color"
              onChange={(value) => onUpdateAppearance("themeColor", value)}
              initialValue={appearance.themeColor}
            />
            <InputPatientDetailColorPicker
              name="Text Color"
              onChange={(value) => onUpdateAppearance("textColor", value)}
              initialValue={appearance.textColor}
            />
            <InputPatientDetail
              name="X offset"
              value={appearance.xOffset}
              onChangeText={(value) => onUpdateAppearance("xOffset", value)}
            />
            <InputPatientDetail
              name="Y offset"
              value={appearance.yOffset}
              onChangeText={(value) => onUpdateAppearance("yOffset", value)}
            />
            <View style={{ zIndex: 3 }}>
              <InputPatientDetailDropdown
                name="Position"
                value={[appearance.position]}
                selections={["Bottom Right", "Bottom Left"]}
                onSelect={(value) => onUpdateAppearance("position", value[0])}
              />
            </View>
            <View style={{ zIndex: 2 }}>
              <InputPatientDetailDropdown
                name="CTA (Mobile)"
                value={[appearance.ctaStateMobile]}
                selections={["Display Call to Action", "Hide Call to Action"]}
                onSelect={(value) =>
                  onUpdateAppearance("ctaStateMobile", value[0])
                }
              />
            </View>
            <View style={{ zIndex: 1 }}>
              <InputPatientDetailDropdown
                name="CTA (Desktop)"
                value={[appearance.ctaStateDesktop]}
                selections={["Display Call to Action", "Hide Call to Action"]}
                onSelect={(value) =>
                  onUpdateAppearance("ctaStateDesktop", value[0])
                }
              />
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            width: "100%",
            minWidth: 350,
            gap: 10,
          }}
        >
          <NormalText text={"Widget Preview"} fontWeight="600" />
          <NormalText
            text={"A preview of how the widget would be placed on your site"}
          />
          <View
            style={{
              zIndex: 1,
              maxWidth: 400,
            }}
          >
            <InputPatientDetailDropdown
              name="Desktop/Mobile View"
              value={[appearance.isDesktopView]}
              selections={["Desktop", "Mobile"]}
              onSelect={(value) =>
                onUpdateAppearance("isDesktopView", value[0])
              }
            />
          </View>
          {/* WARNING: Be careful about change this <View> bellow.
          It might cause the responsive on mobile and desktop to be mess up */}
          <View
            style={{
              position: "relative",
              aspectRatio:
                appearance.isDesktopView === "Desktop" ? 16 / 9 : 9 / 16,
              width: "100%",
              maxWidth: appearance.isDesktopView === "Desktop" ? 600 : 410,
              borderRadius: 10,
              overflow: "hidden",
              marginTop: 10,
            }}
          >
            {iframeSrc ? (
              <iframe
                src={iframeSrc}
                style={{
                  position: "absolute",
                  border: 0,
                  aspectRatio:
                    appearance.isDesktopView === "Desktop" ? 16 / 9 : 9 / 16,
                  width:
                    appearance.isDesktopView === "Desktop" ? "200%" : "100%",
                  maxWidth: appearance.isDesktopView === "Desktop" ? 1200 : 410,
                  transformOrigin:
                    appearance.isDesktopView === "Desktop" ? "0 0" : "center 0",
                  transform:
                    appearance.isDesktopView === "Desktop"
                      ? "scale(0.5)"
                      : "scale(1)",
                  pointerEvents: "none",
                }}
                scrolling="no"
              >
                Browser doesn't support iframe
              </iframe>
            ) : null}
          </View>
          {/*****************************************************************/}
        </View>
      </View>
      <View style={{ marginTop: "auto", marginHorizontal: "auto" }}>
        <PrimaryButton
          text={"Continue"}
          roundBorderRadius
          width={275}
          onPress={onContinue}
        />
      </View>
    </View>
  );
};

export default AppearanceTab;
