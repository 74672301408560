import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useNavigate } from "react-router-dom";

import global from "../../styles/globalStyles";
import { supabase } from "../../utils/supabase";
import { titles, states } from "../../utils/constant";

import {
  Header,
  Input,
  FooterLinks,
  PrimaryButton,
  SecondaryButton,
  InputDropdown,
} from "../common/native/index";

const SetupPassword = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<1 | 2 | 3>(1);

  const [loading, setLoading] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [title, setTitle] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");

  const [jobTitle, setJobTitle] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [state, setState] = useState<string>("");

  const [errorMessageStep1, setErrorMessageStep1] = useState<string>("");
  const [errorMessageStep2, setErrorMessageStep2] = useState<string>("");
  const [errorMessageStep3, setErrorMessageStep3] = useState<string>("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: password,
        data: {
          title: title,
          fullName: fullName,
          jobTitle: jobTitle,
          phoneNumber: phoneNumber,
          state: state,
        },
      });
      if (error) setErrorMessageStep3(error.message);
      if (!error) navigate("/");
    } catch (error) {
      setErrorMessageStep3("Error in Updating Password. Please try again");
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Header />
      <View
        style={{
          width: "100%",
          maxWidth: 434,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {step === 1 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 1 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 24 },
              ]}
            >
              Sign up to join your team
            </Text>
            <Input
              name="Password"
              placeholder="Enter your password"
              value={password}
              onChangeText={setPassword}
              secureTextEntry
              required
            />
            <Input
              name="Confirm Password"
              placeholder="Enter your password"
              value={confirmPassword}
              onChangeText={setConfirmPassword}
              secureTextEntry
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep1 !== "" && (
              <Text style={global.errorText}>{errorMessageStep1}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={{ marginLeft: "auto" }}>
              <PrimaryButton
                text="Next"
                width={140}
                disabled={password && confirmPassword ? false : true}
                onPress={() => {
                  if (password.length < 8) {
                    setErrorMessageStep1("Invalid password");
                    return;
                  }

                  if (password !== confirmPassword) {
                    setErrorMessageStep1(
                      "Please make sure your passwords match.",
                    );
                    return;
                  }
                  setErrorMessageStep1("");
                  setStep(2);
                }}
              />
            </View>
          </>
        )}

        {step === 2 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 2 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 24 },
              ]}
            >
              Tell us about yourself
            </Text>
            <View style={{ zIndex: 1, width: "100%" }}>
              <InputDropdown
                onSelect={setTitle}
                required
                name={"Title"}
                selections={titles}
                value={title}
              />
            </View>
            <Input
              name="Full name"
              placeholder=""
              value={fullName}
              onChangeText={setFullName}
              required
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep2 !== "" && (
              <Text style={global.errorText}>{errorMessageStep2}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={styles.backNextBtnsContainer}>
              <SecondaryButton
                text="Back"
                width={140}
                onPress={() => setStep(1)}
              />
              <PrimaryButton
                text="Next"
                width={140}
                onPress={() => setStep(3)}
                disabled={title && fullName ? false : true}
              />
            </View>
          </>
        )}

        {step === 3 && (
          <>
            <Text
              style={[
                global.text,
                global.fontSize20,
                global.fontWeight600,
                global.alignSelfStart,
              ]}
            >
              Step 3 of 3
            </Text>
            <Text
              style={[
                global.title,
                global.alignSelfStart,
                { marginBottom: 32 },
              ]}
            >
              Tell us about your business
            </Text>
            <View style={{ zIndex: 1, width: "100%" }}>
              <InputDropdown
                onSelect={setState}
                required
                allowSearch
                name={"State"}
                selections={states}
                value={state}
              />
            </View>
            <Input
              name="Job Title"
              value={jobTitle}
              onChangeText={setJobTitle}
            />
            <Input
              name="Phone number"
              type="numeric"
              value={phoneNumber}
              onChangeText={setPhoneNumber}
            />
            <View style={{ marginBottom: 12 }} />
            {errorMessageStep3 !== "" && (
              <Text style={global.errorText}>{errorMessageStep3}</Text>
            )}
            <View style={{ marginBottom: 12 }} />
            <View style={styles.backNextBtnsContainer}>
              <SecondaryButton
                text="Back"
                width={140}
                onPress={() => setStep(2)}
              />
              <PrimaryButton
                text="Next"
                width={140}
                onPress={() => handleSubmit()}
                disabled={state ? false : true}
                loading={loading}
              />
            </View>
          </>
        )}
      </View>
      <FooterLinks />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "space-between",
    minHeight: "100%",
    width: "100%",
    gap: 32,
    padding: 16,
  },
  backNextBtnsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default SetupPassword;
