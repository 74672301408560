import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  useWindowDimensions,
  ScrollView,
  Pressable,
} from "react-native";

import global from "../../../../styles/globalStyles";

import { capitalizeString } from "../../../../utils/functions";
import { NormalText, PrimaryButton } from "../../../common/native/index";
import { ModalOpener } from "../../../uncommon/native/modals";
import { Plus } from "../../../../assets";
import { TPatientInfo } from "../../../../services/api-clinic";

type PatientListProps = {
  storePatientDetail: (currentPatientDetail: TPatientInfo) => void;
  patients: TPatientInfo[];
  addNewPatient: (newPatientInfo: TPatientInfo) => void;
  isErrorGettingPatients: boolean;
};

const PatientList: React.FC<PatientListProps> = ({
  storePatientDetail,
  patients,
  addNewPatient,
  isErrorGettingPatients,
}) => {
  const [sortedPatientListElements, setSortedPatientListElements] = useState<
    TPatientInfo[]
  >([]);
  const [visible, setVisible] = useState<boolean>(false);

  const sortPatientListElements = (field: string, order: "asc" | "desc") => {
    const sortingFunction = (a: TPatientInfo, b: TPatientInfo) => {
      const valueA = a[field as keyof TPatientInfo];
      const valueB = b[field as keyof TPatientInfo];

      if (
        field === "dateOfBirth" ||
        field === "createdAt" ||
        field === "updatedAt"
      ) {
        // @ts-ignore
        const dateA = valueA ? new Date(valueA) : null;
        // @ts-ignore
        const dateB = valueB ? new Date(valueB) : null;

        if (dateA && dateB) {
          return order === "asc"
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        } else if (dateA) {
          return order === "asc" ? -1 : 1;
        } else if (dateB) {
          return order === "asc" ? 1 : -1;
        }
        return 0;
      } else {
        const stringValueA = valueA ? valueA.toString().toLowerCase() : "";
        const stringValueB = valueB ? valueB.toString().toLowerCase() : "";

        if (stringValueA < stringValueB) return order === "asc" ? -1 : 1;
        if (stringValueA > stringValueB) return order === "asc" ? 1 : -1;
        return 0;
      }
    };

    const sorted = [...patients].sort(sortingFunction);
    setSortedPatientListElements(sorted);
  };

  useEffect(() => {
    sortPatientListElements("updatedAt", "desc");
  }, [patients]);

  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

  const renderPatientRow = ({
    item,
    index,
  }: {
    item: TPatientInfo;
    index: number;
  }) => {
    const isHovered = index === hoveredIndex;

    return (
      <Pressable
        onPress={() => storePatientDetail(item)}
        onHoverIn={() => setHoveredIndex(index)}
        onHoverOut={() => setHoveredIndex(-1)}
        style={[
          styles.patientListRow,
          isHovered && styles.patientListRowHovered,
        ]}
      >
        <Text style={global.text} numberOfLines={1}>
          {item.updatedAt
            ? `${new Date(item.updatedAt).toLocaleDateString()}`
            : "-"}
        </Text>
        <Text style={global.text} numberOfLines={1}>
          {`${item.firstName || ""} ${item.lastName || "-"}`}
        </Text>
        <Text style={global.text} numberOfLines={1}>
          {item.proceduresOfInterest
            ? item.proceduresOfInterest
                .map((item) => capitalizeString(item))
                .join(", ")
            : "-"}
        </Text>
        <Text style={global.text} numberOfLines={1}>
          {item.email || "-"}
        </Text>
        <Text style={global.text} numberOfLines={1}>
          {item.phone || "-"}
        </Text>
      </Pressable>
    );
  };

  return (
    <>
      <ModalOpener
        visible={visible}
        modalType={"AddANewPatient"}
        onClose={() => setVisible(false)}
        addNewPatient={addNewPatient}
      />
      <ScrollView horizontal showsHorizontalScrollIndicator={true}>
        <View style={{ flexDirection: "column" }}>
          <View style={styles.patientListHeader}>
            <Pressable
              onPress={() => sortPatientListElements("updatedA", "desc")}
            >
              <Text style={[global.text, global.fontWeight600]}>
                Date Updated
              </Text>
            </Pressable>
            <Pressable
              onPress={() => sortPatientListElements("firstName", "asc")}
            >
              <Text style={[global.text, global.fontWeight600]}>Name</Text>
            </Pressable>
            <Pressable
              onPress={() =>
                sortPatientListElements("proceduresOfInterest", "asc")
              }
            >
              <Text style={[global.text, global.fontWeight600]}>
                Procedures
              </Text>
            </Pressable>
            <Pressable onPress={() => sortPatientListElements("email", "asc")}>
              <Text style={[global.text, global.fontWeight600]}>Email</Text>
            </Pressable>
            <Pressable onPress={() => sortPatientListElements("phone", "asc")}>
              <Text style={[global.text, global.fontWeight600]}>Phone</Text>
            </Pressable>
          </View>
          {isErrorGettingPatients ? (
            <NormalText
              text={
                "Something wrong with getting patients. Please try again later"
              }
              style={{ marginTop: 20 }}
            />
          ) : (
            <FlatList
              showsVerticalScrollIndicator={false}
              data={sortedPatientListElements}
              keyExtractor={(item, index) => `${index}`}
              renderItem={renderPatientRow}
            />
          )}
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  searchContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    marginBottom: 12,
  },
  searchSortByContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: 9999,
  },
  patientListHeader: {
    // @ts-ignore comment
    display: "grid",
    gridTemplateColumns: "120px 200px 170px 300px 150px",
    borderBottomColor: "#DDDEE0",
    borderBottomWidth: 1,
    paddingVertical: 20,
  },
  patientListRow: {
    // @ts-ignore comment
    display: "grid",
    gridTemplateColumns: "120px 200px 170px 300px 150px",
    paddingVertical: 6,
    marginVertical: 10,
  },
  patientListRowHovered: {
    backgroundColor: "#f0f0f0",
  },
});

export default PatientList;
