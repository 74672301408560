import React from "react";
import { View } from "react-native";

type AnalyticsProps = {
  color?: string;
};

const Analytics: React.FC<AnalyticsProps> = ({ color = "#000" }) => {
  return (
    <View>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10L8 16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 8V16"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="3"
          y="4"
          width="18"
          height="16"
          rx="2"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    </View>
  );
};

export default Analytics;
