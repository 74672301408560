import { StyleSheet } from "react-native";

const global = StyleSheet.create({
  fontSize12: {
    fontSize: 12,
    lineHeight: 12 * 1.4,
  },

  fontSize14: {
    fontSize: 14,
    lineHeight: 14 * 1.4,
  },

  fontSize16: {
    fontSize: 16,
    lineHeight: 16 * 1.4,
  },

  fontSize17: {
    fontSize: 17,
    lineHeight: 18 * 1.4,
  },

  fontSize18: {
    fontSize: 18,
    lineHeight: 18 * 1.4,
  },

  fontSize20: {
    fontSize: 20,
    lineHeight: 20 * 1.4,
  },

  fontSize24: {
    fontSize: 24,
    lineHeight: 24 * 1.4,
  },

  fontWeight100: {
    fontFamily: "Urbanist100",
    fontWeight: "100",
  },

  fontWeight200: {
    fontFamily: "Urbanist200",
    fontWeight: "200",
  },

  fontWeight300: {
    fontFamily: "Urbanist300",
    fontWeight: "300",
  },

  fontWeight400: {
    fontFamily: "Urbanist400",
    fontWeight: "400",
  },

  fontWeight500: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
  },

  fontWeight600: {
    fontFamily: "Urbanist600",
    fontWeight: "600",
  },

  fontWeight700: {
    fontFamily: "Urbanist700",
    fontWeight: "700",
  },

  fontWeight800: {
    fontFamily: "Urbanist800",
    fontWeight: "800",
  },

  colorRed: {
    color: "red",
  },

  colorBlue: {
    color: "#00B7F4",
  },

  colorGray: {
    color: "#C4C6CD",
  },

  textAlignCenter: {
    textAlign: "center",
  },

  textAlignLeft: {
    textAlign: "left",
  },

  alignSelfStart: {
    alignSelf: "flex-start",
  },

  width140: {
    width: 140,
  },

  widthAuto: {
    width: "auto",
  },

  blue: {
    color: "#00B7F4",
  },

  borderRadius80: {
    borderRadius: 80,
  },

  errorText: {
    fontFamily: "Urbanist300",
    fontSize: 14,
    lineHeight: 14 * 1.4,
    color: "red",
    letterSpacing: 0.2,
  },

  text: {
    fontFamily: "Urbanist300",
    fontSize: 16,
    lineHeight: 16 * 1.4,
    color: "#282B33",
  },

  underlineNone: {
    textDecorationLine: "none",
  },

  title: {
    fontFamily: "Urbanist700",
    fontSize: 36,
    lineHeight: 36 * 1.4,
    color: "#00759B",
  },
});

export default global;
