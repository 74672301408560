import React, { useState } from "react";
import { StyleSheet, Text, Pressable, View, Linking } from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";

import global from "../../../../styles/globalStyles";

import { Input, PrimaryButton } from "../../../common/native/index";

import { Close } from "../../../../assets/index";
import { TSimulationLinkAndPin } from "../../../../utils/types";

interface ShareReportModalProps {
  onClose: () => void;
  simulationLinkAndPin: TSimulationLinkAndPin | null;
}

const ShareReportModal: React.FC<ShareReportModalProps> = ({
  onClose,
  simulationLinkAndPin = null,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleModalClose = () => {
    onClose();
  };

  return (
    <View style={styles.modalView}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Share Simulation Link</Text>
        <Pressable onPress={handleModalClose}>
          <Close />
        </Pressable>
      </View>
      <Text style={[global.text, { marginRight: "auto" }]}>
        Pin: {simulationLinkAndPin?.pin ?? ""}
      </Text>
      <View style={styles.copyContainer}>
        <Text style={[global.text]} numberOfLines={1} ellipsizeMode="tail">
          {simulationLinkAndPin?.simulationLink ?? ""}
        </Text>
        <PrimaryButton
          text={isCopied ? "Copied" : "Copy"}
          roundBorderRadius
          onPress={() => {
            Clipboard.setString(simulationLinkAndPin?.simulationLink ?? "");
            setIsCopied(true);
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxWidth: 465,
    height: 220,
    padding: 24,
    gap: 24,
    borderRadius: 10,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    alignItems: "center",
  },
  titleContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontFamily: "Urbanist600",
    fontSize: 20,
    lineHeight: 20 * 1.4,
    letterSpacing: 0.2,
    fontStyle: "normal",
  },
  iconsContainer: {
    flexDirection: "row",
    marginRight: "auto",
    gap: 10,
  },
  iconPressable: {
    width: 60,
    height: 60,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: "#DDDEE0",
    backgroundColor: "#F8F8F8",
    position: "relative",
  },
  copyContainer: {
    margin: "auto",
    width: "100%",
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 10,
    borderColor: "#C4C6CD",
    borderWidth: 1,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default ShareReportModal;
