import React, { useContext, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Linking,
  TouchableOpacity,
  Platform,
} from "react-native";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Clipboard from "@react-native-clipboard/clipboard";
import { NormalText } from "../../../common/native";
import { SessionContext } from "../../../../contexts";

type DeployProps = {
  codeSnippet: string;
};

type CopyableCodeBlockProps = {
  code: string;
};

const CopyableCodeBlock: React.FC<CopyableCodeBlockProps> = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    Clipboard.setString(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <View style={styles.codeBlockContainer}>
      <SyntaxHighlighter language="javascript" style={atomOneDark}>
        {code}
      </SyntaxHighlighter>
      <TouchableOpacity style={styles.copyButton} onPress={handleCopy}>
        <NormalText
          text={copied ? "Copied" : "Copy"}
          style={{ fontSize: 12, textAlign: "center" }}
        />
      </TouchableOpacity>
    </View>
  );
};

const DeployTab: React.FC<DeployProps> = ({ codeSnippet }) => {
  const session = useContext(SessionContext)?.session;

  const handleEmailPress = () => {
    const email = "support@clinicos.ai";
    const subject = "Embedding Form Support Request";
    const body = "";
    let url = "";
    if (Platform.OS === "ios") {
      url = `googlegmail:///co?to=${email}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    } else {
      url = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        Linking.openURL(
          `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`,
        );
      }
    });
  };

  const handlePhonePress = () => {
    Linking.openURL("tel:+19093592587");
  };

  return (
    <View style={{ gap: 10, marginTop: 10 }}>
      <NormalText text={"Deploy the form to your site"} fontWeight="600" />
      <NormalText
        text={`Add the following code snippet to the <body> tag on your website`}
      />
      <View style={{ width: "100%", maxWidth: 550 }}>
        <CopyableCodeBlock code={codeSnippet} />
      </View>
      <View style={{ marginTop: 30 }} />
      <Text style={styles.textParagraphNormal}>
        Once the script is added to your site, the Lead Gen Widget will be
        publicly visible on your site.{"\n"}
        New submissions will be sent to your email and accessible in the Patient
        dashboard. {"\n\n"}
        Questions? Email{" "}
        <TouchableOpacity onPress={handleEmailPress}>
          <Text style={styles.link}>support@clinicos.ai</Text>
        </TouchableOpacity>{" "}
        or call{" "}
        <TouchableOpacity onPress={handlePhonePress}>
          <Text style={styles.link}>+1 (909) 359-2587</Text>
        </TouchableOpacity>
      </Text>

      <View style={{ flexDirection: "row", marginTop: 30 }}>
        <NormalText
          text={"Deploy Status: "}
          fontWeight="600"
          style={{ fontSize: 18 }}
        />
        {session?.deployStatus ? (
          <NormalText
            text={"Deployed!"}
            fontWeight="600"
            style={{ color: "#00B7F4", fontSize: 18 }}
          />
        ) : (
          <NormalText
            text={"Not Deployed"}
            fontWeight="600"
            style={{ color: "#868B97", fontSize: 18 }}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textParagraphNormal: {
    fontFamily: "Urbanist500",
    fontWeight: "500",
    fontSize: 16,
    marginTop: 10,
  },
  link: {
    color: "#00B7F4",
    textDecorationColor: "#fff",
  },
  codeBlockContainer: {
    position: "relative",
  },
  copyButton: {
    position: "absolute",
    bottom: 20,
    right: 10,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: 5,
    borderRadius: 5,
    width: 60,
  },
});

export default DeployTab;
