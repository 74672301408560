import React from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  useWindowDimensions,
} from "react-native";

import { TProcedure, TTabName } from "../../../../utils/types";
import { SessionContext } from "../../../../contexts";
import {
  createSession,
  getAfterImageUrls,
  getBeforeImageUrl,
  Session as TryAISession,
} from "../../../../services/tryai-api";
import { mixpanel } from "../../../../utils/mixpanel";
import {
  simulateTabBodyProcedure,
  simulateTabBreastProcedure,
} from "./simulateTabConstants";

import Tab from "../../../common/native/Tab";
import {
  InputDropdown,
  NormalText,
  ProcedureButton,
  TitleText,
} from "../../../common/native";
import TryAI from "./TryAI";
import TryAILoading from "./TryAILoading";

type SimulateProps = {};

const Simulate: React.FC<SimulateProps> = ({}) => {
  const userAppSession = React.useContext(SessionContext)?.session;
  const { width, height } = useWindowDimensions();
  const isMobile = Math.min(width, height) < 644;

  const [selectedProcedure, setSelectedProcedure] = React.useState<TProcedure>(
    "BREAST_AUGMENTATION",
  );
  const [currentImage, setCurrentImage] = React.useState<Blob | string>("");
  const [afterImageUrls, setAfterImageUrls] = React.useState<string[] | null>(
    null,
  );
  const [isShowSlider, setIsShowSlider] = React.useState<boolean>(false);
  const [afterImageMetaData, setAfterImageMetaData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSomethingWrong, setIsSomethingWrong] =
    React.useState<boolean>(false);
  // TODO: This can be removed if we don't need to cancel the fetch request
  const [currentAbortController, setCurrentAbortController] =
    React.useState<AbortController>(new AbortController());

  const [isAllowScrolling, setIsAllowScrolling] = React.useState<boolean>(true);

  React.useEffect(() => {
    const fetchData = async () => {
      if (currentImage) {
        setIsLoading(true);
        try {
          const session: TryAISession = await createSession(
            new AbortController(),
          );
          let beforeImageUrl;
          if (typeof currentImage === "string") {
            mixpanel.track("SimulationComplete", {
              "User Email": userAppSession?.userEmail,
              "Clinic Name": userAppSession?.clinicName,
              "Simulation Type": "ClinicOS Model",
              Procedure: selectedProcedure,
            });
            beforeImageUrl = currentImage;
          } else {
            mixpanel.track("SimulationComplete", {
              "User Email": userAppSession?.userEmail,
              "Clinic Name": userAppSession?.clinicName,
              "Simulation Type": "Photo Upload",
              Procedure: selectedProcedure,
            });
            beforeImageUrl = await getBeforeImageUrl(
              session,
              currentImage,
              currentAbortController,
            );
          }
          const afterImageUrlsAndMetaData = await getAfterImageUrls(
            session,
            beforeImageUrl,
            selectedProcedure,
            currentAbortController,
          );
          setAfterImageMetaData(afterImageUrlsAndMetaData.metaData);
          setAfterImageUrls(afterImageUrlsAndMetaData.imageUrls);
        } catch (error: any) {
          if (!(error && error.name === "AbortError"))
            setIsSomethingWrong(true);
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
          setIsShowSlider(true);
        }
      }
    };
    fetchData();
  }, [currentImage]);

  const handleProcedurePress = (procedure: TProcedure) => {
    setSelectedProcedure(procedure);
    setIsShowSlider(false);
  };

  const saveCurrentImage = (image: Blob | string) => {
    setCurrentImage(image);
  };

  const handleSetIsAllowScrolling = (bool: boolean) => {
    setIsAllowScrolling(bool);
  };

  const handleOnTabPress = (tab: TTabName) => {
    if (tab === "Breast") {
      setSelectedProcedure("BREAST_AUGMENTATION");
    }
    if (tab === "Body") {
      setSelectedProcedure("BBL");
    }
  };

  if (!userAppSession) return null;

  if (isSomethingWrong) {
    return (
      <View style={{ margin: "auto" }}>
        <Text
          style={{ fontFamily: "Urbanist500", fontWeight: "500", fontSize: 16 }}
        >
          Something is wrong. Please refresh and try again later
        </Text>
      </View>
    );
  }

  if (isMobile) {
    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        scrollEnabled={isAllowScrolling}
      >
        <TitleText text="Simulate" style={{ marginTop: 5, marginBottom: 10 }} />
        <View style={{ paddingHorizontal: 20 }}>
          <View style={{ zIndex: 1, marginBottom: 15, width: "100%" }}>
            <InputDropdown
              name={""}
              selections={[
                ...simulateTabBreastProcedure,
                ...simulateTabBodyProcedure,
              ]}
              value={selectedProcedure}
              onSelect={(value) => {
                setIsShowSlider(false);
                setSelectedProcedure(value as TProcedure);
              }}
            />
          </View>
          {isLoading ? (
            <TryAILoading currentImage={currentImage} isMobile={isMobile} />
          ) : (
            <TryAI
              isShowSlider={isShowSlider}
              selectedProcedure={selectedProcedure}
              afterImageUrls={afterImageUrls}
              afterImageMetaData={afterImageMetaData}
              saveCurrentImage={saveCurrentImage}
              isMobile={isMobile}
              handleSetIsAllowScrolling={handleSetIsAllowScrolling}
            />
          )}
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
    >
      <TitleText text="Simulate" style={{ marginTop: 5, marginBottom: 5 }} />
      <Tab
        disabledTabIndexes={!isLoading ? [] : [0, 1]}
        tabNames={["Breast", "Body"]}
        onTabPress={(tab) => handleOnTabPress(tab)}
        tabContent={[
          <View key={1} style={styles.tabContainer}>
            <View style={{ flexDirection: "row", gap: 20 }}>
              <View style={{ gap: 20, marginLeft: 4 }}>
                {simulateTabBreastProcedure.map((procedure) => (
                  <ProcedureButton
                    key={procedure}
                    procedure={procedure}
                    selected={procedure === selectedProcedure}
                    onPress={handleProcedurePress}
                    disabled={isLoading}
                  />
                ))}
              </View>
              {isLoading ? (
                <TryAILoading currentImage={currentImage} isMobile={false} />
              ) : (
                <TryAI
                  isShowSlider={isShowSlider}
                  selectedProcedure={selectedProcedure}
                  afterImageUrls={afterImageUrls}
                  afterImageMetaData={afterImageMetaData}
                  saveCurrentImage={saveCurrentImage}
                  isMobile={isMobile}
                />
              )}
            </View>
          </View>,
          <View key={2} style={styles.tabContainer}>
            <View style={{ flexDirection: "row", gap: 20 }}>
              <View style={{ gap: 20, marginLeft: 4 }}>
                {simulateTabBodyProcedure.map((procedure) => (
                  <ProcedureButton
                    key={procedure}
                    procedure={procedure}
                    selected={procedure === selectedProcedure}
                    onPress={handleProcedurePress}
                    disabled={isLoading}
                  />
                ))}
              </View>
              {isLoading ? (
                <TryAILoading currentImage={currentImage} isMobile={false} />
              ) : (
                <TryAI
                  isShowSlider={isShowSlider}
                  selectedProcedure={selectedProcedure}
                  afterImageUrls={afterImageUrls}
                  afterImageMetaData={afterImageMetaData}
                  saveCurrentImage={saveCurrentImage}
                  isMobile={isMobile}
                />
              )}
            </View>
          </View>,
        ]}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  textHeader: {
    fontFamily: "Urbanist600",
    fontSize: 24,
    color: "#000",
    lineHeight: 33.6,
    height: 46,
    alignItems: "center",
    marginTop: 5,
  },
  tabContainer: {
    flexDirection: "column",
    alignItems: "stretch",
    paddingTop: 15,
    height: "auto",
  },
});

export default Simulate;
